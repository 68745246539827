import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRuleItem } from "../../../redux/Commission/commissionSlice";

interface Item {
  id: any;
  partyType: string;
  CaseType: string;
  partyName: string;
  Amount: string;
  Note: string;
}

const initialItems: Item[] = [
  {
    partyType: "Consultant",
    CaseType: "Civil",
    partyName: "John Doe",
    Amount: "$1,000",
    Note: "Pending",
    id: "11023",
  },
  {
    partyType: "Corporate",
    CaseType: "Criminal",
    partyName: "ABC Corp",
    Amount: "$5,000",
    Note: "Completed",
    id: "11623",
  },
  {
    partyType: "Consultant",
    CaseType: "Civil",
    partyName: "John Doe",
    Amount: "$1,000",
    Note: "Pending",
    id: "15123",
  },
  {
    partyType: "Corporate",
    CaseType: "Criminal",
    partyName: "ABC Corp",
    Amount: "$5,000",
    Note: "Completed",
    id: "16123",
  },
  {
    partyType: "Consultant",
    CaseType: "Civil",
    partyName: "John Doe",
    Amount: "$1,000",
    Note: "Pending",
    id: "19123",
  },
  {
    partyType: "Corporate",
    CaseType: "Criminal",
    partyName: "ABC Corp",
    Amount: "$5,000",
    Note: "Completed",
    id: "18123",
  },
  // ... other initial items
];

const LocateCommissionPopup = (props: any) => {
  const [items, setItems] = useState<Item[]>(initialItems);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const selectedRuleItem = useSelector(
    (state: RootState) => state.commission.selectedRuleItem
  );

  const handleCellChange = (
    rowIndex: number,
    columnKey: keyof Item,
    value: string
  ) => {
    const updatedItems = [...items];
    updatedItems[rowIndex] = { ...updatedItems[rowIndex], [columnKey]: value };
    setItems(updatedItems);
  };

  const columns: MRT_ColumnDef<Item>[] = [
    {
      accessorKey: "partyType",
      header: "Party Type",
      enableEditing: true,
    },
    {
      accessorKey: "CaseType",
      header: "Case Type",
      enableEditing: true,
    },
    {
      accessorKey: "partyName",
      header: "Party Name",
      enableEditing: true,
    },
    {
      accessorKey: "Amount",
      header: "Amount",
      enableEditing: true,
    },
    {
      accessorKey: "Note",
      header: "Note",
      enableEditing: true,
    },
  ];
  const handleUseRule = () => {
    const selectedRowId = Object.keys(rowSelection)[0]; // Get the first selected row ID
    if (selectedRowId) {
      const selectedRow = items.find((item) => item.id === selectedRowId);
      dispatch(setSelectedRuleItem(selectedRow || null));
      props.setislocate(false);
    }
  };

  return (
    <Dialog open={props.islocate}>
      <div>
        <DialogTrigger disableButtonEnhancement>
          <Button>Commission Rule</Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Locate Commission Rule</DialogTitle>
            <DialogContent>
              <MaterialReactTable
                columns={columns}
                data={items}
                onRowSelectionChange={setRowSelection}
                getRowId={(row) => row.id}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableRowSelection={true}
                enableSelectAll={false}
                enableColumnActions={false}
                enableMultiRowSelection={false}
                enableRowActions={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                state={{ rowSelection }}
                enablePagination={false}
                positionActionsColumn="last"
                positionToolbarAlertBanner="none"
              />
            </DialogContent>
            <DialogActions>
              <Button
                appearance="primary"
                className="asc-button-primary"
                onClick={handleUseRule}
              >
                Use Rule
              </Button>
              <Button
                appearance="secondary"
                onClick={() => {
                  props.setislocate(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </div>
    </Dialog>
  );
};

export default LocateCommissionPopup;
