import React, { useState } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";

interface Item {
  CaseType: string;
  Provider: string;
  Reference: string;
}

const initialItems: Item[] = [
  {
    CaseType: "Life Assurance",
    Provider: "",
    Reference: "",
  },
  {
    CaseType: "Pensions",
    Provider: "Canada Life",
    Reference: "",
  },
  {
    CaseType: "Savings Plan",
    Provider: "",
    Reference: "",
  },
];

const AssignCase = (props: any) => {
  const [items, setItems] = useState<Item[]>(initialItems);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});

  const handleCellChange = (
    rowIndex: number,
    columnKey: keyof Item,
    value: string
  ) => {
    const updatedItems = [...items];
    updatedItems[rowIndex] = { ...updatedItems[rowIndex], [columnKey]: value };
    setItems(updatedItems);
  };

  const columns: MRT_ColumnDef<Item>[] = [
    {
      accessorKey: "CaseType",
      header: "Case Type",
    },
    {
      accessorKey: "Reference",
      header: "Reference",
    },
    {
      accessorKey: "Provider",
      header: "Provider",
    },
  ];

  return (
    <Dialog open={props.iscase}>
      <div>
        <DialogSurface
          style={{
            height: "400px",
            width: "500px",
            maxHeight: "90vh",
            maxWidth: "90vw",
            overflow: "auto", // Ensure scrolling if content exceeds height/width
          }}
        >
          <DialogBody>
            <DialogTitle>Assign Case</DialogTitle>
            <DialogContent>
              <MaterialReactTable
                columns={columns}
                data={items}
                enablePagination={false}
                enableStickyHeader
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableDensityToggle={false}
                enableRowSelection={true}
                enableSelectAll={false}
                enableColumnActions={false}
                enableMultiRowSelection={false}
                enableRowActions={false}
                enableFullScreenToggle
                positionActionsColumn="last"
                positionToolbarAlertBanner="none"
                muiTableBodyRowProps={({ row }: any) => ({
                  onClick: () =>
                    setSelectedRow((prev: any) => ({
                      [row.id]: !prev[row.id],
                    })),
                  selected: selectedRow[row.id],
                  sx: {
                    cursor: "pointer",
                    backgroundColor: selectedRow[row.id]
                      ? "#f1ebea"
                      : "transparent",
                    "& > *": {
                      backgroundColor: selectedRow[row.id]
                        ? "#f1ebea"
                        : "transparent",
                    },
                  },
                })}
              />
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                onClick={() => {
                  props.setisCaseType(false);
                }}
              >
                Clear
              </Button>
              <Button appearance="primary" className="asc-button-primary">
                Assign
              </Button>
              <Button
                appearance="secondary"
                onClick={() => {
                  props.setisCase(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </div>
    </Dialog>
  );
};

export default AssignCase;
