import { Button, Label, Tooltip } from "@fluentui/react-components";
import React, { useEffect, useMemo, useState } from "react";
import {
  DocumentBulletList20Regular,
  Dismiss24Regular,
  ChevronLeft20Regular,
} from "@fluentui/react-icons";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";

import {
  convertStringToArray,
  formatedDate,
  formatedTime,
  getClientNoteCategories,
  getClientType,
  getYes_NoConversion,
  isObjectEmpty,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { assetDetails } from "../../../shared/caseSummary";
import OverlayLoader from "../../../components/OverlayLoader";
import "./style.css";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
import { object, string } from "yup";
import apiServices from "../../../service";
import CaseSummaryDetail from "./CaseSummaryDetail";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { Eye24Filled, EyeOff24Filled } from "@fluentui/react-icons";
import ContentLoader from "../../../components/Loader/ContentLoader";
interface PropsType {
  [x: string]: any;
}

function CaseSummary(props: PropsType) {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [fundValue, setFundValue] = useState<any>();
  const [paymentValue, setPaymentValue] = useState<any>();
  const [withdrawalValue, setWithdrawalValue] = useState<any>();
  const [showNonCurrentCases, setShowNonCurrentCases] =
    useState<Boolean>(false);
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "case_type",
        header: "Case Type",
      },
      {
        accessorKey: "owner",
        header: "Owner",
      },
      {
        accessorKey: "subtype",
        header: "Subtype",
      },
      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "reference",
        header: "Reference",
      },
      {
        accessorKey: "start_date",
        header: "Start Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
      },
      {
        accessorKey: "value",
        header: "Value",
      },
      {
        accessorKey: "value_date",
        header: "Value Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "report_note",
        header: "Report Note",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.report_note} />
          </>
        ),
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const [assetData, setAssetData] = useState([]);
  const [liabilityData, setLiabilityData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [reviewData, setReviewData] = useState<PropsType>({});
  const [providerData, setProviderData] = useState<PropsType>({});
  const [caseSummaryData, setcaseSummary] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any | {}>({});
  const [currentPage, setCurrentPage] = useState("listing");
  const [activeTab, setActiveTab] = useState("");
  const [detailDataItems, setDetailDataItems] = useState([]);
  const [activeRowItemDetail, setActiveRowItemDetail] = useState<any | {}>({});
  const [providerList, setProviderList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseSummary, setCaseSummary] = useState<any>({});

  useEffect(() => {
    if (caseSummary.length > 0 && Object.keys(rowSelection)[0] !== undefined) {
      let index = 0;
      index = caseSummary.findIndex(
        (x: any) => x.id === Object.keys(rowSelection)[0]
      );
      setSelectedItem(caseSummary[index]);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (activeTab !== "") {
      getCaseDetail(activeTab);
    }
  }, [activeTab]);

  const getProviderDetails = () => {
    if (
      !isObjectEmpty(activeRowItemDetail) &&
      activeRowItemDetail?.provider_correspondence &&
      activeRowItemDetail?.provider_correspondence !== null
    ) {
      apiServices.systemManager.providers
        .getProvider(activeRowItemDetail.provider_correspondence)
        .then((response) => {
          if (response.data) {
            if (response.data.data) {
              setProviderList(response.data.data);
            }
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };
  // console.log(providerList,"---consooleee---");

  useEffect(() => {
    getProviderDetails();
  }, [activeRowItemDetail]);

  const CaseSummaryDetails = (userId: any) => {
    setIsLoading(true);
    apiServices.caseSummaryList
      .getCaseSummary(userId, showNonCurrentCases ? "show" : "")
      .then((response: any) => {
        setIsLoading(false);
        if (response.data && response.data.data) {
          let resultData: any = [];
          Object.keys(response.data.data).map((key: any, index) => {
            for (const key in response.data.data[index]) {
              if (response.data.data[index].hasOwnProperty(key)) {
                if (key === "asset") {
                  response.data.data[index][key].map((val: any) => {
                    resultData.push({
                      id: val.asset_id,
                      customer_id: val.customer_id,
                      category: "Asset",
                      case_type: "Investment",
                      owner: val.owner,
                      subtype: val.investment_subtype,
                      provider: val.investment_provider,
                      reference: val.investment_account_number,
                      start_date: val.investment_start_date,
                      value: val.current_value,
                      value_date: val.value_date,
                      status: val.investment_status,
                      report_note: val.report_note,
                      review_assigned: val.review_assigned,
                      review_completed: val.review_completed,
                      review_date: val.review_date,
                      review_interval: val.review_interval,
                      review_reason: val.review_reason,
                      provider_correspondence: val.provider_correspondence,
                    });
                  });
                } else if (key === "liability") {
                  response.data.data[index][key].map((val: any) => {
                    resultData.push({
                      id: val.liability_id,
                      customer_id: val.customer_id,
                      category: "Liability",
                      case_type: val.case_type,
                      owner: getClientType(val.joint_indicator),
                      subtype: val.subtype,
                      provider: val.provider,
                      reference: val.account_number,
                      start_date: val.start_date,
                      value: val.property_value,
                      value_date: "",
                      status: val.status,
                      report_note: val.report_note,
                      review_assigned: val.review_assigned,
                      review_completed: val.review_completed,
                      review_date: val.review_date,
                      review_interval: val.review_interval,
                      review_reason: val.review_reason,
                      provider_correspondence: val.provider_correspondence,
                    });
                  });
                } else if (key === "policy") {
                  response.data.data[index][key].map((val: any) => {
                    resultData.push({
                      id: val.policy_id,
                      customer_id: val.customer_id,
                      category: "Policy",
                      case_type: val.case_type,
                      owner: getClientType(val.joint_indicator),
                      subtype: val.subtype,
                      provider: val.provider,
                      reference: val.account_number,
                      start_date: val.start_date,
                      value: val.property_value,
                      value_date: "",
                      status: val.status,
                      report_note: val.report_note,
                      review_assigned: val.review_assigned,
                      review_completed: val.review_completed,
                      review_date: val.review_date,
                      review_interval: val.review_interval,
                      review_reason: val.review_reason,
                      provider_correspondence: val.provider_correspondence,
                    });
                  });
                }
              }
            }
          });
          setCaseSummary(resultData);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log("err");
      });
  };

  useEffect(() => {
    CaseSummaryDetails(props.activeCustomerId);
  }, [showNonCurrentCases]);
  const updateCaseActions = () => {
    setCaseSummary([...assetData, ...liabilityData, ...policyData]);
  };
  useEffect(() => {
    updateCaseActions();
  }, [assetData, liabilityData, policyData]);
  const getCaseDetail = (tab: string) => {
    switch (tab) {
      case "fund":
        apiServices.fund
          .getList(
            props.activeCustomerId,
            (selectedItem as { [key: string]: any }).id
          )
          .then((response) => {
            if (response.data) {
              if (response.data.data && response.data.data.result) {
                setDetailDataItems(response.data.data?.result || []);
                setFundValue(response.data.data?.total_fund);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "payments":
        apiServices.Payments.getPayments(
          (selectedItem as { [key: string]: any }).id,
          props.activeCustomerId
        )
          .then((response) => {
            if (response.data) {
              if (response.data.data && response.data.data.result) {
                setDetailDataItems(response.data.data?.result || []);
                setPaymentValue(response.data.data?.total);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "withdrawals":
        apiServices.Withdrawals.getList(
          (selectedItem as { [key: string]: any }).id,
          props.activeCustomerId
        )
          .then((response) => {
            if (response.data) {
              if (response.data.data && response.data.data.result) {
                setDetailDataItems(response.data.data?.result || []);
                setWithdrawalValue(response.data.data?.total);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;

      case "actions":
        apiServices.Actions.getActions(
          (selectedItem as { [key: string]: any }).id,
          props.activeCustomerId
        )
          .then((response) => {
            if (response.data) {
              if (response.data.data) {
                setDetailDataItems(response.data.data);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "review":
        const selectedReview = selectedItem;
        setReviewData(selectedReview);
        break;
      case "provider":
        const selectedProvider = providerList;
        setProviderData(selectedProvider);
        break;
      case "valuations":
        apiServices.valuation
          .getvaluation(
            (selectedItem as { [key: string]: any }).id,
            props.activeCustomerId
          )
          .then((response) => {
            if (response.data) {
              if (response.data.data) {
                setDetailDataItems(response.data.data);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isObjectEmpty(selectedItem)) {
      getCaseDetails(selectedItem);
    }
  }, [selectedItem]);

  const getCaseDetails = (rowItem: any) => {
    switch (rowItem.category) {
      case "Asset":
        apiServices.assets
          .getDetail(rowItem.id, rowItem.customer_id)
          .then((response) => {
            handleSuccessResponse(response);
          })
          .catch((err) => {
            handleErrorResponse(err);
          });
        break;
      case "Liability":
        apiServices.liability
          .getDetail(rowItem.id, rowItem.customer_id)
          .then((response) => {
            handleSuccessResponse(response);
          })
          .catch((err) => {
            handleErrorResponse(err);
          });
        break;
      case "Policy":
        apiServices.policy
          .getDetail(rowItem.id, rowItem.customer_id)
          .then((response) => {
            handleSuccessResponse(response);
          })
          .catch((err) => {
            handleErrorResponse(err);
          });
        break;
      default:
        break;
    }
  };
  const handleSuccessResponse = (response: any) => {
    if (
      response.data &&
      !response.data.isError &&
      !isObjectEmpty(response.data.data)
    ) {
      setActiveRowItemDetail(response.data.data);
    }
  };
  const handleErrorResponse = (err: any) => {
    console.log(err);
  };

  const Details = (activeRowItemDetail: any) => {
    if (selectedItem.category === "Policy") {
      if (selectedItem.case_type === "Life Assurance") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Linked to Mortgage :</b>
            <span>
              {getYes_NoConversion(activeRowItemDetail.mortgage_link) || "-"}
            </span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Sum Assured :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Pensions") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Contracted Out :</b>
            <span>{activeRowItemDetail.contracted_out || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.transfer_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Expression of Wish :</b>
            <span>{activeRowItemDetail.expression_of_wish || "-"}</span>
            <b>Expression of Wish Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.expression_of_wishnote || "-"}
            />
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>{" "}
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Savings Plans") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>{" "}
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>{" "}
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>{" "}
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Income Protection") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Deferred Period :</b>
            <span>{activeRowItemDetail.deffered_period || "-"}</span>
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Health Assurance") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>Rated :</b>
            <span>{activeRowItemDetail.rated || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "General") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Investments") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Policyholder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Assured :</b>
            <span>{activeRowItemDetail.assured_detail || "-"}</span>
            <b>Assured Name 1 :</b>
            <span>{activeRowItemDetail.assured_name1 || "-"}</span>
            <b>Assured Name 2 :</b>
            <span>{activeRowItemDetail.assured_name2 || "-"}</span>
            <b>Subtype :</b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Provider :</b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Purpose :</b>
            <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term :</b>
            <span>{activeRowItemDetail.term || "-"}</span>
            <b>Scheme Name :</b>
            <span>{activeRowItemDetail.scheme_name || "-"}</span>
            <b>Policy Number :</b>
            <span>{activeRowItemDetail.policy_number || "-"}</span>
            <b>Other Reference :</b>
            <span>{activeRowItemDetail.other_reference || "-"}</span>
            <b>Status :</b>
            <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Premium Indexed :</b>
            <span>{activeRowItemDetail.premium_indexed || "-"}</span>
            <b>Waiver :</b>
            <span>{activeRowItemDetail.waiver || "-"}</span>
            <b>Surrender Value :</b>
            <span>{activeRowItemDetail.surrender_value || "-"}</span>
            <b>Future Value :</b>
            <span>{activeRowItemDetail.future_value || "-"}</span>
            <b>Total Fund Value :</b>
            <span>{activeRowItemDetail.total_fund_value || "-"}</span>
            <b>Valuation Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.valuation_date) || "-"}
            </span>
            <b>Lump Sum Benefit :</b>
            <span>{activeRowItemDetail.lump_sum_benefit || "-"}</span>
            <b>Periodic Benefit :</b>
            <span>{activeRowItemDetail.periodic_benefit || "-"}</span>
            <b>Benefit Frequency :</b>
            <span>{activeRowItemDetail.benefit_frequency || "-"}</span>
            <b>Benefits Indexed :</b>
            <span>{activeRowItemDetail.benefits_indexed || "-"}</span>
            <b>Benefit Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.benefit_text || "-"}
            />
            <b>In Trust :</b>
            <span>{activeRowItemDetail.in_trust || "-"}</span>
            <b>In Trust Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.in_trust_note || "-"}
            />
            <b>Age Admitted :</b>
            <span>{activeRowItemDetail.age_admitted || "-"}</span>
            <b>Assigned To :</b>
            <span>{activeRowItemDetail.assigned_to || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.admin_note || "-"}
            />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      }
    } else if (selectedItem.category === "Asset") {
      if (selectedItem.case_type === "Investment") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>
              {activeRowItemDetail.case_type === "Unit-linked"
                ? "Investment"
                : activeRowItemDetail.case_type || "-"}
            </span>
            <b>Investment Holder :</b>
            <span>
              {getClientType(activeRowItemDetail.policy_holder) || "-"}
            </span>
            <b>Owner :</b>
            <span>{activeRowItemDetail.owner || "-"}</span>
            <b>Owner Name 1 :</b>
            <span>{activeRowItemDetail.owner_name1 || "-"}</span>
            <b>Owner Name 2 : </b>
            <span>{activeRowItemDetail.owner_name2 || "-"}</span>
            <b>Type : </b>{" "}
            <span>{activeRowItemDetail.investment_type || "-"}</span>
            <b>Subtype : </b>
            <span>{activeRowItemDetail.investment_subtype || "-"}</span>
            <b>Provider : </b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Account Number :</b>
            <span>{activeRowItemDetail.investment_account_number || "-"}</span>
            <b>Current Value: </b>
            <span>{activeRowItemDetail.current_value || "-"}</span>
            <b>Value Date :</b>
            <span>{formatedDate(activeRowItemDetail.value_date) || "-"}</span>
            <b>Purpose : </b> <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Start Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.investment_start_date) || "-"}
            </span>
            <b>End Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.investment_end_date) || "-"}
            </span>
            <b>Status : </b>
            <span>{activeRowItemDetail.investment_status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Service Status :</b>
            <span>{activeRowItemDetail.service_status || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent value={activeRowItemDetail.note_text || "-"} />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      }
    } else if (selectedItem.category === "Liability") {
      if (selectedItem.case_type === "Mortgages") {
        return (
          <div className="case_details">
            <b>Case Type :</b>
            <span>{activeRowItemDetail.case_type || "-"}</span>
            <b>Owner :</b>
            <span>
              {getClientType(activeRowItemDetail.joint_indicator) || "-"}
            </span>
            <b>Borrower :</b>
            <span>{activeRowItemDetail.borrower || "-"}</span>
            <b>Borrower Name 1 :</b>
            <span>{activeRowItemDetail.borrower_name1 || "-"}</span>
            <b>Borrower Name 2 : </b>
            <span>{activeRowItemDetail.borrower_name2 || "-"}</span>
            <b>Type : </b>{" "}
            <span>{activeRowItemDetail.liability_type || "-"}</span>
            <b>Subtype : </b>
            <span>{activeRowItemDetail.subtype || "-"}</span>
            <b>Repayment Method : </b>
            <span>{activeRowItemDetail.repayment_method || "-"}</span>
            <b>Purpose : </b> <span>{activeRowItemDetail.purpose || "-"}</span>
            <b>Provider : </b>
            <span>{activeRowItemDetail.provider_name || "-"}</span>
            <b>Provider Contact :</b>
            <span>{activeRowItemDetail.provider_contact_name || "-"}</span>
            <b>Provider Phone :</b>
            <span>{activeRowItemDetail.provider_phone || "-"}</span>
            <b>Address :</b>
            <p>
              {activeRowItemDetail.provider_address1 || "-"}
              <br />
              {activeRowItemDetail.provider_address2}
              <br />
              {activeRowItemDetail.provider_address3}
            </p>
            <b>Town :</b>
            <span>{activeRowItemDetail.provider_town || "-"}</span>
            <b>County :</b>
            <span>{activeRowItemDetail.provider_county || "-"}</span>
            <b>Country :</b>
            <span>{activeRowItemDetail.provider_country || "-"}</span>
            <b>Postcode :</b>
            <span>{activeRowItemDetail.provider_postcode || "-"}</span>
            <b>Provider Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.provider_note || "-"}
            />
            <b>Account Number :</b>
            <span>{activeRowItemDetail.account_number || "-"}</span>
            <b>Start Date :</b>
            <span>{formatedDate(activeRowItemDetail.start_date) || "-"}</span>
            <b>End Date :</b>
            <span>{formatedDate(activeRowItemDetail.end_date) || "-"}</span>
            <b>Term:</b> <span>{activeRowItemDetail.term || "-"}</span>
            <b>Amount Borrowed :</b>
            <span>{activeRowItemDetail.amount_borrowed || "-"}</span>
            <b>Amount Outstanding :</b>
            <span>{activeRowItemDetail.amount_outstanding || "-"}</span>
            <b>Outstanding Date :</b>
            <span>
              {formatedDate(activeRowItemDetail.amount_outstanding_date) || "-"}
            </span>
            <b>Interest Rate :</b>
            <span>{activeRowItemDetail.interest_rate || "-"}</span>
            <b>Interest Basis:</b>
            <span>{activeRowItemDetail.interest_basis || "-"}</span>
            <b>End of Fixed Rate:</b>
            <span>
              {formatedDate(activeRowItemDetail.mortgage_fixed_rate_end) || "-"}
            </span>
            <b>Status :</b> <span>{activeRowItemDetail.status || "-"}</span>
            <b>Status Date :</b>
            <span>{formatedDate(activeRowItemDetail.status_date) || "-"}</span>
            <b>Covered by Insurance? :</b>
            <span>{activeRowItemDetail.covered || "-"}</span>
            <b>Property Value :</b>
            <span>{activeRowItemDetail.property_value || "-"}</span>
            <b>Loan to Valuation % :</b>
            <span>{activeRowItemDetail.loan_valuation_percentage || "-"}</span>
            <b>Property Address :</b>
            <p>
              {activeRowItemDetail.property_address1 || "-"}
              <br />
              {activeRowItemDetail.property_address2}
              <br />
              {activeRowItemDetail.property_address3}
            </p>
            <b>Town :</b>
            <span>{activeRowItemDetail.property_town || "-"}</span>{" "}
            <b>County :</b>
            <span>{activeRowItemDetail.property_county || "-"}</span>{" "}
            <b>Country :</b>
            <span>{activeRowItemDetail.property_country || "-"}</span>
            <b>Postcode :</b>
            <span>{activeRowItemDetail.property_postcode || "-"}</span>
            <b>Admin Note :</b>
            <ReactQuillComponent value={activeRowItemDetail.note_text || "-"} />
            <b>Report Note :</b>
            <ReactQuillComponent
              value={activeRowItemDetail.report_note || "-"}
            />
          </div>
        );
      } else if (selectedItem.case_type === "Loans/Lease/HP") {
        return <></>;
      }
    } else {
      return (
        <>
          <div className="case_detail_text">
            <p>
              Title: <span>{activeRowItemDetail.NameAndAddress?.title}</span>
            </p>
            <p>
              First Names:
              <span>{activeRowItemDetail.NameAndAddress?.first_names}</span>
            </p>
          </div>
        </>
      );
    }
  };
  // useEffect(() => {
  //   getAssetDetails(props.activeCustomerId);
  //   getLiabilityDetails(props.activeCustomerId);
  //   getPolicyDetails(props.activeCustomerId);
  // }, []);

  const selectSubTab = (tab: string) => {
    setActiveTab(tab);
    setCurrentPage("detail");
  };

  return isLoading ? (
    <ContentLoader />
  ) : (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "details_btn_case_summary"
        ) ? (
          <Button
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("details_btn_case_summary")
                ? false
                : true
            }
            onClick={() => {
              setIsOpen(true);
            }}
            icon={<DocumentBulletList20Regular />}
            style={{ width: "fit-content" }}
            size="small"
          >
            Details
          </Button>
        ) : (
          ""
        )}
      </div>

      <div style={{ display: "flex", gap: 10, margin: "10px 0" }}>
        {selectedItem &&
          Object.keys(selectedItem).length !== 0 &&
          currentPage !== "listing" && (
            <>
              {!convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("back_to_case_summary") ? (
                <Button
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("back_to_case_summary")
                      ? false
                      : true
                  }
                  shape="square"
                  size="small"
                  icon={<ChevronLeft20Regular />}
                  onClick={() => {
                    setActiveTab("");
                    setCurrentPage("listing");
                  }}
                >
                  <span style={{ color: "red" }}>Back to Case Summary</span>
                </Button>
              ) : (
                ""
              )}
            </>
          )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "funds_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "fund" ? "black" : undefined,
              color: activeTab === "fund" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("fund");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("funds_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Funds
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "payments_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "payments" ? "black" : undefined,
              color: activeTab === "payments" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("payments");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("payments_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Payments
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "withdrawals_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "withdrawals" ? "black" : undefined,
              color: activeTab === "withdrawals" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("withdrawals");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("withdrawals_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Withdrawals
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "actions_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "actions" ? "black" : undefined,
              color: activeTab === "actions" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("actions");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("actions_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Actions
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "review_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "review" ? "black" : undefined,
              color: activeTab === "review" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("review");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("review_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Review
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "provider_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "provider" ? "black" : undefined,
              color: activeTab === "provider" ? "white" : undefined,
            }}
            size="small"
            onClick={() => {
              selectSubTab("provider");
            }}
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("provider_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Provider
          </Button>
        ) : (
          ""
        )}
        {!convertStringToArray(process.env.REACT_APP_HIDE_CTA_BUTTON).includes(
          "valuations_btn_case_summary"
        ) ? (
          <Button
            style={{
              background: activeTab === "valuations" ? "black" : undefined,
              color: activeTab === "valuations" ? "white" : undefined,
            }}
            onClick={() => {
              selectSubTab("valuations");
            }}
            size="small"
            disabled={
              !convertStringToArray(
                process.env.REACT_APP_DISABLE_CTA_BUTTON
              ).includes("valuations_btn_case_summary") &&
              selectedItem !== undefined &&
              Object.keys(selectedItem).length !== 0
                ? false
                : true
            }
          >
            Valuations
          </Button>
        ) : (
          ""
        )}
      </div>

      <div className="caseSummary">
        {currentPage === "listing" ? (
          <MaterialReactTable
            columns={columns}
            data={caseSummary}
            enableRowSelection
            enableColumnActions={false}
            onRowSelectionChange={setRowSelection}
            enablePagination={false}
            // enableRowNumbers
            enableBottomToolbar={false}
            rowNumberMode="static"
            enableMultiRowSelection={false}
            muiTableBodyRowProps={({ row }: any) => ({
              onClick: row.getToggleSelectedHandler(),
              title: "Double-click here to edit the record",
              onDoubleClick: () => {
                row.getToggleSelectedHandler();
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    contact_history_data: row.original,
                    type: row.original.category,
                    case_type: row.original.case_type,
                    setActiveItem: props.setActiveItem,
                    activeClient: props.activeClient,
                    customerId: props.activeCustomerId,
                    close: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Cancel",
                    },
                  })
                );
              },
              sx: { cursor: "pointer" },
            })}
            getRowId={(row) => row.id}
            enableRowActions={false}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
                // size: 300, //make actions column wider
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
              },
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <>
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("non_current_btn_case_summary") ? (
                  <Button
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("non_current_btn_case_summary")
                        ? false
                        : true
                    }
                    style={{ marginTop: "15px" }}
                    onClick={() => setShowNonCurrentCases(!showNonCurrentCases)}
                    icon={
                      showNonCurrentCases ? <EyeOff24Filled /> : <Eye24Filled />
                    }
                    size="small"
                    title={
                      showNonCurrentCases
                        ? ""
                        : "Activating the button filters the list to show 'In-force', 'Pending,' and 'Active' statuses"
                    }
                  >
                    {showNonCurrentCases ? "Hide" : "Show"} non-current Cases
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
            initialState={{
              columnPinning: { right: ["mrt-row-actions"] },
            }}
            positionActionsColumn="last"
            positionToolbarAlertBanner="bottom"
            state={{ rowSelection }}
          />
        ) : (
          <>
            <CaseSummaryDetail
              data={detailDataItems}
              context={activeTab}
              fundValue={fundValue}
              paymentValue={paymentValue}
              withdrawalValue={withdrawalValue}
            />
            {activeTab == "review" ? (
              <div className="review_provider_container">
                <div className="review_provider">
                  <b>Review Date:</b>
                  <p>{formatedDate(reviewData.review_date) || "-"}</p>
                  <b>Review Interval (months):</b>
                  <p>{reviewData.review_interval || 0} months</p>
                  <b>Review Completed:</b>
                  <p>{formatedDate(reviewData.review_completed) || "-"}</p>
                  <b>Review Note:</b>
                  <p>
                    <ReactQuillComponent
                      value={reviewData.review_reason || "-"}
                    />
                  </p>
                  <b>Review Assigned:</b>
                  <p>{reviewData.review_assigned || "-"}</p>
                </div>
              </div>
            ) : null}

            {activeTab == "provider" ? (
              <div className="review_provider_container">
                <div className="review_provider">
                  <b>Provider Name: </b>
                  <span>{providerData.provider_name || "-"}</span>
                  <b>Group: </b>
                  <span>{providerData.group_name || "-"}</span>
                  <b>Contact: </b>
                  <span>{providerData.contact_name || "-"}</span>
                  <b>Address: </b>
                  <span>{providerData.address1 || "-"}</span>
                  <br />
                  <span>{providerData.address2 || "-"}</span>
                  <br />
                  <span>{providerData.address3 || "-"}</span>
                  <b>Town: </b>
                  <span>{providerData.town || "-"}</span>
                  <b>County: </b>
                  <span>{providerData.county || "-"}</span>
                  <b>Country: </b>
                  <span>{providerData.country || "-"}</span>
                  <b>Postcode: </b>
                  <span>{providerData.postcode || "-"}</span>
                  <b>Salutation: </b>
                  <span>{providerData.salutation || "-"}</span>
                  <b>Telephone: </b>
                  <span>{providerData.telephone || "-"}</span>
                  <b>Fax: </b>
                  <span>{providerData.fax || "-"}</span>
                  <b>Mobile: </b>
                  <span>{providerData.mobile || "-"}</span>
                  <b>Email: </b>
                  <span>{providerData.email || "-"}</span>
                  <b>Status: </b>
                  <span>{providerData.status || "-"}</span>
                  <b>Note: </b>
                  <span>
                    <ReactQuillComponent
                      value={providerData.note_text || "-"}
                    />
                  </span>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>

      <OverlayDrawer
        position={"end"}
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        className={"common-drawer"}
        style={{ width: "400px" }}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{Details(activeRowItemDetail)}</DrawerBody>
      </OverlayDrawer>
    </div>
  );
}

export default CaseSummary;
