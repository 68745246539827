import * as React from "react";
import { makeStyles, Button, Label } from "@fluentui/react-components";
import { ChevronLeftFilled, ChevronRightFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    minWidth: "min-content",
    alignItems: "end",
    justifyContent: "end",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
});

export const Paginate = ({
  handlePrevious,
  handleNext,
  page,
  nextPage,
}: any) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Button
        size="small"
        shape="square"
        onClick={handlePrevious}
        disabled={page === 1}
        appearance="transparent"
        icon={<ChevronLeftFilled />}
      >
        Previous
      </Button>

      <Button
        shape="square"
        size="small"
        onClick={handleNext}
        disabled={nextPage === false}
        appearance="transparent"
        icon={<ChevronRightFilled />}
        iconPosition="after"
      >
        Next
      </Button>
    </div>
  );
};
