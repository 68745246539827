import { Badge, Button, Checkbox, Tooltip } from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_RowSelectionState,
} from "material-react-table";
import React, { useMemo, useState } from "react";

import FilterTab from "../CommissionReceipts/FilterTab";
import FeesChargesEditForm from "./FeesChargesEditForm";
import TableComponent from "../CommissionDistributon/DistributionTable";
interface DataRow {
  commission_type: string;
  amount_to_company: string;
  receipt_start: string;
  end_date: string;
  payment_source: string;
  status: string;
  status_date: string;
  provider: string;
  client: boolean;
  client_name: string;
  case: boolean;
  case_reference: string;
}
const FeeschargesList = (props: any) => {
  const [editpopup, setEditpopup] = useState(false);
  const [distributioneditpopup, setDistributionEditpopup] = useState(false);

  const [datalist, setDataList] = useState<DataRow[]>([
    {
      commission_type: "Renewal- General ",
      amount_to_company: "78.00",
      receipt_start: "01/02/2016",
      end_date: "30/04/2099 ",
      status: "Receipts stopped ",
      status_date: "03/10/2024",
      payment_source: "Product Provider",
      provider: "Payment Sheild",
      client: true,
      client_name: "Robert",
      case: false,
      case_reference: "",
    },
    {
      commission_type: "Client management fee",
      amount_to_company: "78.00",
      receipt_start: "01/02/2016",
      end_date: "30/04/2099 ",
      status: "Receipts stopped ",
      status_date: "03/10/2024",
      payment_source: "Client",
      provider: "Payment Sheild",
      client: false,
      client_name: "Robert",
      case: false,
      case_reference: "",
    },
    {
      commission_type: "Initial Advice Fee-Pensions",
      amount_to_company: "78.00",
      receipt_start: "01/02/2016",
      end_date: "30/04/2099 ",
      status: "Receipts stopped ",
      status_date: "03/10/2024",
      payment_source: "Client",
      provider: "Payment Sheild",
      client: true,
      client_name: "Robert",
      case: false,
      case_reference: "",
    },
    {
      commission_type: "Renewal-Income Protection ",
      amount_to_company: "78.00",
      receipt_start: "01/02/2016",
      end_date: "30/04/2099 ",
      status: "Receipts stopped ",
      status_date: "03/10/2024",
      payment_source: "Product Provider",
      provider: "Payment Sheild",
      client: false,
      client_name: "Robert",
      case: true,
      case_reference: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const handleRowClick = (rowId: string, commissionType: string) => {
    if (datalist.length === 0 || commissionType !== "Client management fee") {
      return; // Prevent expanding if there is no data or the commission type is not applicable
    }
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const handleCellEdit = (
    rowIndex: number,
    accessorKey: keyof DataRow,
    value: string
  ) => {
    setDataList((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = {
        ...newData[rowIndex],
        [accessorKey]: value,
      };
      return newData;
    });
  };
  const case_relatedcolumns = [
    { columnKey: "party_type", label: "Party Type" },
    { columnKey: "party_name", label: "Party Name" },
    { columnKey: "status", label: "Status" },
    { columnKey: "status_date", label: "Status Date" },
  ];
  const case_relateditems = [
    {
      party_type: { label: "Consultant" },
      party_name: { label: "Jone Done" },
      status: { label: "In Payment" },
      status_date: { label: "17/10/2024" },
    },
  ];
  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      {
        accessorKey: "commission_type",
        header: "Commission Type",
      },
      {
        accessorKey: "amount_to_company",
        header: "Amount to Company",
      },
      {
        accessorKey: "receipt_start",
        header: " Receipt Start",
      },
      {
        accessorKey: "end_date",
        header: "End Date",
      },
      {
        accessorKey: "payment_source",
        header: "Payment Source",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "status_date",
        header: "Status Date",
      },
      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "client",
        header: "Client",
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.client === true}
            // onChange={(e, checked) =>
            //   handleCheckboxChange(row.index, "client", checked)
            // }
          />
        ),
      },
      {
        accessorKey: "client_name",
        header: " Client Name",
      },
      {
        accessorKey: "case",
        header: "Case",
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.case === true}
            // onChange={(e, checked) =>
            //   handleCheckboxChange(row.index, "client", checked)
            // }
          />
        ),
      },
      {
        accessorKey: "case_reference",
        header: "Case Reference ",
      },
    ],
    []
  );
  const CommissionTypeOptions = [
    { label: "A J Bell", checked: true, id: "12254232" },
    { label: "Bob", checked: false, id: "12224532" },
    { label: "Tom", checked: false, id: "12432232" },
    { label: "Anderson", checked: true, id: "12232232" },
  ];
  return (
    <div className="client-list-wrapper">
      <MaterialReactTable
        renderTopToolbarCustomActions={({ table }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button
              className="asc-button-primary"
              appearance="primary"
              onClick={() => {
                setEditpopup(true);
              }}
            >
              Add New
            </Button>
            <FilterTab
              filterPopup={props.filterPopup}
              setFilterPopup={props.setFilterPopup}
              selectedMenuItem="Select Parties"
              title="Select Commission Type"
              providerOptions={CommissionTypeOptions}
            />
            {/* <FilterTab
              setFilterPopup={props.setFilterPopup}
              filterPopup={props.filterPopup}
              selectedMenuItem={props.selectedMenuItem}
              selectedProviders={props.selectedProviders}
              titile="Select Commission Type"
            /> */}
            {/* <div className="filter_style">
              <span className="customTriggerText">Define Dates ,</span>
              Management Fee
              <>
                <Badge className="badge">+2</Badge>
              </>
            </div> */}
          </div>
        )}
        columns={columns}
        data={datalist}
        enableColumnFilters={true}
        enableGlobalFilter={false}
        enableDensityToggle={true}
        enableExpandAll={false}
        enableRowSelection={false} // Disable row selection
        enableSelectAll={false} // Disable "Select All" option
        enableRowActions={false} // Disable row actions
        enableMultiRowSelection={false} // Disable multi-row selection
        enableColumnActions={false}
        enableStickyHeader
        enablePinning
        manualPagination
        initialState={{
          columnPinning: {
            left: ["mrt-row-expand", "commission_type"],
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row.id, row.original.commission_type),
          onDoubleClick: () => setEditpopup(true),
          selected: selectedRow[row.id],
          sx: {
            cursor: "pointer",
            backgroundColor: selectedRow[row.id] ? "#f1ebea" : "transparent",
            "& > *": {
              backgroundColor: selectedRow[row.id] ? "#f1ebea" : "transparent",
            },
          },
          title: "Double click for edit details", // Add tooltip text here
        })}
        renderDetailPanel={({ row }) =>
          datalist.length > 0 &&
          row.original.commission_type === "Client management fee" &&
          expandedRow === row.id ? (
            <TableComponent
              columns={case_relatedcolumns}
              items={case_relateditems}
              level={4}
            />
          ) : null
        }
        renderRowActions={({ row, table }) => (
          <Tooltip content="Double click for edit details" relationship="label">
            <div>{/* Content of the row */}</div>
          </Tooltip>
        )}
        // renderRowActions={({ row, table }) => (
        //   <Tooltip content="Edit" relationship="label" positioning="below">
        //     <Button
        //       appearance="subtle"
        //       onClick={() => {
        //         setConfirmationPopupPopup(true);
        //       }}
        //       icon={<Edit16Filled primaryFill="var(--button-color)" />}
        //     />
        //   </Tooltip>
        // )}
      />
      <FeesChargesEditForm
        setEditpopup={setEditpopup}
        editpopup={editpopup}
        setDistributionEditpopup={setDistributionEditpopup}
        distributioneditpopup={distributioneditpopup}
      />
    </div>
  );
};

export default FeeschargesList;
