import { useEffect, useState } from "react";
import "./style.css";
import FormContent from "../../components/Form/FormContent";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { setFormData, resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { formBuilder } from "../../utils/formGenerator";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  Label,
  Spinner,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  Add16Filled,
} from "@fluentui/react-icons";
import apiServices from "../../service";
import {
  checkUserPermission,
  formatAmount,
  getAmountType,
  getCommissionCategory,
  getUserDefinedType,
} from "../../utils";
import SystemManagerTableDataList from "./SystemManagerTableDataList";
import { UserPermissionsList } from "../../config";
import { data } from "../../redux/SystemAdminFormCollection";
import { FormErrorMessage } from "../../components/FormErrorMessage/FormErrorMessage";
import SystemManagerDeletePopup from "../../components/SystemManagerPopup/SystemManagerDeletePopup";
import ModalDialog from "../../components/ModalDialog";
import OverlayLoader from "../../components/OverlayLoader";
import ContentLoader from "../../components/Loader/ContentLoader";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
    marginTop: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type FormContainerWithListProps = {
  [x: string]: any;
};

const SystemManagerFormContainer = (props: FormContainerWithListProps) => {
  let { context } = props;
  const classes = useStyles();
  const [nextAction, setNextAction] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [mode, setMode] = useState("");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [selectedRowItem, setSelectedRowItem] = useState<{
    [key: string]: any;
  }>({});
  const [initialFormData, setInitialFormData] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [optionList, setOptionList] = useState<any>([]);
  const [isAdminForm, setIsAdminForm] = useState<boolean>(false);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer?.isFormDataUpdated
  );
  const providersList = useSelector((state: RootState) => state.providers);
  const [permissionList, setPermissionList] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<{ [key: string]: any }>({});

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const dispatch: AppDispatch = useDispatch();
  const [currentRecord, setCurrentRecord] = useState(1);
  const [deletePopup, setDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };
  useEffect(() => {
    setPermissionList(props.permissionsList);
  }, [props.permissionsList]);

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
    if (JSON.stringify(formik.initialValues) === JSON.stringify(newPresent)) {
      dispatch(resetFormData());
      setFormMode("");
    } else {
      dispatch(setFormData());
    }
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  useEffect(() => {
    if (context !== "") {
      generateDynamicForm(context);
    }
  }, [context]);
  useEffect(() => {
    if (props.dataList.length > 0) {
      setSelectedRowItem(props.dataList[0]);
      setMode("view");
    }
  }, [props.dataList]);
  useEffect(() => {
    if (
      mode &&
      selectedRowItem &&
      ["view", "edit"].includes(mode) &&
      Object.keys(selectedRowItem).length !== 0
    ) {
      let rowItem = {};
      if (context === "MASTER_SETTINGS_INCOME_CATEGORY") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "MASTER_SETTINGS_EXPENSE_CATEGORY") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "MASTER_SETTINGS_OBJECTIVES") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "SYSTEM_MANAGER_USERLIST") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
        getUserDetail(selectedRowItem);
      } else if (context === "MASTER_SETTINGS_PROVIDERS") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "MASTER_SETTINGS_USER_DEFINED") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          type:
            mode == "view"
              ? getUserDefinedType(selectedRowItem.type)
              : selectedRowItem.type,
        };
      } else if (context === "MASTER_SETTINGS_CASE_ACTIONS") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "COMMISSION_TYPES") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          category:
            mode == "view"
              ? getCommissionCategory(selectedRowItem.category)
              : selectedRowItem.category,
        };
      } else if (context === "EMAIL_TEMPLATE") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      } else if (context === "COMMISSION_RULES") {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
          amount_flag:
            mode === "view"
              ? getAmountType(selectedRowItem.amount_flag)
              : selectedRowItem.amount_flag,
          amount:
            mode === "view"
              ? formatAmount(
                  selectedRowItem.amount,
                  selectedRowItem.amount_flag
                )
              : selectedRowItem.amount,
        };
      } else {
        rowItem = {
          ...rowItem,
          ...selectedRowItem,
        };
      }
      setInitialFormData(rowItem);
    }
  }, [mode, selectedRowItem]);
  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings: objFormSettings?.systemAdmin,
    });
    let filteredSelectionOptions = inputs
      .filter(
        (opt: any) =>
          opt.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.listType) &&
          opt.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.fieldOptionLabel,
        listType: opt.listType,
        formField: opt.field,
      }));
    setSelectFieldOptionLabels(filteredSelectionOptions);
    if (filteredSelectionOptions.length > 0) {
      getSelectionListOptions(filteredSelectionOptions);
    }

    setFormFieldData({ initialValues, inputs, validationSchema });
  };
  const formik = useFormik({
    initialValues:
      mode && ["edit", "view"].includes(mode)
        ? initialFormData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},
    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      // formik.resetForm();
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  const permissionAdded = (permission: any) => {
    const index = selectedPermissions.findIndex(
      (obj) => obj["id"] === permission["id"]
    );
    if (index === -1) {
      selectedPermissions.push(permission);
    } else {
      selectedPermissions.splice(selectedPermissions.indexOf(permission), 1);
    }
    setSelectedPermissions(selectedPermissions);
  };

  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  useEffect(() => {
    // fetchDataList();
  }, [props.initialData]);
  const getUserDetail = (row: any) => {
    apiServices.systemManager.manageUsers
      .getDetail(row.user_id)
      .then((response: any) => {
        setLoading(true);

        if (response.data && Object.keys(response.data).length !== 0) {
          setUserInfo(response.data);

          if (
            response.data.roles !== undefined &&
            response.data.roles.length > 0
          ) {
            setSelectedPermissions(response.data.roles);
          } else {
            setSelectedPermissions([]);
          }
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const deleteData = () => {
    if (context === "MASTER_SETTINGS_USER_DEFINED") {
      apiServices.systemManager.userDefined
        .delete(selectedRowItem.id, data)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_PROVIDERS") {
      apiServices.systemManager.providers
        .delete(selectedRowItem.id, data)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_EXPENSE_CATEGORY") {
      apiServices.systemManager.expenseCategory
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_INCOME_CATEGORY") {
      apiServices.systemManager.incomeCategory
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "COMMISSION_TYPES") {
      apiServices.systemManager.commissionType
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "COMMISSION_RULES") {
      apiServices.systemManager.commissionRule
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_CLIENT_ACTIONS") {
      apiServices.systemManager.loadTrackingAction
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_CASE_ACTIONS") {
      apiServices.systemManager.loadTrackingAction
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_OBJECTIVES") {
      apiServices.systemManager.objectives
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY") {
      apiServices.systemManager.attitudeToRiskcategory
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    } else if (context === "MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING") {
      apiServices.systemManager.attitudeToRiskRating
        .delete(selectedRowItem.id)
        .then((response: any) => {
          handleApiSuccessResponse(response);
        })
        .catch((e: Error) => {
          handleApiErrorResponse(e);
        });
    }
    setDeletePopup(false);
    // dispatch(setDialogModalOptions({ open: false }));
  };

  const formSubmitted = (values: any) => {
    // setIsFormLoading(true);
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    setFormError([]);
    let reqBody: { [key: string]: any } = {};

    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }

    if (mode === "add") {
      setFormError([]);
      setApiProcessing(true);
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "income_category") {
          addReq[props.config.renderForm.reference_id] = {
            category: reqBody.category,
            description: reqBody.description,
            frequency: reqBody.frequency,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            auto_add: reqBody.auto_add === "" ? 0 : reqBody.auto_add,
          };
        } else if (
          props.config.renderForm.reference_id === "expense_category"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            category: reqBody.category,
            description: reqBody.description,
            frequency: reqBody.frequency,
            discretionary: reqBody.discretionary,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            auto_add: reqBody.auto_add === "" ? 0 : reqBody.auto_add,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk_category"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            category: reqBody.category,
            description: reqBody.description,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            auto_add: reqBody.auto_add === "" ? 0 : reqBody.auto_add,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk_rating"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            rating: reqBody.rating,
            rating_score: reqBody.rating_score,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            description: reqBody.description,
          };
        } else if (
          props.config.renderForm.reference_id === "objectives_detail"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
            detail: reqBody.detail,
            auto_add: reqBody.auto_add === "" ? 0 : reqBody.auto_add,
          };
        } else if (
          props.config.renderForm.reference_id === "user_defined_field"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            name: reqBody.name,
            type: reqBody.type === "" ? 0 : reqBody.type,
            length: reqBody.length === "" ? 0 : reqBody.length,
          };
        } else if (
          props.config.renderForm.reference_id === "tracking_client_actions"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            business_category: "client",
            case_type: reqBody.case_type,
            due_interval:
              reqBody.due_interval === "" ? 0 : parseInt(reqBody.due_interval),
            description: reqBody.description,
            assigned: reqBody.assigned,
          };
        } else if (
          props.config.renderForm.reference_id === "tracking_case_actions"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            business_category: "case",
            case_type: reqBody.case_type,
            due_interval:
              reqBody.due_interval === "" ? 0 : parseInt(reqBody.due_interval),
            description: reqBody.description,
            assigned: reqBody.assigned,
          };
        } else if (props.config.renderForm.reference_id === "provider") {
          addReq[props.config.renderForm.reference_id] = {
            group_name: reqBody.group_name,
            provider_name: reqBody.provider_name,
            contact_name: reqBody.contact_name,
            address1: reqBody.address1,
            address2: reqBody.address2,
            address3: reqBody.address3,
            town: reqBody.town,
            county: reqBody.county,
            country: reqBody.country,
            postcode: reqBody.postcode,
            telephone: reqBody.telephone,
            fax: reqBody.fax,
            mobile: reqBody.mobile,
            email: reqBody.email,
            salutation: reqBody.salutation,
            status: reqBody.status,
            note_text: reqBody.note_text,
          };
        } else if (props.config.renderForm.reference_id === "user_list") {
          addReq = {
            username: reqBody.user_email,
            enabled: true,
            totp: false,
            emailVerified: true,
            firstName: reqBody.full_name,
            lastName: reqBody.short_name,
            email: reqBody.user_email,
            credentials: [
              {
                type: "password",
                value: reqBody.password,
                temporary: false,
              },
            ],
            realmRoles: selectedPermissions,
          };
        } else if (
          props.config.renderForm.reference_id === "commission_types"
        ) {
          addReq[props.config.renderForm.reference_id] = {
            category: parseInt(reqBody.category),
            description: reqBody.description,
            commission_type: reqBody.commission_type,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "commission_rule") {
          addReq[props.config.renderForm.reference_id] = {
            case_type: reqBody.case_type,
            party_type: reqBody.party_type,
            party_name: reqBody.party_name,
            note_text: reqBody.note_text,
            amount: reqBody.amount ? parseInt(reqBody.amount) : 0,
            amount_flag: parseInt(reqBody.amount_flag),
          };
        } else {
          addReq[props.config.renderForm.reference_id] = reqBody;
        }
      }

      if (
        [
          "MASTER_SETTINGS_INCOME_CATEGORY",
          "MASTER_SETTINGS_EXPENSE_CATEGORY",
          "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY",
          "MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING",
          "MASTER_SETTINGS_OBJECTIVES",
          "MASTER_SETTINGS_PROVIDERS",
          "MASTER_SETTINGS_USER_DEFINED",
          "SYSTEM_MANAGER_USERLIST",
          "MASTER_SETTINGS_CLIENT_ACTIONS",
          "MASTER_SETTINGS_CASE_ACTIONS",
          "COMMISSION_TYPES",
          "COMMISSION_RULES",
        ].includes(props.context)
      ) {
        if (props.context === "MASTER_SETTINGS_INCOME_CATEGORY") {
          // setApiProcessing(true)
          apiServices.systemManager.incomeCategory
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);

              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_EXPENSE_CATEGORY") {
          // setLoading(true);
          apiServices.systemManager.expenseCategory
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              handleApiErrorResponse(e);
              setApiProcessing(false);
            });
        } else if (
          props.context === "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY"
        ) {
          // setLoading(true);
          apiServices.systemManager.attitudeToRiskcategory
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (
          props.context === "MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING"
        ) {
          // setLoading(true);
          apiServices.systemManager.attitudeToRiskRating
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_OBJECTIVES") {
          // setLoading(true);
          apiServices.systemManager.objectives
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_USER_DEFINED") {
          // setLoading(true);
          apiServices.systemManager.userDefined
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_CLIENT_ACTIONS") {
          // setLoading(true);
          apiServices.systemManager.loadTrackingAction
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_CASE_ACTIONS") {
          // setLoading(true);
          apiServices.systemManager.loadTrackingAction
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
              AddSelectOptions();
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "MASTER_SETTINGS_PROVIDERS") {
          // setLoading(true);
          apiServices.systemManager.providers
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "SYSTEM_MANAGER_USERLIST") {
          // setLoading(true);
          apiServices.systemManager.manageUsers
            .createUser(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "COMMISSION_TYPES") {
          // setLoading(true);
          apiServices.systemManager.commissionType
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else if (props.context === "COMMISSION_RULES") {
          // setLoading(true);
          apiServices.systemManager.commissionRule
            .add(addReq)
            .then((response: any) => {
              setApiProcessing(false);
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              setApiProcessing(false);
              handleApiErrorResponse(e);
            });
        } else {
        }
      } else {
      }
    } else if (mode === "edit") {
      setFormError([]);
      setApiProcessing(true);
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.config.renderForm.reference_id === "income_category") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            income_category_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "expense_category"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            expense_category_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk_category"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            attitude_to_risk_category_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "attitude_to_risk_rating"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            attitude_to_risk_rating_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "objectives_detail"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            objectives_detail_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "user_defined_field"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            user_defined_id: props.id,
          };
        } else if (
          props.config.renderForm.reference_id === "tracking_case_actions"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            case_actions_id: props.id,
            business_category: "case",
          };
        } else if (
          props.config.renderForm.reference_id === "tracking_client_actions"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            client_actions_id: props.id,
            business_category: "client",
          };
        } else if (props.config.renderForm.reference_id === "provider") {
          updateReq[props.config.renderForm.reference_id] = {
            ...reqBody,
            providers: props.id,
          };
        } else if (props.config.renderForm.reference_id === "user_list") {
          updateReq = {
            user_email: reqBody.user_email,
            full_name: reqBody.full_name,
            short_name: reqBody.short_name,
            roles: selectedPermissions,
          };
        } else if (
          props.config.renderForm.reference_id === "commission_types"
        ) {
          updateReq[props.config.renderForm.reference_id] = {
            category: parseInt(reqBody.category),
            description: reqBody.description,
            commission_type: reqBody.commission_type,
            sort_order: reqBody.sort_order === "" ? 0 : reqBody.sort_order,
          };
        } else if (props.config.renderForm.reference_id === "commission_rule") {
          updateReq[props.config.renderForm.reference_id] = {
            case_type: reqBody.case_type,
            party_type: reqBody.party_type,
            party_name: reqBody.party_name,
            note_text: reqBody.note_text,
            amount_flag: parseInt(reqBody.amount_flag) || 0,
            amount: parseFloat(reqBody.amount) || 0,
          };
        } else {
          updateReq[props.config.renderForm.reference_id] = reqBody;
        }
      }
      if (props.config.renderForm.reference_id === "income_category") {
        // setLoading(true);
        apiServices.systemManager.incomeCategory
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "expense_category") {
        // setLoading(true);
        apiServices.systemManager.expenseCategory
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "attitude_to_risk_category"
      ) {
        // setLoading(true);
        apiServices.systemManager.attitudeToRiskcategory
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "attitude_to_risk_rating"
      ) {
        // setLoading(true);

        apiServices.systemManager.attitudeToRiskRating
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "objectives_detail") {
        // setLoading(true);
        apiServices.systemManager.objectives
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "user_defined_field"
      ) {
        // setLoading(true);
        apiServices.systemManager.userDefined
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "provider") {
        // setLoading(true);
        apiServices.systemManager.providers
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "tracking_case_actions"
      ) {
        // setLoading(true);

        apiServices.systemManager.loadTrackingAction
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);

            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            setApiProcessing(false);

            handleApiErrorResponse(e);
          });
      } else if (
        props.config.renderForm.reference_id === "tracking_client_actions"
      ) {
        // setLoading(true);
        apiServices.systemManager.loadTrackingAction
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
            AddSelectOptions();
            generateDynamicForm(context);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "user_list") {
        // setLoading(true);
        apiServices.systemManager.manageUsers
          .updateUser(selectedRowItem.user_id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "commission_types") {
        // setLoading(true);
        apiServices.systemManager.commissionType
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else if (props.config.renderForm.reference_id === "commission_rule") {
        // setLoading(true);
        apiServices.systemManager.commissionRule
          .update(selectedRowItem.id, updateReq)

          .then((response: any) => {
            setApiProcessing(false);
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            setApiProcessing(false);
            handleApiErrorResponse(e);
          });
      } else {
      }
    }
  };

  const handleApiSuccessResponse = (response: any) => {
    if (!response.data.isError) {
      setTimeout(() => {
        setApiProcessing(false);
        props.notify(response.data.message, "", "success");
      }, 1000);
      // props.setFormUpdated(false);

      dispatch(resetFormData());

      props.reloadData();
      //  props.getList();
      //  if (props.resetClientFormSubmited) {
      //    props.resetClientFormSubmited();
      //  }
      //  if (props.changeMode) {
      //    props.setActiveItem(response.data.data);
      //    props.changeMode("edit");
      //  }
      if (nextAction === "next") {
        props.changeTab?.(props.tabMenuIndex.next);
      }
    } else if (response.data.isError) {
      if (
        response.data.error &&
        response.data.error &&
        Object.keys(response.data.error).length !== 0
      ) {
        if (Array.isArray(response.data.error.message)) {
          setFormError(response.data.error.message);
        } else {
          setFormError((prev) => [response.data.error.message]);
        }
      }
    } else {
      props.notify("Something went wrong", "", "error");
    }
  };
  const handleApiErrorResponse = (e: any) => {
    props.notify(e.message, "", "error");
  };
  const customHandleChange = (event: any) => {
    // alert("custom handle chnage");
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;

    // dispatch(setFormData());
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      dispatch(resetFormData());
      updated = false;
    } else {
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(mode === "add" ? "New" : "");
    }
  };
  const changeMode = (mode: string) => {
    setMode(mode);
  };
  const handleRowAction = (action: string, row: any) => {
    changeMode(action);
    setSelectedRowItem(row);
  };
  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      setLoading(true);
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          setLoading(false);
          // console.log("response--add options", response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          // console.log("index", index);
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });
        setLoading(true);
        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
            // console.log("response-selectionlist res", res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return loading ? (
    <ContentLoader />
  ) : (
    <div
      style={{
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {props.title !== undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Label size="large">{props.title}</Label>
        </div>
      )}

      {props.dataList && props.dataList.length === 0 && mode === "" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
          ) && (
            <Button
              className="asc-button-primary"
              style={{ marginRight: 2, marginBottom: 6 }}
              appearance="primary"
              size="medium"
              icon={<Add16Filled />}
              onClick={() => {
                changeMode("add");
              }}
            >
              Add New
            </Button>
          )}
        </div>
      )}
      {mode && mode !== "" && (
        <>
          {["edit", "add"].includes(mode) && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>
              <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={
                    past !== undefined && past.length === 0 ? true : false
                  }
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                }}
                disabled={!isFormDataUpdated}
              >
                Reset All
              </Button>
            </div>
          )}
          {props.mode && props.mode === "edit" && (
            <Button
              appearance="transparent"
              size="medium"
              icon={<Delete16Regular />}
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    content_line_1: "Do you want to delete?",
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "No",
                      type: "primary",
                    },
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Yes",
                    },
                  })
                );
              }}
            ></Button>
          )}
          {mode && mode === "view" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                Record {currentRecord} of {props.dataList.length}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
                ) && (
                  <Button
                    className="asc-button-primary"
                    style={{ marginRight: 2, marginBottom: 6 }}
                    appearance="primary"
                    size="medium"
                    icon={<Add16Filled />}
                    onClick={() => {
                      changeMode("add");
                    }}
                  >
                    Add New
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
                ) && (
                  <Button
                    style={{ marginRight: 2, marginBottom: 6 }}
                    icon={<Edit24Filled />}
                    size="medium"
                    onClick={() => {
                      changeMode("edit");
                    }}
                  >
                    Edit
                  </Button>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_DELETE_USER
                ) && (
                  <Button
                    icon={<Delete16Regular />}
                    style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                    size="medium"
                    onClick={() => {
                      setDeletePopup(true);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          )}
          <SystemManagerDeletePopup
            deletePopup={deletePopup}
            setDeletePopup={setDeletePopup}
            deleteData={deleteData}
          />
          {formError && formError.length > 0 && (
            <FormErrorMessage
              errorMessages={formError}
              onDismiss={() => setFormError([])}
            />
          )}

          {/* {isOptionLoaded && ( */}
          <FormContent
            {...{
              formik,
              formFieldData,
              mode,
              handleOnChange,
              isAdminForm,
              optionList,
              customHandleChange,
              permissionList,
              selectedPermissions,
              context,
              permissionAdded,
              selectFieldOptions,
              newSelectOptionAdded,
            }}
          />

          {/* )} */}
          {mode !== "view" && (
            <div className={classes.buttonWrapper}>
              <Button
                className="asc-button-primary"
                appearance="primary"
                disabled={
                  context === "MASTER_SETTINGS_USER_DEFINED"
                    ? false
                    : !isFormDataUpdated
                }
                onClick={() =>
                  isFormDataUpdated ||
                  context === "MASTER_SETTINGS_USER_DEFINED"
                    ? formik.handleSubmit()
                    : ""
                }
                icon={apiProcessing ? <Spinner size="tiny" /> : null}
              >
                {apiProcessing ? "Processing" : "Save"}
              </Button>
              <Button
                onClick={() => {
                  if (isFormDataUpdated === true) {
                    dispatch(
                      setDialogModalOptions({
                        open: true,
                        content_line_1:
                          "We have detected an attempt to move away from the current page.",
                        content_line_2:
                          "This would cause all changes made to be lost",
                        cancel: {
                          onclick: () => {
                            dispatch(
                              setDialogModalOptions({
                                open: false,
                              })
                            );
                            // props.setFormUpdated(false);
                            dispatch(resetFormData());
                            formik.resetForm();
                            props.dataList.length === 0
                              ? changeMode("")
                              : changeMode("view");
                          },
                          label: "Leave the Page",
                        },
                        no: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                          },
                          label: "Stay on Page",
                          type: "primary",
                        },
                      })
                    );
                  } else {
                    formik.resetForm();
                    props.dataList.length === 0
                      ? changeMode("")
                      : changeMode("view");
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </>
      )}

      {mode === "" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 200,
          }}
        >
          <>
            {" "}
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click Add New to create a new record
            </Label>
          </>
        </div>
      ) : (
        <div>
          <SystemManagerTableDataList
            {...{
              changeMode,
              handleRowAction,
              mode,
              context: props.context,
              data: props.dataList,
              selectedRowItem,
              setCurrentRecord,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SystemManagerFormContainer;
