import React from "react";
import { Spinner } from "@fluentui/react-components";
interface ContentLoaderProps {
  message?: string;
  style?: React.CSSProperties;
}
const ContentLoader: React.FC<ContentLoaderProps> = ({ message, style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 80px)",
        ...style,
      }}
    >
      <Spinner
        size="huge"
        labelPosition="below"
        label={message ? message : "Loading..."}
      />
    </div>
  );
};

export default ContentLoader;
