import React from "react";
import { makeStyles, Button } from "@fluentui/react-components";
import type { ButtonProps } from "@fluentui/react-components";

type ASCButtonProps = {
  style?: React.CSSProperties;
  label?: any;
  onItemClick?: () => void;
};
const useStyles = makeStyles({
  root: { color: "red" },
});

const ASCButton = (props: ButtonProps & ASCButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      shape={props.shape ? props.shape : "square"}
      appearance={props.appearance ? props.appearance : undefined}
      className={props.className ? props.className : ""}
      onClick={props.onItemClick ? props.onItemClick : undefined}
      disabled={props.disabled ? props.disabled : undefined}
      size={props.size ? props.size : undefined}
      icon={props.icon ? props.icon : undefined}
      iconPosition={props.iconPosition ? props.iconPosition : undefined}
      style={props.style ? props.style : undefined}
    >
      {props.label ? props.label : ""}
    </Button>
  );
};

export default ASCButton;
