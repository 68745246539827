import React, { useState, useEffect } from "react";
import {
  Button,
  Tab,
  TabList,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { useSelector } from "react-redux";
import "./styles.css";
import { DynamicTree } from "./Tree";
import Example from "./Table";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  formatedDate,
  getAssetCategoryByCaseType,
  getClientType,
} from "../../utils";
import ReactQuillComponent from "../../components/ReactQuillComponent";

enum TabValues {
  Client = "tab1",
  Partner = "tab2",
}

export type TableNameCounts = {
  Client: Record<string, number>;
  Partner: Record<string, number>;
};

export type ResponseItem = {
  tableName: string;
  assignedTo: "Client" | "Partner";
  bank_account_type?: string;
  bank_contribution?: string;
  bank_date_opened?: string;
  bank_frequency?: string;
  case_type: string;
  borrower: string;
  liability_type: string;
  provider: string;
  status: string;
  assured_detail: string;
  assured_name1: string;
  income_type: string;
  owner: string;
  frequency: string;
  source: string;
  sort_order: any;
  client_amount: any;
  partner_amount: any;
  joint_amount: any;
  first_names: string;
  financially_dependant: string;
  known_as: string;
  last_name: string;
  address_type: string;
  organisation: string;
  contact_name: string;
  address_1: string;
  address_2: string;
  record_Id: string;
  address_3: string;
  postcode: string;
  address_id: string;
  //
  policy_holder: any;
  subtype: string;
  other_reference: string;
  gross_amount: any;
  net_amount: any;
  start_date: any;
  end_date: any;
  effective_date: any;
  date_of_birth: string;
  relation: string;
  parent: string;
  //
  bank_sort_code: string;
  joint_indicator: string;
  owner_name2: string;
  owner_name1: string;
  current_value: string;
  value_date: string;
  purpose: string;
  note_text: string;
  report_note: string;
  investment_type: string;
  share_type: string;
  investment_subtype: string;
  share_subtype: string;
  investment_account_number: string;
  bank_account_number: string;
  investment_start_date: string;
  investment_end_date: string;
  investment_status: string;
  bank_status: string;
  share_status: string;
  status_date: string;
  service_status: string;
  investment_provider: string;
  provider_contact_name: string;
  provider_phone: string;
  provider_note: string;
  share_company: string;
  company_contact_name: string;
  company_phone: string;
  company_note: string;
  share_current_number: string;
  share_current_price: string;
  acquired_date: string;
  share_original_value: string;
  home_description: string;
  bank_name: string;
  balance_value: string;
  balance_date: string;
  bank_interest_rate: string;
  bank_account_name: string;
};

const DataSelection = ({
  BackToCP,
  CancelDataSelection,
  NextToSplit,
  checkboxState,
  getPartner,
  setGetPartner,
  ClientPartner,
  selectedTable,
  setSelectedTable,
  clientData,
  setClientData,
  partnerData,
  setPartnerData,
  tableNameCounts,
  setTableNameCounts,
}: {
  BackToCP: () => void;
  CancelDataSelection: () => void;
  NextToSplit: () => void;
  checkboxState: { client: boolean; partner: boolean };
  getPartner: any;
  setGetPartner: React.Dispatch<React.SetStateAction<any>>;
  ClientPartner: any;
  selectedTable: any;
  setSelectedTable: React.Dispatch<React.SetStateAction<string | null>>;
  clientData: ResponseItem[];
  setClientData: React.Dispatch<React.SetStateAction<ResponseItem[]>>;
  partnerData: ResponseItem[];
  setPartnerData: React.Dispatch<React.SetStateAction<ResponseItem[]>>;
  tableNameCounts: TableNameCounts;
  setTableNameCounts: React.Dispatch<React.SetStateAction<TableNameCounts>>;
}) => {
  const [selectedTab, setSelectedTab] = useState<TabValues>(TabValues.Client);
  const dynamicResponseData = useSelector(
    (state: any) => state.splitData?.dynamicResponseData || []
  ) as {
    asset: ResponseItem[];
    liability: ResponseItem[];
    policy: ResponseItem[];
    income: ResponseItem[];
    expense: ResponseItem[];
    dependant: ResponseItem[];
    address: ResponseItem[];
  };
  useEffect(() => {
    if (dynamicResponseData) {
      const clientRecords: ResponseItem[] = [];
      const partnerRecords: ResponseItem[] = [];
      const counts: TableNameCounts = {
        Client: {},
        Partner: {},
      };

      [
        ...dynamicResponseData.asset,
        ...dynamicResponseData.liability,
        ...dynamicResponseData.policy,
        ...dynamicResponseData.income,
        ...dynamicResponseData.expense,
        ...dynamicResponseData.dependant,
        ...dynamicResponseData.address,
      ].forEach((item) => {
        if (item.assignedTo === "Client") {
          clientRecords.push(item);
          counts.Client[item.tableName] =
            (counts.Client[item.tableName] || 0) + 1;
        } else if (item.assignedTo === "Partner") {
          partnerRecords.push(item);
          counts.Partner[item.tableName] =
            (counts.Partner[item.tableName] || 0) + 1;
        }
      });

      setClientData(clientRecords);
      setPartnerData(partnerRecords);
      setTableNameCounts(counts);
      if (selectedTab === TabValues.Client) {
        const firstClientTable = Object.keys(counts.Client)[0];
        if (firstClientTable) {
          setSelectedTable(firstClientTable);
        }
      } else if (selectedTab === TabValues.Partner) {
        const firstPartnerTable = Object.keys(counts.Partner)[0];
        if (firstPartnerTable) {
          setSelectedTable(firstPartnerTable);
        }
      }
    }
  }, [dynamicResponseData]);
  console.log(dynamicResponseData,'dynamic response data')

  const handleTableClick = (tableName: string) => {
    setSelectedTable(tableName);
  };

  const getTableColumns = (
    tableName: string | null
  ): MRT_ColumnDef<ResponseItem>[] => {
    if (tableName === "Asset") {
      return [
        {
          accessorKey: "case_type",
          header: "Asset Category",
          Cell: ({ renderedCellValue, row }: any) => (
            <>{getAssetCategoryByCaseType(row.original.case_type)}</>
          ),
        },
        { accessorKey: "bank_sort_code", header: "Sort Code" },
        {
          accessorKey: "joint_indicator",
          header: "Owner",
          Cell: ({ renderedCellValue, row }: any) => (
            <>{getClientType(row.original.joint_indicator)}</>
          ),
        },
        { accessorKey: "owner", header: "Owner Detail" },
        { accessorKey: "owner_name1", header: "Owner Detail" },
        { accessorKey: "owner_name2", header: "Owner Name2" },
        { accessorKey: "current_value", header: "Current Value" },
        {
          accessorKey: "value_date",
          header: "Value Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        { accessorKey: "purpose", header: "Purpose" },
        {
          accessorKey: "note_text",
          header: "Admin Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.note_text} />
            </>
          ),
        },
        {
          accessorKey: "report_note",
          header: "Report Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.report_note} />
            </>
          ),
        },
        {
          accessorKey: "investment_type" || "share_type",
          header: "Type",
          Cell: ({ row }: any) => (
            <>{row.original.investment_type || row.original.share_type}</>
          ),
        },
        {
          accessorKey: "investment_subtype" || "share_subtype",
          header: "Subtype",
          Cell: ({ row }: any) => (
            <>{row.original.investment_subtype || row.original.share_subtype}</>
          ),
        },
        {
          accessorKey: "investment_account_number" || "bank_account_number",
          header: "Account Number",
          Cell: ({ row }: any) => (
            <>
              {row.original.investment_account_number ||
                row.original.bank_account_number}
            </>
          ),
        },
        {
          accessorKey: "investment_start_date",
          header: "Start Date",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.investment_start_date)}</>
          ),
        },
        {
          accessorKey: "investment_end_date",
          header: "End Date",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.investment_end_date)}</>
          ),
        },
        {
          accessorKey: "investment_status" || "bank_status" || "share_status",
          header: "Status",
          Cell: ({ row }: any) => (
            <>
              {row.original.investment_status ||
                row.original.bank_status ||
                row.original.share_status}
            </>
          ),
        },
        {
          accessorKey: "status_date",
          header: "Status Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
        },
        {
          accessorKey: "service_status",
          header: "Service Status",
          Cell: ({ row }: any) => (
            <>
              {row.original.case_type === "Home/Personal"
                ? ""
                : row.original.service_status}
            </>
          ),
        },
        {
          accessorKey: "investment_provider",
          header: "Provider",
        },
        {
          accessorKey: "provider_contact_name",
          header: "Provider Contact",
        },
        {
          accessorKey: "provider_phone",
          header: "Provider Phone",
        },
        {
          accessorKey: "provider_note",
          header: "Provider Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.provider_note} />
            </>
          ),
        },
        {
          accessorKey: "share_company",
          header: "Company",
        },
        {
          accessorKey: "company_contact_name",
          header: "Company Contact",
          Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
        },
        {
          accessorKey: "company_phone",
          header: "Company Phone",
          Cell: ({ row }: any) => <>{row.original.provider_phone}</>,
        },
        {
          accessorKey: "company_note",
          header: "Company Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.provider_note} />
            </>
          ),
        },
        {
          accessorKey: "share_current_number",
          header: "Quantity",
        },
        {
          accessorKey: "share_current_price",
          header: "Current Price",
        },
        {
          accessorKey: "acquired_date",
          header: "Date Acquired",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        {
          accessorKey: "share_original_value",
          header: "Original Value",
          Cell: ({ row }: any) => (
            <>
              {row.original.case_type === "Home/Personal"
                ? row.original.home_original_value
                : row.original.share_original_value}
            </>
          ),
        },
        {
          accessorKey: "home_description",
          header: "Description",
        },
        {
          accessorKey: "bank_account_type",
          header: "Account Type",
        },
        {
          accessorKey: "bank_name",
          header: "Bank Name",
        },
        {
          accessorKey: "contact_name",
          header: "Contact",
          Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
        },
        {
          accessorKey: "bank_account_name",
          header: "Account Name",
        },
        {
          accessorKey: "balance_value",
          header: "Balance",
          Cell: ({ row }: any) => <>{row.original.current_value}</>,
        },
        {
          accessorKey: "balance_date",
          header: "Balance Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        {
          accessorKey: "bank_interest_rate",
          header: "Interest Rate",
        },
        {
          accessorKey: "bank_date_opened",
          header: "Date Opened",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.bank_date_opened)}</>
          ),
        },
      ];
    } else if (tableName === "Liability") {
      return [
        { accessorKey: "borrower", header: "Borrower" },
        { accessorKey: "liability_type", header: "Liability Type" },
        { accessorKey: "provider", header: "Provider" },
        { accessorKey: "status", header: "Status" },
      ];
    } else if (tableName === "Policy") {
      return [
        {
          accessorKey: "policy_holder",
          header: "Owner",
          Cell: ({ cell }) => {
            const value = cell.getValue<number>();
            return value === 0 ? "Client" : value === 1 ? "Partner" : "Joint";
          },
        },
        { accessorKey: "subtype", header: "Sub Type" },
        { accessorKey: "provider", header: "Provider" },
        { accessorKey: "other_reference", header: "Reference" },
        { accessorKey: "status", header: "Status" },
      ];
    } else if (tableName === "Income") {
      return [
        {
          accessorKey: "owner",
          header: "Owner",
          Cell: ({ cell }) => {
            const value = cell.getValue<number>();
            return value === 0 ? "Client" : value === 1 ? "Partner" : "Joint";
          },
        },
        { accessorKey: "income_type", header: "Type" },
        { accessorKey: "gross_amount", header: "Gross Amount" },
        { accessorKey: "net_amount", header: "Net Amount" },
        { accessorKey: "frequency", header: "Frequency" },
        { accessorKey: "start_date", header: "Start Date" },
        { accessorKey: "end_date", header: "End Date" },
      ];
    } else if (tableName === "Expense") {
      return [
        { accessorKey: "sort_order", header: "Sort Order" },
        { accessorKey: "client_amount", header: "Client Amount" },
        { accessorKey: "partner_amount", header: "Partner Amount" },
        { accessorKey: "joint_amount", header: "Joint Amount" },
        { accessorKey: "frequency", header: "Frequency" },
      ];
    } else if (tableName === "Dependant") {
      return [
        { accessorKey: "first_names", header: "First Name" },
        { accessorKey: "last_name", header: "Last Name" },
        { accessorKey: "date_of_birth", header: "Date of Birth" },
        { accessorKey: "relation", header: "Relation" },
        { accessorKey: "parent", header: "Parent" },
      ];
    } else if (tableName === "Address") {
      return [
        { accessorKey: "organisation", header: "Organisation" },
        { accessorKey: "contact_name", header: "Contact Name" },
        { accessorKey: "address_1", header: "Address 1" },
        { accessorKey: "postcode", header: "Postcode" },
        { accessorKey: "effective_date", header: "Effective Date" },
      ];
    }
    return [];
  };
  useEffect(() => {
    if (checkboxState.client) {
      setSelectedTab(TabValues.Client);
    } else if (checkboxState.partner) {
      setSelectedTab(TabValues.Partner);
    }
  }, [checkboxState]);

  return (
    <div>
      <div className="container">
        <div className="sub-heading">
          <div>
            <span className="headings">Allocate Records</span>
          </div>
          <div>
            <span className="description">
              Client-Assyst has allocated active records according to current
              ownership. By default, non-current records are not listed at all
              and joint records are not assigned to either new client. Review
              these assignments here and change them, if necessary.
            </span>
          </div>
          <div>
            <span className="bolds">Current:</span>
          </div>
        </div>

        <div className="sub-container">
          <div className="s-a-section">
            <DynamicTree />
          </div>
          <div className="s-b-section">
            <Example />
          </div>
        </div>

        <div className="sec">
          <div className="line"></div>
          <div>
            <span className="bolds">Will Change To:</span>
          </div>
        </div>

        <TabList
          defaultSelectedValue={
            checkboxState.client
              ? TabValues.Client
              : checkboxState.partner
              ? TabValues.Partner
              : undefined
          }
          onTabSelect={(event, data) => setSelectedTab(data.value as TabValues)}
        >
          {checkboxState.client && (
            <Tab value={TabValues.Client}>
              <div className="tab-sub-title">
                <div>
                  <span>
                    {ClientPartner?.NameAndAddress?.first_names || ""}
                  </span>{" "}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 400 }}>Client</div>
              </div>
            </Tab>
          )}
          {checkboxState.partner && (
            <Tab value={TabValues.Partner}>
              <div className="tab-sub-title">
                <div>
                  <span>{getPartner?.NameAndAddress?.first_names || ""}</span>{" "}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 400 }}>Partner</div>
              </div>
            </Tab>
          )}
        </TabList>

        {selectedTab === TabValues.Client && (
          <div className="sub-container">
            <div className="s-a-section-a">
              <Tree aria-label="Dynamic Tree">
                {Object.entries(tableNameCounts.Client).map(
                  ([tableName, count]) => (
                    <TreeItem key={tableName} itemType="leaf">
                      <TreeItemLayout
                        onClick={() => handleTableClick(tableName)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedTable === tableName
                              ? "#e0f7fa"
                              : "transparent",
                        }}
                      >
                        {tableName} ({count})
                      </TreeItemLayout>
                    </TreeItem>
                  )
                )}
              </Tree>
            </div>
            <div className="s-b-section">
              <div
                style={{
                  marginTop: "-56px",
                }}
              >
                {selectedTable && (
                  <MaterialReactTable
                    columns={getTableColumns(selectedTable)}
                    data={clientData.filter(
                      (item) => item.tableName === selectedTable
                    )}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={true}
                    initialState={{
                      pagination: {
                        pageSize: 5, // Set initial rows per page to 5
                        pageIndex: 0, // Set initial page index to 0 (first page)
                      },
                    }}
                    enableSorting={false}
                    enableGlobalFilter={false}
                    enableColumnOrdering={false}
                    enableColumnResizing={false}
                    enableColumnFilterModes={false}
                    enableExpandAll={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableHiding={false}
                    muiTableBodyCellProps={({ column }) => ({
                      style: {
                        padding: "6px 12px",
                        fontSize: "0.875rem",
                      },
                    })}
                    muiTableProps={{
                      style: {
                        margin: "0",
                        padding: "0",
                        border: "1px solid rgba(81, 81, 81, .5)",
                      },
                    }}
                    muiTableContainerProps={{
                      style: {
                        minHeight: "200px",
                        maxHeight: "400px",
                        overflow: "auto",
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {selectedTab === TabValues.Partner && (
          <div className="sub-container">
            <div className="s-a-section-a">
              <Tree aria-label="Dynamic Tree">
                {Object.entries(tableNameCounts.Partner).map(
                  ([tableName, count]) => (
                    <TreeItem key={tableName} itemType="leaf">
                      <TreeItemLayout
                        onClick={() => handleTableClick(tableName)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedTable === tableName
                              ? "#e0f7fa"
                              : "transparent",
                        }}
                      >
                        {tableName} ({count})
                      </TreeItemLayout>
                    </TreeItem>
                  )
                )}
              </Tree>
            </div>

            <div className="s-b-section">
              <div
                style={{
                  marginTop: "-56px",
                }}
              >
                {selectedTable && (
                  <MaterialReactTable
                    columns={getTableColumns(selectedTable)}
                    data={partnerData.filter(
                      (item) => item.tableName === selectedTable
                    )}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={true}
                    initialState={{
                      pagination: {
                        pageSize: 5, // Set initial rows per page to 5
                        pageIndex: 0, // Set initial page index to 0 (first page)
                      },
                    }}
                    enableSorting={false}
                    enableGlobalFilter={false}
                    enableColumnOrdering={false}
                    enableColumnResizing={false}
                    enableColumnFilterModes={false}
                    enableExpandAll={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableFilters={false}
                    enableHiding={false}
                    muiTableBodyCellProps={({ column }) => ({
                      style: {
                        padding: "6px 12px",
                        fontSize: "0.875rem",
                      },
                    })}
                    muiTableProps={{
                      style: {
                        margin: "0",
                        padding: "0",
                        border: "1px solid rgba(81, 81, 81, .5)",
                      },
                    }}
                    muiTableContainerProps={{
                      style: {
                        minHeight: "200px",
                        maxHeight: "400px",
                        overflow: "auto",
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <div className="buttons">
        <Button size="small" onClick={BackToCP}>
          Back
        </Button>
        <Button size="small" appearance="primary" onClick={NextToSplit}>
          Next
        </Button>
        <Button size="small" onClick={CancelDataSelection}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DataSelection;
