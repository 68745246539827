import { useParams, Outlet, useNavigate,useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  shorthands,
  tokens,
  TabList,
  Tab,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Subtitle2,
  Caption1,
  Caption1Strong,
  Link,
} from "@fluentui/react-components";
import { ArrowLeft20Regular } from "@fluentui/react-icons";
import ClientPageSidebar from "./ClientPageSidebar";
import apiServices from "../../service";
import OverlayLoader from "../../components/OverlayLoader";
import { removeEmptyValues } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { formBuilder } from "../../utils/formGenerator";
import FormContainer from "../../components/Form/FormContainer";
import { FormLoader } from "../../components/Loader/FormLoader";
type ConfigFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};

const ClientAddForm = () => {
  const { clientId } = useParams(); // Get clientId from the URL
  const navigate = useNavigate();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [clientInfo, setClientInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [configForm, setConfigForm] = useState<ConfigFm | null>(null);
  const [formContext, setFormContext] = useState("CLIENT_NAME_ADDRESS");
  const [initialData, setInitialData] = useState({});
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  useEffect(() => {
    if (formContext && formSettingsObj?.data?.formConfig) {
      const configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      console.log("formContext===", formContext);
      console.log("configObj===", configObj);
      if (configObj) {
        setConfigForm(configObj);
        const { initialValues, inputs, validationSchema } = formBuilder({
          context: formContext,
          formSettings: formSettingsObj.data,
        });
        setInitialData({ initialValues, inputs, validationSchema });
      }
      setPageLoading(false);
    }
  }, [formContext, formSettingsObj]);
  const changeMode = (action: string) => {
    alert("change mode" + action);
  };
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const renderFormContent = () => {
    if (!formContext || formContext !== configForm?.context) return null;

    return (
      <>
        <FormContainer
          context={formContext}
          mode={"add"}
          initialData={initialData}
          notify={notify}
          changeMode={changeMode}
          config={configForm}
        />
      </>
    );
  };
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <OverlayLoader isLoading={pageLoading} />
      <aside style={{ width: "250px", padding: "1rem" }}>
        <ClientPageSidebar/>
      </aside>
      <main style={{ flex: 1, padding: "1rem", overflowY: "auto" }}>
        <div>
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              gap: "10px",
            }}
          >
            <h2>Add Client</h2>
          </div>
          <div>{renderFormContent()}</div>
        </div>

        <div></div>
      </main>
    </div>
  );
};

export default ClientAddForm;
