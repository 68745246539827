import { makeStyles, tokens, Tooltip } from "@fluentui/react-components";
import { DrawerProps } from "@fluentui/react-components";
import {
  Hamburger,
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavItem,
  NavSectionHeader,
  NavSubItem,
  NavSubItemGroup,
} from "@fluentui/react-nav-preview";
import React, { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
type DrawerType = Required<DrawerProps>["type"];

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "calc(100vh - 50px)",
    width: "200px",
  },
  content: {
    flex: "1",
    padding: "16px",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "8px",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
});

const CommissionSidebar = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [enabledLinks, setEnabledLinks] = useState(true);
  const [type, setType] = useState<DrawerType>("inline");
  const [isMultiple, setIsMultiple] = useState(true);

  return (
    <div className={styles.root}>
      <NavDrawer open={isOpen} type={type}>
        <NavDrawerBody>
          {/* <NavCategory value="1">
            <NavCategoryItem value="2">Case Related</NavCategoryItem>
            <NavSubItemGroup>
              <NavSubItem value="3">Summary</NavSubItem>
              <NavCategory value="4">
                <NavCategoryItem value="4.1">Client</NavCategoryItem>
                <NavSubItemGroup>
                  <NavSubItem value="4.2">Name and Address</NavSubItem>
                  <NavSubItem value="4.3">Service Type</NavSubItem>
                </NavSubItemGroup>
              </NavCategory>
              <NavCategory value="5">
                <NavCategoryItem value="5.1">Partner</NavCategoryItem>
                <NavSubItemGroup>
                  <NavSubItem value="5.2">Name and Address</NavSubItem>
                  <NavSubItem value="5.3">Service Type</NavSubItem>
                </NavSubItemGroup>
              </NavCategory>
              <NavItem value="6">Asset</NavItem>
              <NavItem value="7">Liability</NavItem>
              <NavItem value="8">Policies</NavItem>
            </NavSubItemGroup>
          </NavCategory> */}
          <NavItem
            onClick={() => {
              navigate("/commission/receipting");
            }}
            value="receipting"
          >
            Receipting
          </NavItem>
          <NavItem value="1">Reports</NavItem>
          <NavItem
            onClick={() => {
              navigate("/commission/other-fees-charges");
            }}
            value="fees-charges" // Corrected value
          >
            Other Fees/charges
          </NavItem>

          <NavItem
            onClick={() => {
              navigate("/commission/distribution");
            }}
            value="distribution"
          >
            Distribute Commission
          </NavItem>
        </NavDrawerBody>
      </NavDrawer>
    </div>
  );
};

export default CommissionSidebar;
