import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  makeStyles,
  Menu,
  MenuButton,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import MainPage from "./MainPage";
import apiServices from "../../../service";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterSelectionData,
  startLoading,
  stopLoading,
} from "../../../redux/clientSlice";
import OverlayLoader from "../../../components/OverlayLoader";

enum TabValues {
  ExportByCondition = "tab1",
  ExportBySelection = "tab2",
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "160px",
    overflowX: "auto",
    gap: "3px",
    padding: "5px",
  },
  fullWidthItem: {
    width: "100%",
    justifyContent: "flex-start",
    borderRadius: "3px",
    height: "25px",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0f7fa",
    },
  },
  selectedItem: {
    backgroundColor: "#e0f7fa", // Light blue for selection
    fontWeight: "bold", // Optional for emphasis
  },
});

const MainTab = (props: any) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectionOfLists, setSelectionOfLists] = useState<any>("");
  const [getClientId, setClientId] = useState<string>("");
  const styles = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const filterSelectionData = useSelector(
    (state: RootState) => state.selectionFilter.storedValue
  );
  const listsOfSelection = useSelector(
    (state: RootState) => state.list.storedValue
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const extractGetList = () => {
    setLoading(true);
    apiServices.extract
      .getExtract()
      .then((res) => {
        const filteredResponse = res.data?.data.filter(
          (item: any) => item.Type === "Selection"
        );

        if (filteredResponse.length === 0) {
        } else {
          setSelectionOfLists(filteredResponse);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.selectedTab === TabValues.ExportBySelection) {
      extractGetList();
    }
  }, [props.selectedTab]);

  const handleItemClick = (searchId: string) => {
    const selectedItem: any = listsOfSelection.find(
      (item: any) => item.SearchId === searchId
    );
    props.setSearchIds(searchId);
    props.setTypeOfExtract(selectedItem.Type);
    if (selectedItem) {
      try {
        const condition = JSON.parse(selectedItem.Condition);

        const customerIds = condition || [""];
        setClientId(customerIds);

        setSelectedItem(selectedItem);
      } catch (error) {
        console.error("Error parsing Condition field:", error);
      }
    }
  };

  const handleAddNewSelection = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setSelectedItem(null);
    setMenuOpen(false);
    props.setTabOpen(false);
    props.setSelectedSearch("");
    props.setSelectedSearches([]);
    props.setSelectedTab(TabValues.ExportByCondition);
  };

  const handleConditionApply = () => {
    const searchId = props.searchId;
    if (searchId) {
      props.handleSearchId(searchId);
    } else {
    }
    props.setApplyFilter(true);
    setMenuOpen(false);
    setSelectedItem(null);
    props.setTabOpen(false);
    props.setSelectedTab(TabValues.ExportByCondition);
  };

  const transformClientData = (item: any) => {
    return {
      customer_id: item.CustomerId,
    first_names: item.FirstNames,
    last_name: item.LastName,
    title: item.Title,
    email: item.Email || "",
    initials: item.Initials,
    former_last_name: item.FormerLastName,
    honours: item.Honours,
    known_as: item.KnownAs,
    address_1: item.Address1,
    address_2: item.Address2,
    address_3: item.Address3,
    address_name: item.AddressName,
    salutation: item.Salutation,
    joint_salutation: item.JointSalutation,
    joint_address_label: item.JointAddressLabel,
    town: item.Town,
    country: item.Country,
    county: item.County,
    postcode: item.Postcode || null,
    associated_type: item.AssociationType,
    associated_with: item.AssociatedWith || null,
    created_by: item.CreatedBy,
    modified_by: item.ModifiedBy || null,
    mobile: item.Mobile || "",
    date_of_birth: item.DateOfBirth || null,
    client_type: item.ClientType,
    sex: item.Sex,
    place_of_birth: item.PlaceOfBirth || "",
    nationality: item.Nationality || "",
    country_of_residence: item.CountryOfResidence || "",
    country_of_domicile: item.CountryOfDomicile || "",
    height: item.Height || "",
    weight: item.Weight || "",
    somker: item.Smoker || "", // Keep typo to match the requested format
    deceased: item.Deceased || "",
    preferred_retirement_age: item.PreferredRetirementAge || "",
    marital_status: item.MaritalStatus || "",
    };
  };
  

  const handleSelectionApply = () => {
    const data = getClientId;

    // setLoading(true);
    props.setTabOpen(false);
    dispatch(startLoading());
    apiServices.exportExcel
      .clientList(data)
      .then((response) => {
        if (response.data && !response.data.isError) {
          const transformedData = response.data.data.map(transformClientData);
          dispatch(setFilterSelectionData(transformedData));
        } else {
          console.error("API Response Error:", response.data);
        }
      })
      .catch((e) => {
        console.error("API Error:", e);
      })
      .finally(() => {
        // setLoading(false);
        dispatch(stopLoading());
        setSelectedItem(null);
        props.setSelectedTab(TabValues.ExportByCondition);
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

    if (props.selectedTab === TabValues.ExportBySelection) {
      setSelectedItem(null);
    }
  };
  useEffect(() => {
    props.getExtracts();
  }, []);
  return (
    <div>
      <Dialog
        open={props.getDialogState}
        onOpenChange={(_, data) => {
          if (!data.open) {
            props.closeMainTabDialog(); // Close dialog when requested
          }
        }}
      >
        {/* <Dialog open={props.tabOpen} onOpenChange={props.getExtracts}> */}

        {/* <DialogTrigger disableButtonEnhancement>
          <Button
            icon={<ChevronDownRegular />}
            iconPosition="after"
            style={{
              fontWeight: 400,
              color: "#606060",
              border: "1px solid grey",
            }}
            onClick={() => setTabOpen(true)}
          >
            Saved Searches
          </Button>
        </DialogTrigger> */}
        <DialogSurface>
          <OverlayLoader isLoading={isLoading} />

          <DialogBody>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>
                  <span style={{ fontSize: "16px", padding: "10px" }}>
                    <b>Extract Type</b>
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "14px", padding: "10px" }}>
                    Choose which type of extract you want to create
                  </span>
                </div>
              </div>

              <br />
              <div className={styles.root}>
                <TabList
                  defaultSelectedValue={TabValues.ExportByCondition}
                  onTabSelect={(event, data) => {
                    props.setSelectedTab(data.value as TabValues);
                    setSelectedItem(null); // Reset item on tab change
                    props.setSelectedSearches([]);
                    setClientId("");
                    props.setSearchIds("");
                  }}
                  {...props}
                >
                  <Tab value={TabValues.ExportByCondition}>
                    Extract by Condition
                  </Tab>
                  <Tab value={TabValues.ExportBySelection}>
                    Extract by Selection
                  </Tab>
                </TabList>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "stretch",
                  padding: "3px",
                  minHeight: "160px",
                }}
              >
                {props.selectedTab === TabValues.ExportByCondition && (
                  <div className={styles.fullWidthContainer}>
                    <span
                      style={{
                        minHeight: "10px",
                        cursor: "pointer",
                        paddingInline: "3px",
                      }}
                    >
                      {props.renderedMenuItems ? props.renderedMenuItems : ""}
                    </span>
                  </div>
                )}

                {props.selectedTab === TabValues.ExportBySelection && (
                  <div className={styles.fullWidthContainer}>
                    {listsOfSelection && listsOfSelection.length > 0
                      ? listsOfSelection.map((item: any) => (
                          <div
                            style={{ minHeight: "30px" }}
                            key={item.SearchId}
                            className={`${styles.fullWidthItem} ${
                              props.searchIds === item.SearchId
                                ? styles.selectedItem
                                : ""
                            }`}
                            onClick={() => handleItemClick(item.SearchId)}
                          >
                            <span
                              style={{
                                paddingInline: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {item.Title}
                            </span>
                          </div>
                        ))
                      : ""}
                  </div>
                )}
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <div>
                  {props.selectedTab === TabValues.ExportByCondition ? (
                    <Button
                      appearance="primary"
                      size="small"
                      onClick={props.handleAddSearchClick}
                    >
                      Add New Condition
                    </Button>
                  ) : (
                    <Button
                      appearance="primary"
                      size="small"
                      onClick={handleAddNewSelection}
                    >
                      Add New Selection
                    </Button>
                  )}
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Button size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  {props.selectedTab === TabValues.ExportByCondition ? (
                    <Button
                      size="small"
                      disabled={!props.selectedSearches}
                      onClick={() => {
                        {
                          !props.selectedItem
                            ? props.handleAddDialogBox()
                            : handleConditionApply();
                        }
                      }}
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      disabled={!selectedItem}
                      onClick={() => {
                        handleSelectionApply();
                      }}
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <MainPage isOpen={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
};

export default MainTab;
