import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_PaginationState,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Badge,
} from "@fluentui/react-components";
import ASCButton from "../../components/Button";
import {
  checkUserPermission,
  formatAmount,
  formatedDate,
  formatedTime,
  getAmountType,
  getClientNoteCategories,
  getClientType,
  getCommissionCategory,
  getUserDefinedType,
  getYes_NoConversion,
} from "../../utils";
import ReactQuillComponent from "../../components/ReactQuillComponent";
import { UserPermissionsList } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getProvidersList } from "../../redux/providersSlice";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

type ComponentProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};

const tableColumnList = {
  SYSTEM_MANAGER_USERLIST: [
    {
      accessorKey: "full_name",
      header: "Full Name",
    },
    {
      accessorKey: "short_name",
      header: "Short Name",
    },
    {
      accessorKey: "user_email",
      header: "Email",
    },
    {
      accessorKey: "active",
      header: "Active",
      Cell: ({ row }: any) => <>{getYes_NoConversion(row.original.active)}</>,
    },
    // {
    //   accessorKey: "role",
    //   header: "Role",
    // },
    // {
    //   accessorKey: "permissions",
    //   header: "Permissions",
    //   Cell: ({ row }: any) => <>{row.original.permissions.join(",")}</>,
    // },
  ],
  MASTER_SETTINGS_INCOME_CATEGORY: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "auto_add",
      header: "Auto Add",
      Cell: ({ row }: any) => <>{row.original.auto_add === 0 ? "No" : "Yes"}</>,
    },
  ],
  MASTER_SETTINGS_EXPENSE_CATEGORY: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "discretionary",
      header: "Type",
    },
    {
      accessorKey: "auto_add",
      header: "Auto Add",
      Cell: ({ row }: any) => <>{row.original.auto_add === 0 ? "No" : "Yes"}</>,
    },
  ],
  MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "category",
      header: "Category",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "auto_add",
      header: "Auto Add",
      Cell: ({ row }: any) => <>{row.original.auto_add === 0 ? "No" : "Yes"}</>,
    },
  ],
  MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "rating",
      header: "Rating",
    },
    {
      accessorKey: "rating_score",
      header: "Score",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
  ],
  MASTER_SETTINGS_OBJECTIVES: [
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },

    {
      accessorKey: "detail",
      header: "Detail",
    },
    {
      accessorKey: "auto_add",
      header: "Auto Add",
      Cell: ({ row }: any) => <>{row.original.auto_add === 0 ? "No" : "Yes"}</>,
    },
  ],
  MASTER_SETTINGS_USER_DEFINED: [
    {
      accessorKey: "name",
      header: "Name",
    },

    {
      accessorKey: "type",
      header: "Type",
      Cell: ({ row }: any) => <>{getUserDefinedType(row.original.type)}</>,
    },
    {
      accessorKey: "length",
      header: "Length",
    },
  ],
  MASTER_SETTINGS_PROVIDERS: [
    {
      accessorKey: "group_name",
      header: "Group Name",
    },

    {
      accessorKey: "provider_name",
      header: "Provider Name",
    },
    {
      accessorKey: "contact_name",
      header: "Contact",
    },
    {
      accessorKey: "address1",
      header: "Address 1",
    },
    {
      accessorKey: "address2",
      header: "Address 2",
    },
    {
      accessorKey: "address3",
      header: "Address 3",
    },
    {
      accessorKey: "town",
      header: "Town",
    },
    {
      accessorKey: "county",
      header: "County",
    },
    {
      accessorKey: "country",
      header: "Country",
    },
    {
      accessorKey: "postcode",
      header: "Postcode",
    },

    {
      accessorKey: "salutation",
      header: "Salutation",
    },
    {
      accessorKey: "telephone",
      header: "Telephone",
    },
    {
      accessorKey: "fax",
      header: "Fax",
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
    },
    {
      accessorKey: "email",
      header: "Email",
    },

    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  MASTER_SETTINGS_CASE_ACTIONS: [
    {
      accessorKey: "case_type",
      header: "Action Group",
    },
    {
      accessorKey: "due_interval",
      header: "Due Interval(Days)",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "assigned",
      header: "Assigned",
    },
  ],
  MASTER_SETTINGS_CLIENT_ACTIONS: [
    {
      accessorKey: "case_type",
      header: "Action Group",
    },
    {
      accessorKey: "due_interval",
      header: "Due Interval(Days)",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "assigned",
      header: "Assigned",
    },
  ],
  COMMISSION_TYPES: [
    {
      accessorKey: "category",
      header: "Category",
      Cell: ({ row }: any) => (
        <>{getCommissionCategory(row.original.category)}</>
      ),
    },
    {
      accessorKey: "sort_order",
      header: "Sort Order",
    },
    {
      accessorKey: "commission_type",
      header: "Commission Type",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
  ],
  COMMISSION_RULES: [
    {
      accessorKey: "party_type",
      header: "Party Type",
    },
    {
      accessorKey: "case_type",
      header: "Case Type",
    },
    {
      accessorKey: "party_name",
      header: "Party Name",
    },
    {
      accessorKey: "amount",
      header: "Amount",
      Cell: ({ row }: any) => {
        const selectedRowItem = row.original;
        return (
          <>
            {formatAmount(selectedRowItem.amount, selectedRowItem.amount_flag)}
          </>
        );
      },
    },
    {
      accessorKey: "amount_flag",
      header: "Amount Type",
      Cell: ({ row }: any) => <>{getAmountType(row.original.amount_flag)}</>,
    },
    {
      accessorKey: "note_text",
      header: "Note",
    },
  ],
};

const SystemManagerTableDataList = (props: ComponentProps) => {
  const styles = useStyles();
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const providersList = useSelector((state: RootState) => state.providers);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const [globalFilter, setGlobalFilter] = useState("");
  const customColumns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(() => {
    if (props.context !== "") {
      return (tableColumnList as { [key: string]: any })[props.context];
    }

    return [];
  }, [props.context]);

  React.useEffect(() => {
    if (
      props.handleRowAction !== undefined &&
      props.data.length > 0 &&
      Object.keys(rowSelection)[0] !== undefined
    ) {
      let index = 0;

      if (props.context === "SYSTEM_MANAGER_USERLIST") {
        index = props.data.findIndex(
          (x: any) => x.user_id === Object.keys(rowSelection)[0]
        );
      } else {
        index = props.data.findIndex(
          (x: any) => x.id === Object.keys(rowSelection)[0]
        );
      }
      let selectedRow = props.data[index];
      if (selectedRow) {
        props.handleRowAction("view", selectedRow);
      }

      // props.handleRowSelection(Object.keys(rowSelection)[0]);
    }
  }, [rowSelection]);
  React.useEffect(() => {
    if (
      props.selectedRowItem &&
      Object.keys(props.selectedRowItem).length !== 0 &&
      props.data.length > 0
    ) {
      let index = 0;

      switch (props.context) {
        case "MASTER_SETTINGS_INCOME_CATEGORY":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_EXPENSE_CATEGORY":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_ATTITUDE_TO_RISK_CATEGORY":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_ATTITUDE_TO_RISK_RATING":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_OBJECTIVES":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_USER_DEFINED":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_PROVIDERS":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "MASTER_SETTINGS_CASE_ACTIONS":
          index = props.data.findIndex(
            (x: any) => x.id === props.selectedRowItem.id
          );
          break;
        case "SYSTEM_MANAGER_USERLIST":
          index = props.data.findIndex(
            (x: any) => x.user_id === props.selectedRowItem.user_id
          );
          break;
        case "MASTER_SETTINGS_CLIENT_ACTIONS":
          index = props.data.findIndex(
            (x: any) => x.user_id === props.selectedRowItem.user_id
          );
          break;
        case "COMMISSION_TYPES":
          index = props.data.findIndex(
            (x: any) => x.user_id === props.selectedRowItem.user_id
          );
          break;
        case "COMMISSION_RULES":
          index = props.data.findIndex(
            (x: any) => x.user_id === props.selectedRowItem.user_id
          );
          break;
        default:
          break;
      }
      if (props.context === "SYSTEM_MANAGER_USERLIST") {
        setRowSelection({ [props.selectedRowItem.user_id]: true });
      } else {
        setRowSelection({ [props.selectedRowItem.id]: true });
      }

      props.setCurrentRecord(index + 1);
    }
  }, [props.selectedRowItem]);
  useEffect(() => {
    // setProviderCategory(providersList.list);
    setRowCount(providersList.count);
  }, [providersList]);

  useEffect(() => {
    dispatch(
      getProvidersList({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        search: globalFilter,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);
  return (
    <div>
      {customColumns && customColumns.length > 0 && (
        <MaterialReactTable
          columns={customColumns}
          data={props.data}
          enableRowSelection
          enableColumnActions={false}
          onRowSelectionChange={setRowSelection}
          enableBottomToolbar={true}
          rowNumberMode="static"
          enableMultiRowSelection={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: "pointer" },
          })}
          getRowId={(row) => row.id}
          enableRowActions={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              // size: 300, //make actions column wider
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_USER
              ) && (
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Eye16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("view", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              )}
              <Tooltip content="Edit" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Edit16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("edit", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
            </div>
          )}
          initialState={{
            columnPinning: { right: ["mrt-row-actions"] },
          }}
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
          state={{
            rowSelection,
            ...(props.context === "MASTER_SETTINGS_PROVIDERS"
              ? { pagination }
              : {}),
          }}
          {...(props.context === "MASTER_SETTINGS_PROVIDERS" && {
            manualPagination: true,
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            rowCount: rowCount,
          })}
        />
      )}
    </div>
  );
};

export default SystemManagerTableDataList;
