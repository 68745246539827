import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  checkUserPermission,
  formatedDate,
  getAssetCategoryByCaseType,
  getAssetFundCodeSource,
  getAssetTypeByCaseType,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { UserPermissionsList } from "../../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});
const tableColumnList = {
  assets: [
    {
      accessorKey: "case_type",
      header: "Asset Category",
      Cell: ({ renderedCellValue, row }: any) => (
        <>{getAssetCategoryByCaseType(row.original.case_type)}</>
      ),
    },
    {
      accessorKey: "bank_sort_code",
      header: "Sort Code",
    },
    {
      accessorKey: "joint_indicator",
      header: "Owner",
      Cell: ({ renderedCellValue, row }: any) => (
        <>{getClientType(row.original.joint_indicator)}</>
      ),
    },
    {
      accessorKey: "owner",
      header: "Owner Detail",
    },
    {
      accessorKey: "owner_name1",
      header: "Owner Name1",
    },
    {
      accessorKey: "owner_name2",
      header: "Owner Name2",
    },
    {
      accessorKey: "current_value",
      header: "Current Value",
    },
    {
      accessorKey: "value_date",
      header: "Value Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
    },
    {
      accessorKey: "purpose",
      header: "Purpose",
    },
    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "report_note",
      header: "Report Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.report_note} />
        </>
      ),
    },
    {
      accessorKey: "investment_type" || "share_type",
      header: "Type",
      Cell: ({ row }: any) => (
        <>{row.original.investment_type || row.original.share_type}</>
      ),
    },
    {
      accessorKey: "investment_subtype" || "share_subtype",
      header: "Subtype",
      Cell: ({ row }: any) => (
        <>{row.original.investment_subtype || row.original.share_subtype}</>
      ),
    },
    {
      accessorKey: "investment_account_number" || "bank_account_number",
      header: "Account Number",
      Cell: ({ row }: any) => (
        <>
          {row.original.investment_account_number ||
            row.original.bank_account_number}
        </>
      ),
    },
    {
      accessorKey: "investment_start_date",
      header: "Start Date",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.investment_start_date)}</>
      ),
    },
    {
      accessorKey: "investment_end_date",
      header: "End Date",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.investment_end_date)}</>
      ),
    },
    {
      accessorKey: "investment_status" || "bank_status" || "share_status",
      header: "Status",
      Cell: ({ row }: any) => (
        <>
          {row.original.investment_status ||
            row.original.bank_status ||
            row.original.share_status}
        </>
      ),
    },
    {
      accessorKey: "status_date",
      header: "Status Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
    },
    {
      accessorKey: "service_status",
      header: "Service Status",
      Cell: ({ row }: any) => (
        <>
          {row.original.case_type === "Home/Personal"
            ? ""
            : row.original.service_status}
        </>
      ),
    },
    {
      accessorKey: "investment_provider",
      header: "Provider",
    },
    {
      accessorKey: "provider_contact_name",
      header: "Provider Contact",
    },
    {
      accessorKey: "provider_phone",
      header: "Provider Phone",
    },
    {
      accessorKey: "provider_note",
      header: "Provider Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.provider_note} />
        </>
      ),
    },
    {
      accessorKey: "share_company",
      header: "Company",
    },
    {
      accessorKey: "company_contact_name",
      header: "Company Contact",
      Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
    },
    {
      accessorKey: "company_phone",
      header: "Company Phone",
      Cell: ({ row }: any) => <>{row.original.provider_phone}</>,
    },
    {
      accessorKey: "company_note",
      header: "Company Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.provider_note} />
        </>
      ),
    },
    {
      accessorKey: "share_current_number",
      header: "Quantity",
    },
    {
      accessorKey: "share_current_price",
      header: "Current Price",
    },
    {
      accessorKey: "acquired_date",
      header: "Date Acquired",
      Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
    },
    {
      accessorKey: "share_original_value",
      header: "Original Value",
      Cell: ({ row }: any) => (
        <>
          {row.original.case_type === "Home/Personal"
            ? row.original.home_original_value
            : row.original.share_original_value}
        </>
      ),
    },

    {
      accessorKey: "home_description",
      header: "Description",
    },
    {
      accessorKey: "bank_account_type",
      header: "Account Type",
    },
    {
      accessorKey: "bank_name",
      header: "Bank Name",
    },
    {
      accessorKey: "contact_name",
      header: "Contact",
      Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
    },
    // {
    //   accessorKey: "provider_phone",
    //   header: "Phone",
    // },
    // {
    //   accessorKey: "provider_note",
    //   header: "Note",
    //   Cell: ({ row }: any) => (
    //     <><ReactQuillComponent value={row.original.provider_note} /></>
    //     ),
    // },
    {
      accessorKey: "bank_account_name",
      header: "Account Name",
    },
    {
      accessorKey: "balance_value",
      header: "Balance",
      Cell: ({ row }: any) => <>{row.original.current_value}</>,
    },
    {
      accessorKey: "balance_date",
      header: "Balance Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
    },
    {
      accessorKey: "bank_interest_rate",
      header: "Interest Rate",
    },
    {
      accessorKey: "bank_date_opened",
      header: "Date Opened",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.bank_date_opened)}</>
      ),
    },
  ],
  funds: [
    {
      accessorKey: "fund_code",
      header: "Fund Code",
    },
    {
      accessorKey: "fund_code_source",
      header: "Fund Source Code",
      Cell: ({ row }: any) => (
        <>{getAssetFundCodeSource(row.original.fund_code_source)}</>
      ),
    },
    {
      accessorKey: "fund_name",
      header: "Fund Name",
    },
    {
      accessorKey: "fund_units",
      header: "Units",
    },
    {
      accessorKey: "unit_price",
      header: "Price",
    },
    {
      accessorKey: "currency_code",
      header: "Currency",
    },
    {
      accessorKey: "price_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.price_date)}</>,
    },

    {
      accessorKey: "fund_value",
      header: "Fund Value",
    },
    {
      accessorKey: "sector",
      header: "Sector",
    },
    {
      accessorKey: "total_expense_ratio",
      header: "Total Expence Ratio",
    },
    {
      accessorKey: "risk_rating",
      header: "Risk Rating",
    },
  ],
  payments: [
    {
      accessorKey: "payment.start_date",
      header: "Start Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.start_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.end_date",
      header: "End Date",
      Cell: ({ row }: any) => (
        <>
          {formatedDate(
            row.original.payment ? row.original.payment.end_date : null
          )}
        </>
      ),
    },
    {
      accessorKey: "payment.amount",
      header: "Amount",
    },
    {
      accessorKey: "payment.frequency",
      header: "Frequency",
    },
    {
      accessorKey: "payment.new_business_register",
      header: "Is This New Business",
    },
    {
      accessorKey: "payment.reinvestment",
      header: "Reinvestment",
    },
    {
      accessorKey: "payment.note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.payment?.note_text} />
        </>
      ),
    },
    {
      accessorKey: "payment.payment_type",
      header: "Payment Type",
    },
  ],
  withdrawals: [
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "amount",
      header: "Amount",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "high_risk",
      header: "HighRisk",
    },
    {
      accessorKey: "high_risk_note",
      header: "High Risk Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.high_risk_note} />
        </>
      ),
    },
    {
      accessorKey: "reinvestment",
      header: "Reinvestment",
    },
    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  valuations: [
    {
      accessorKey: "effective_date",
      header: "Effective Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.effective_date)}</>,
    },
    {
      accessorKey: "valuation_amount",
      header: "Valuation Amount",
    },

    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  actions: [
    {
      accessorKey: "business_date",
      header: "Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.business_date)}</>,
    },

    {
      accessorKey: "business_action",
      header: "Action",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.business_action} />
        </>
      ),
    },
    {
      accessorKey: "business_assigned",
      header: "Assigned",
    },
    {
      accessorKey: "business_status",
      header: "Status",
    },
  ],
  commission: [
    {
      accessorKey: "commission_type",
      header: "Commission Type",
    },
    {
      accessorKey: "payment_source",
      header: "Source of Payment",
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
    },
    {
      accessorKey: "amount_to_company",
      header: "Amount to Company",
    },
    {
      accessorKey: "start_date",
      header: "Commission Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "payment_count",
      header: "Number of Payments",
    },
    {
      accessorKey: "nb_register",
      header: "Show AMT on NB Register",
      Cell: ({ row }: any) => (
        <>{getYes_NoConversion(row.original.nb_register)}</>
      ),
    },
    {
      accessorKey: "note_text",
      header: "Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
  ],
  liabilities: [
    {
      accessorKey: "case_type",
      header: "Liability Category",
    },
    {
      accessorKey: "joint_indicator",
      header: "Owner",
      Cell: ({ renderedCellValue, row }: any) => (
        <>{getClientType(row.original.joint_indicator)}</>
      ),
    },
    {
      accessorKey: "borrower",
      header: "Borrower",
    },
    {
      accessorKey: "borrower_name1", //access nested data with dot notation
      header: "Borrower Name1",
    },
    {
      accessorKey: "borrower_name2",
      header: "Borrower Name2",
    },
    {
      accessorKey: "liability_type",
      header: "Type",
    },
    {
      accessorKey: "subtype",
      header: "Subtype",
    },
    {
      accessorKey: "repayment_method",
      header: "Repayment Method",
    },
    {
      accessorKey: "purpose",
      header: "Purpose",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "term",
      header: "Term",
    },
    {
      accessorKey: "amount_borrowed",
      header: "Amount Borrowed",
    },
    {
      accessorKey: "amount_outstanding",
      header: "Amount Outstanding",
    },
    {
      accessorKey: "amount_outstanding_date",
      header: "Outstanding Date",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.amount_outstanding_date)}</>
      ),
    },
    {
      accessorKey: "interest_rate",
      header: "Interest Rate",
    },
    {
      accessorKey: "interest_basis",
      header: "Interest Basis",
    },

    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "status_date",
      header: "Status Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
    },
    {
      accessorKey: "note_text",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.note_text} />
        </>
      ),
    },
    {
      accessorKey: "report_note",
      header: "Report Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.report_note} />
        </>
      ),
    },
    {
      accessorKey: "account_number",
      header: "Account Number",
    },
    {
      accessorKey: "mortgage_fixed_rate_end",
      header: "End of Fixed Rate",
      Cell: ({ row }: any) => (
        <>{formatedDate(row.original.mortgage_fixed_rate_end)}</>
      ),
    },
    {
      accessorKey: "property_value",
      header: "Property value",
    },
    {
      accessorKey: "property_address1",
      header: "Property Address",
      Cell: ({ row }: any) => (
        <p>
          {row.original.property_address1}
          <br />
          {row.original.property_address2}
          <br />
          {row.original.property_address3}
        </p>
      ),
    },
    {
      accessorKey: "loan_valuation_percentage",
      header: "Loan to valuation %",
    },

    {
      accessorKey: "provider",
      header: "Provider",
    },
    {
      accessorKey: "provider_contact_name",
      header: "Provider Contact",
    },
    {
      accessorKey: "provider_phone",
      header: "Provider Phone",
    },
    {
      accessorKey: "provider_address1",
      header: "Provider Address",
      Cell: ({ row }: any) => (
        <p>
          {row.original.provider_address1}
          <br />
          {row.original.provider_address2}
          <br />
          {row.original.provider_address3}
        </p>
      ),
    },
    {
      accessorKey: "property_town",
      header: "Property Town",
    },
    {
      accessorKey: "property_county",
      header: "Property County",
    },
    {
      accessorKey: "property_country",
      header: "Property Country",
    },
    {
      accessorKey: "provider_town",
      header: "Provider Town",
    },
    {
      accessorKey: "provider_county",
      header: "Provider Country",
    },
    {
      accessorKey: "provider_country",
      header: "Provider Country",
    },
    {
      accessorKey: "property_postcode",
      header: "Property Postcode",
    },
    {
      accessorKey: "provider_postcode",
      header: "Provider Postcode",
    },
    {
      accessorKey: "provider_note",
      header: "Provider Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.provider_note} />
        </>
      ),
    },
    {
      accessorKey: "covered",
      header: "Premium Covered",
      Cell: ({ row }: any) =>
        row.original.case_type !== "Mortgages" ? row.original.covered : "",
    },
    {
      accessorKey: "covered",
      header: "Covered by Insurance",
      Cell: ({ row }: any) =>
        row.original.case_type === "Mortgages" ? row.original.covered : "",
    },
  ],
  policies: [
    {
      accessorKey: "case_type",
      header: "Policy Category",
    },
    {
      accessorKey: "policy_holder",
      header: "Policyholder",
      Cell: ({ renderedCellValue, row }: any) => (
        <>{getClientType(row.original.policy_holder)}</>
      ),
    },
    {
      accessorKey: "assured_detail",
      header: "Assured Detail",
    },
    {
      accessorKey: "assured_name1",
      header: "Assured Name1",
    },
    {
      accessorKey: "assured_name2",
      header: "Assured Name2",
    },
    {
      accessorKey: "subtype",
      header: "Subtype",
    },
    {
      accessorKey: "provider",
      header: "Provider",
    },
    {
      accessorKey: "provider_contact_name",
      header: "Provider Contact",
    },
    {
      accessorKey: "provider_phone",
      header: "Provider Phone",
    },
    {
      accessorKey: "provider_note",
      header: "Provider Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.provider_note} />
        </>
      ),
    },
    {
      accessorKey: "purpose",
      header: "Purpose",
    },
    {
      accessorKey: "start_date",
      header: "Start Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
    },
    {
      accessorKey: "end_date",
      header: "End Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
    },
    {
      accessorKey: "term",
      header: "Term",
    },
    {
      accessorKey: "scheme_name",
      header: "Scheme Name",
    },
    {
      accessorKey: "policy_number",
      header: "Policy Number",
    },
    {
      accessorKey: "other_reference",
      header: "Other Reference",
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "status_date",
      header: "Status Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
    },
    {
      accessorKey: "service_status",
      header: "Service Status",
    },
    {
      accessorKey: "premium_indexed",
      header: "Premium Indexed",
      Cell: ({ row }: any) => <>{row.original.service_status}</>,
    },
    {
      accessorKey: "periodic_benefit",
      header: "Periodic Benefit",
    },
    {
      accessorKey: "benefit_frequency",
      header: "Benefit Frequency",
    },
    {
      accessorKey: "benefits_indexed",
      header: "Benefits Indexed",
    },
    {
      accessorKey: "benefit_text",
      header: "Benefit Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.benefit_text} />
        </>
      ),
    },
    {
      accessorKey: "admin_note",
      header: "Admin Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.admin_note} />
        </>
      ),
    },
    {
      accessorKey: "report_note",
      header: "Report Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.report_note} />
        </>
      ),
    },
    {
      accessorKey: "link_to_mortgage",
      header: "Linked to Mortgage",
      Cell: ({ row }: any) => (
        <>{getYes_NoConversion(row.original.link_to_mortgage)}</>
      ),
    },
    {
      accessorKey: "rated",
      header: "Rate",
    },
    {
      accessorKey: "waiver",
      header: "Waiver",
    },
    {
      accessorKey: "surrender_value",
      header: "Surrender Value",
    },
    {
      accessorKey: "future_value",
      header: "Future Value",
    },
    {
      accessorKey: "age_admitted",
      header: "Age Admitted",
    },

    {
      accessorKey: "in_trust",
      header: "In trust",
    },
    {
      accessorKey: "in_trust_note",
      header: "In Trust Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.in_trust_note} />
        </>
      ),
    },
    {
      accessorKey: "valuation_date",
      header: "Valuation Date",
      Cell: ({ row }: any) => <>{formatedDate(row.original.valuation_date)}</>,
    },
    {
      accessorKey: "assigned_to",
      header: "Assigned To",
    },
    {
      accessorKey: "contracted_out",
      header: "Contracted Out",
    },
    {
      accessorKey: "transfer_value",
      header: "Transfer Value",
    },
    {
      accessorKey: "total_fund_value",
      header: "Total Fund Value",
    },
    {
      accessorKey: "expression_of_wish",
      header: "Expression of Wish",
    },

    {
      accessorKey: "lump_sum_benefit",
      header: "Lump Sum Benefit",
      Cell: ({ row }: any) => (
        <>
          {row.original.case_type !== "Life Assurance"
            ? row.original.lump_sum_benefit
            : null}
        </>
      ),
    },
    {
      accessorKey: "sum_assured",
      header: "Sum Assured",
      Cell: ({ row }: any) => (
        <>
          {row.original.case_type === "Life Assurance"
            ? row.original.lump_sum_benefit
            : null}
        </>
      ),
    },

    {
      accessorKey: "expression_of_wishnote",
      header: "Expression of Wish Note",
      Cell: ({ row }: any) => (
        <>
          <ReactQuillComponent value={row.original.expression_of_wishnote} />
        </>
      ),
    },
  ],
};
const CaseList = (props: any) => {
  console.log("props in case list", props);
  const styles = useStyles();
  const { clientId, caseId, subItemId, subSubItemId } = useParams();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const customColumns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(() => {
    if (props.module !== "") {
      return (tableColumnList as { [key: string]: any })[props.module];
    }
    return [];
  }, [props.module]);

  useEffect(() => {
    if (Object.keys(rowSelection)[0] !== undefined && props.data.length > 0) {
      let selectedRow = props.data[Object.keys(rowSelection)[0]];

      if (selectedRow && Object.keys(selectedRow).length !== 0) {
        if (props.module == "assets") {
          navigate(
            `/clients/${clientId}/${props.section}/${selectedRow.asset_id}/asset/view`
          );
        } else if (props.module == "actions") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.module}/${selectedRow.business_id}/action/view`
          );
        } else if (props.module == "valuations") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.module}/${selectedRow.valuation_id}/valuation/view`
          );
        } else if (props.module == "withdrawals") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.module}/${selectedRow.withdrawal_id}/withdrawal/view`
          );
        } else if (props.module == "payments") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.module}/${selectedRow.payment?.payment_id}/payment/view`
          );
        } else if (props.module == "funds") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.module}/${selectedRow.fund_id}/fund/view`
          );
        } else if (props.module == "commission" && subItemId) {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/payments/${subItemId}/${props.module}/${selectedRow.commission_id}/commission/view`
          );
        } else if (props.module == "liabilities") {
          navigate(
            `/clients/${clientId}/${props.section}/${selectedRow.liability_id}/liability/view`
          );
        } else {
          navigate(
            `/clients/${clientId}/${props.section}/${selectedRow.policy_id}/policy/view`
          );
        }
      }

      // props.handleRowSelection(selectedRow.asset_id, props.mode);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (props.data.length > 0) {
      let index = 0;
      if (
        [
          "actions",
          "valuations",
          "withdrawals",
          "payments",
          "funds",
          "commission",
        ].includes(props.module)
      ) {
        if (subItemId) {
          if (props.module == "actions") {
            index = props.data.findIndex(
              (x: any) => x.business_id === subItemId
            );
          } else if (props.module == "valuations") {
            index = props.data.findIndex(
              (x: any) => x.valuation_id === subItemId
            );
          } else if (props.module == "withdrawals") {
            index = props.data.findIndex(
              (x: any) => x.withdrawal_id === subItemId
            );
          } else if (props.module == "payments") {
            index = props.data.findIndex(
              (x: any) => x.payment?.payment_id === subItemId
            );
          } else if (props.module == "funds") {
            index = props.data.findIndex((x: any) => x.fund_id === subItemId);
          } else if (props.module == "commission" && subSubItemId) {
            index = props.data.findIndex(
              (x: any) => x.commission_id === subSubItemId
            );
          }
        }
      } else {
        if (props.module == "assets") {
          index = props.data.findIndex((x: any) => x.asset_id === caseId);
        } else if (props.module == "liabilities") {
          index = props.data.findIndex((x: any) => x.liability_id === caseId);
        } else {
          index = props.data.findIndex((x: any) => x.policy_id === caseId);
        }
      }

      setRowSelection({ [index]: true });
      // props?.setCurrentRecord(index + 1);
    }
  }, [props.data]);

  return (
    <div>
      {customColumns && customColumns.length > 0 && (
        <MaterialReactTable
          columns={customColumns}
          data={props.data}
          enableRowSelection
          enableColumnActions={false}
          onRowSelectionChange={setRowSelection}
          enablePagination={false}
          enableRowNumbers
          enableBottomToolbar={false}
          rowNumberMode="static"
          enableMultiRowSelection={false}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: { cursor: "pointer" },
          })}
          getRowId={(row) => row.id}
          enableRowActions={false}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions", //change header text
              // size: 300, //make actions column wider
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
            },
          }}
          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_CLIENT
              ) && (
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    icon={
                      <Eye16Filled
                        primaryFill="var(--button-color)"
                        onClick={() => {
                          props.handleRowAction("view", row);
                        }}
                      />
                    }
                  ></Button>
                </Tooltip>
              )}
              <Tooltip content="Edit" relationship="label" positioning="below">
                <Button
                  appearance="subtle"
                  icon={
                    <Edit16Filled
                      primaryFill="var(--button-color)"
                      onClick={() => {
                        props.handleRowAction("edit", row);
                      }}
                    />
                  }
                ></Button>
              </Tooltip>
            </div>
          )}
          initialState={{
            columnPinning: { right: ["mrt-row-actions"] },
          }}
          positionActionsColumn="last"
          positionToolbarAlertBanner="bottom"
          state={{ rowSelection }}
        />
      )}
    </div>
  );
};

export default CaseList;
