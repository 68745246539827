import React, {
  useState,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Dropdown,
  Label,
  Button,
  Input,
  Option,
  useRestoreFocusTarget,
  Toast,
  ToastTitle,
  ToastTrigger,
  Link,
  useId,
  useToastController,
  Tooltip,
  Field,
  Toaster,
  Checkbox,
  Combobox,
} from "@fluentui/react-components";
import { makeStyles, shorthands } from "@fluentui/react-components";
import {
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components/unstable";
import apiServices from "../../../service";
import { Delete16Regular, Edit16Filled } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import MaterialReactTable, {
  MRT_RowSelectionState,
} from "material-react-table";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { formatCondition, formatedDate2, isObjectEmpty } from "../../../utils";
import {
  Condition,
  ConditionType,
  Errors,
  TreeNode,
  ValidationErrors,
  booleanOperators,
  dateOperators,
  filterConditionalTypeData,
  filterTypeData,
  getDatatype,
  stringOperators,
  useStyles,
} from "../../../shared/extractTypes";
import OverlayLoader from "../../../components/OverlayLoader";
import { renderConditionTree, renderTree } from "./treeData";
import type { CheckboxProps } from "@fluentui/react-components";
import { ComboBox } from "@fluentui/react";
import CreatableSelectionList from "../../../components/Creatablelist";
import CreatableSelect from "react-select/creatable";

const tableColumnList = {
  extractList: [
    {
      accessorKey: "Title",
      header: "Title",
    },
    {
      accessorKey: "Description",
      header: "Description",
    },
    {
      accessorKey: "Condition",
      header: "Condition",
      Cell: ({ row }: any) => <>{formatCondition(row.original.Condition)}</>,
    },
  ],
};

type ExtractMainProps = {
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  setTabOpen: Dispatch<SetStateAction<boolean>>;
  searchId: any;
  extract: { [key: string]: any }[]; // Matches the state type in the parent
  setExtract: Dispatch<SetStateAction<{ [key: string]: any }[]>>; // Matches the type of setState
  getExtracts: () => void;
};

export const ExtractMain: React.FC<ExtractMainProps> = ({
  setIsDialogOpen,
  searchId,
  extract,
  setExtract,
  getExtracts,
  setTabOpen,
}) => {
  const comboId = useId("combo-default");
  const [isDataLabel, setDataLabel] = useState<string>("");
  const [isConditionDataLabel, setIsConditionDataLabel] = useState<string>("");
  const [isDrodownExpand, setDropdownExpand] = useState(false);
  const [isDrodownExpanded, setDropdownExpanded] = useState(false);
  const [treeKey, setTreeKey] = useState(0);
  const [treeConditionKey, setTreeConditionKey] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const [extractData, setExtractData] = useState<TreeNode[]>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectConditionOption, SetSelectConditionOption] = useState("");
  const [titleData, setTitleData] = useState<any>("");
  const [descriptionData, setDescriptionData] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [searchID, setSearchID] = useState<string>("");
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const [showDialogPopup, setShowDialogPopup] = useState(false);
  const [isDataType, setIsDataType] = useState<getDatatype[]>([]);
  const [isFilteredDataType, setIsFilteredDataType] = useState<
    filterTypeData[]
  >([]);
  const [isConditionalFilteredDataType, setIsConditionalFilteredDataType] =
    useState<filterConditionalTypeData[]>([]);
  const [tableOne, setTableOne] = useState("");
  const [tableTwo, setTableTwo] = useState("");
  const [name, setName] = useState("");

  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedValue, setSelectedValue] = useState<any>("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {}
  );
  const [operatorName, setOperatorName] = useState("");
  const [selectedConditionOperator, setSelectedConditionOperator] =
    useState("");
  const [selectedConditionValue, setSelectedConditionValue] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [selectedRow, setSelectedRow] = useState<{
    [key: string]: any;
  }>({});
  const [mode, setMode] = useState("");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );
  const [conditionPopulation, setConditionPopulation] = useState<any[]>([]);
  const [clientDetail, setClientDetail] = useState<any[]>([]);
  const [checked, setChecked] = useState(false);
  const { userProfileInfo } = useSelector((state: any) => state.authUser);
  const [colourOptions, setColourOptions] = useState([
    { label: "anc", value: "test" },
    { label: "anc", value: "test" },
  ]);
  const clientpartner = useSelector(
    (state: any) => state.clientpartner.storedValue
  );
  console.log(clientpartner,"clientpartnerddd");
  
  const conditionalClientPartner = useSelector(
    (state: any) => state.conditionalclientpartner.storedValue
  );
  const toggleDropdown = () => {
    setDropdownExpand((prevState) => !prevState);
    setTreeKey((prevKey) => prevKey + 1);
  };

  const toggleConditionDropdown = () => {
    setDropdownExpanded((prevState) => !prevState);
    setTreeConditionKey((prevKey) => prevKey + 1);
  };

  const changeMode = (mode: string, row?: any) => {
    setMode(mode);

    if (mode === "edit" && selectedRow) {
      let buildCondition = JSON.parse(selectedRow.Condition);
      setTitleData(selectedRow.Title);
      setDescriptionData(selectedRow.Description);
      setTableOne(buildCondition?.tableOne?.table);
      setDataLabel(buildCondition?.tableOne?.label);
      setSelectedOperator(buildCondition?.tableOne?.condition);
      setSelectedValue(buildCondition?.tableOne?.fieldValue);
      setTableTwo(buildCondition?.tableTwo?.table);
      setIsConditionDataLabel(buildCondition?.tableTwo?.label || "");
      setSelectedConditionOperator(buildCondition?.tableTwo?.condition || "");
      setSelectedConditionValue(buildCondition?.tableTwo?.fieldValue || "");
      setSelectedCondition(buildCondition?.condition || "");

      if (buildCondition?.tableTwo?.label != "") {
        setShowDialogPopup(true);
        setIsButtonDisabled(true);
      } else {
        setShowDialogPopup(false);
      }
    }
  };

  useEffect(() => {
    if (extract.length > 0) {
      selectedIndex(0);
    }
  }, [extract]);

  const selectedIndex = (index: number) => {
    if (extract.length > 0) {
      setMode("view");
      setSelectedRow(extract[index]);
    }
  };
  useEffect(() => {
    if (!isObjectEmpty(selectedRow)) {
      const index = extract.findIndex(
        (item) => item.SearchId === selectedRow.SearchId
      );
      setRowSelection({ [index]: true });
    }
  }, [selectedRow]);
  const notify = (message: any, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {message}
        </ToastTitle>
      </Toast>,
      { intent: type ? type : "success" }
    );
  const getOperators = () => {
    switch (types.toString()) {
      case "number":
        return booleanOperators;
      case "string":
        return stringOperators;
      case "timestamp":
        return dateOperators;
      default:
        return [];
    }
  };
  const conditionDataType = isConditionalFilteredDataType;
  const getConditionOperators = () => {
    switch (conditionDataType.toString()) {
      case "number":
        return booleanOperators;
      case "string":
        return stringOperators;
      case "timestamp":
        return dateOperators;
      default:
        return [];
    }
  };

  const [isCondition, setIsCondition] = useState<ConditionType[]>(Condition);

  const handleTitleChange = (
    fieldName: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTitleData(value);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      titleData: "",
    }));
  };
  const handleDescriptionChange = (e: any, value: any) => {
    setDescriptionData(value.value);
  };
  const handleAddCondition = () => {
    setShowDialogPopup(true);
    setIsButtonDisabled(true);
  };
  const handleDeleteCondition = () => {
    setShowDialogPopup(false);
    setIsButtonDisabled(false);
    setIsDeleted(false);
    SetSelectConditionOption("");
    setSelectedConditionOperator("");
    setSelectedConditionValue("");
    setSelectedCondition("");
  };
  const handleClose = () => {
    setShowDialogPopup(false);
    setIsButtonDisabled(false);
    setTitleData("");
    setDescriptionData("");
    setSelectedOption("");
    setSelectedOperator("");
    setSelectedValue("");
    setDataLabel("");
    setIsConditionDataLabel("");
    SetSelectConditionOption("");
    setSelectedConditionOperator("");
    setSelectedConditionValue("");
    setSelectedCondition("");
    changeMode("view");
  };

  const handleTreeData = (e: any) => {
    setSelectedOption(e.target.value);
  };
  const handleOperator = (e: any, value: any) => {
    setSelectedOperator(value.optionValue);
  };
  const handleValue = (e: any, value: any) => {
    setSelectedValue(value.value);
  };
  const handleCondition = (e: any, value: any) => {
    setSelectedCondition(value.optionValue);
  };
  const handleConditionOperator = (e: any, value: any) => {
    setSelectedConditionOperator(value.optionValue);
  };
  const handleConditionValue = (e: any, value: any) => {
    setSelectedConditionValue(value.value);
  };
  const handleConditionTreeData = (e: any) => {
    SetSelectConditionOption(e.target.value);
  };
  const handleDropdownToggle = (isOpen: any) => {
    setIsDropdownOpen(isOpen);
  };

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const createExtract = async () => {
    const errors: Errors = {};
    if (!titleData) errors.titleData = "Title is required";
    if (!selectedOption) errors.selectedOption = "Field is required";
    if (selectedOption && selectedOption.length > 0 && !selectedOperator) {
      errors.selectedOperator = "Operator is required";
    }
    // if (selectedOption && selectedOption.length > 0 && !selectedValue)
    //   errors.selectedValue = "Value is required";
    if (selectedCondition) {
      if (
        selectedCondition &&
        selectedCondition.length > 0 &&
        !selectConditionOption
      )
        errors.selectConditionOption = "Field is required";
      if (
        selectedCondition &&
        selectedCondition.length > 0 &&
        !selectedConditionOperator
      )
        errors.selectedConditionOperator = "Operator is required";
      // if (
      //   selectedCondition &&
      //   selectedCondition.length > 0 &&
      //   !selectedValue // Use selectedValue here if it reflects the user's selection
      // ) {
      //   errors.selectedConditionValue = "Value is required";
      // }
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const countFields = [
      "Investments",
      "Share Holdings",
      "Home and Personal",
      "Bank/Building Societies",
      "Mortgages",
      "Loan and Hire Purchases",
      "Credit Cards",
      "Life Assurance",
      "Pensions",
      "Investments",
      "Savings Plans",
      "Income Protection",
      "Health Assurance",
      "General",
    ];

    const tableOneCondition = {
      table: tableOne,
      fieldName: selectedOption,
      label: isDataLabel,
      fk: "CustomerId",
      fieldValue:
        types.toString() === "timestamp"
          ? formatedDate2(selectedValue)
          : selectedValue,
      condition: selectedOperator,
      ...(countFields.includes(isDataLabel)
        ? { count: true, caseTypeField: "CaseType" }
        : {}),
      partner:
        clientpartner === "Client"
          ? false
          : clientpartner === "Partner"
          ? true
          : "",
    };

    const tableTwoCondition = {
      table: tableTwo,
      fieldName: selectConditionOption,
      fk: "CustomerId",
      label: isConditionDataLabel,
      fieldValue: selectedConditionValue,
      condition: selectedConditionOperator,
      ...(countFields.includes(selectConditionOption)
        ? { count: true, caseTypeField: "CaseType" }
        : {}),
      partner:
        conditionalClientPartner === "Client"
          ? false
          : conditionalClientPartner === "Partner"
          ? true
          : "",
    };

    const newData = {
      type: "Condition",
      title: titleData,
      description: descriptionData,
      condition: {
        tableOne: tableOneCondition,
        ...(selectedCondition && {
          tableTwo: tableTwoCondition,
        }),
        condition: selectedCondition,
      },
      UserId: checked ? userProfileInfo.user_id : null,
    };

    try {
      setLoading(true);

      if (mode === "add") {
        const response = await apiServices.extract.add(newData);
        setSearchID(response.data.data.SearchId);

        if (!response.data.isError) {
          notify(response.data.message, "success");
          getExtracts();
        } else {
          notify("Something went wrong", "error");
        }
      } else {
        const response = await apiServices.extract.update(
          selectedRow.SearchId,
          newData
        );
        setSearchID(response.data.data.SearchId);
        if (!response.data.isError) {
          notify(response.data.message, "success");
          getExtracts();
        } else {
          notify("Something went wrong", "error");
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchID) {
      searchId(searchID);
    }
  }, [searchID]);
  const handleSaveAndApply = async () => {
    try {
      await createExtract();
      setTabOpen(false);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error during extract operation:", error);
    }
  };

  const handleOptionClick = (
    value: any,
    label: any,
    itemType: string,
    datatypes: any,
    datatype: any,
    table: any,
    fieldName?: any,
    name?: any
  ) => {
    setTableOne(table);
    setSelectedOption(fieldName);
    setIsFilteredDataType(datatype);
    setDropdownExpand(false);
    setDataLabel(label);
  };

  const handleConditionOptionClick = (
    value: any,
    label: any,
    itemType: string,
    data: any,
    datatype: any,
    table: any,
    fieldName?: any
  ) => {
    setTableTwo(table);
    setOperatorName(label);
    SetSelectConditionOption(fieldName);
    setIsConditionalFilteredDataType(datatype);
    setDropdownExpanded(false);
    setIsConditionDataLabel(label);
  };
  const styles = useStyles();
  const getExtractList = async () => {
    try {
      const response = await apiServices.extract.getList();
      const data: any = response.data;

      setExtractData(data);
      setListData(data);
    } catch (error) {
      console.error("Failed to fetch extract list:", error);
    }
  };

  const setListData = (data: any) => {
    if (!Array.isArray(data)) {
      console.error("Provided data is not an array");
      return;
    }
    let datatypes: getDatatype[] = [];
    for (let i = 0; i < data.length; i++) {
      if (Array.isArray(data[i].items)) {
        for (let j = 0; j < data[i].items.length; j++) {
          if (Array.isArray(data[i].items[j].items)) {
            for (let k = 0; k < data[i].items[j].items.length; k++) {
              datatypes.push(data[i].items[j].items[k].datatype);
            }
          } else {
          }
        }
      } else {
      }
    }

    setIsDataType(datatypes);
  };
  const deleteData = () => {
    setLoading(true);
    apiServices.extract
      .delete(selectedRow.SearchId)
      .then((response: any) => {
        setLoading(false);
        notify(response.data.message, "success");
        getExtracts();
      })
      .catch((e: Error) => {
        setLoading(false);
      });

    dispatch(setDialogModalOptions({ open: false }));
  };

  const getClientDetails = (tableName: any, columnName: any) => {
    apiServices.extract
      .getClientList(tableName, columnName)
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data[0].options) {
            setClientDetail(response.data[0].options);
          }
        }
      })
      .catch((e: Error) => {
        console.error("API Error:", e);
      });
  };
  useEffect(() => {
    const tableName = tableOne;
    const columnName = selectedOption;

    if (tableName && columnName) {
      getClientDetails(tableName, columnName);
    } else {
      console.warn("Missing parameters: Table or Column");
    }
  }, [tableOne, selectedOption]);

  const conditionDataPopulation = (tableName: any, columnName: any) => {
    apiServices.extract
      .getClientList(tableName, columnName)
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data[0].options) {
            setConditionPopulation(response.data[0].options);
          }
        }
      })
      .catch((e: Error) => {
        console.error("API Error:", e);
      });
  };
  useEffect(() => {
    const tableName = tableTwo;
    const columnName = selectConditionOption;

    if (tableName && columnName) {
      conditionDataPopulation(tableName, columnName);
    } else {
      console.warn("Missing parameters: Table or Column");
    }
  }, [tableTwo, selectedCondition]);

  const types = isFilteredDataType;
  useEffect(() => {
    getExtractList();
    getExtracts();
  }, []);
  const handleSelectDate = (date: Date | null | undefined) => {
    if (date) {
      const isoDate = date.toISOString(); // Convert to ISO string
      setSelectedValue(isoDate); // Store the ISO string
    }
  };
  const handleSelectConditionDate = (date: Date | null | undefined) => {
    if (date) {
      const isoDate = date.toISOString();
      setSelectedConditionValue(isoDate);
    }
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }).format(date);
  };
  return (
    <>
      <OverlayLoader isLoading={loading} />
      <Toaster
        toasterId={toasterId}
        position="top-end"
        pauseOnHover
        pauseOnWindowBlur
        timeout={1000}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "600px",
          maxHeight: "600px",
        }}
      >
        <div
          className={styles.Addbutton}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            style={{ backgroundColor: "#0f6cbd" }}
            appearance="primary"
            {...restoreFocusTargetAttribute}
            onClick={() => {
              changeMode("add");
            }}
            disabled={mode === "add"}
          >
            Add Search
          </Button>
        </div>
        {extractData.length > 0 ? (
          <div>
            <div className="extract-form">
              {mode === "view" && (
                <div className="grid-container">
                  {selectedRow && (
                    <>
                      <div className="fieldWrapper">
                        <Label weight="semibold" size="medium">
                          Title
                        </Label>
                        <Label>{selectedRow.Title}</Label>
                      </div>
                      <div className="fieldWrapper">
                        <Label weight="semibold" size="medium">
                          Description
                        </Label>
                        <Label>{selectedRow.Description}</Label>
                      </div>
                      <div className="fieldWrapper">
                        <Label weight="semibold" size="medium">
                          Condition
                        </Label>
                        <Label>{formatCondition(selectedRow.Condition)}</Label>
                      </div>
                    </>
                  )}
                </div>
              )}
              {(mode === "add" || mode === "edit") && (
                <div>
                  <h3>{mode === "add" ? "Add Search" : "Edit Search"}</h3>
                  <div
                    style={{
                      margin: "10px 0",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "10px",
                    }}
                  >
                    <Field
                      label={
                        <span>
                          Title<span className="required-item">*</span>
                        </span>
                      }
                      validationState={
                        validationErrors.titleData ? "error" : "none"
                      }
                      validationMessage={validationErrors.titleData || ""}
                    >
                      <Input
                        value={titleData}
                        name="titleData"
                        onChange={(e) => {
                          handleTitleChange("titleData", e);
                          setValidationErrors({
                            ...validationErrors,
                            titleData: "",
                          });
                        }}
                      />
                    </Field>

                    <Field label="Description">
                      <Input
                        value={descriptionData}
                        name="descriptionData"
                        onChange={handleDescriptionChange}
                      />
                    </Field>
                  </div>
                  <div className={styles.inlineContainer}>
                    <Field label="Field">
                      <Dropdown
                        open={isDrodownExpand}
                        onClick={toggleDropdown}
                        name="selectedOption"
                        onOptionSelect={handleTreeData}
                        placeholder={isDataLabel || "Field"}
                        onOpenChange={handleDropdownToggle}
                        className="custom-dropdown"
                      >
                        <Tree
                          aria-label="Dynamic Tree"
                          key={treeKey}
                          className="custom-tree"
                        >
                          {renderTree(extractData, handleOptionClick)}
                        </Tree>
                      </Dropdown>
                    </Field>

                    <Field
                      label={
                        <span>
                          Operator
                          {selectedOption && selectedOption.length > 0 && (
                            <span className="required-item">*</span>
                          )}
                        </span>
                      }
                      validationState={
                        validationErrors.selectedOperator ? "error" : "none"
                      }
                      validationMessage={
                        validationErrors.selectedOperator || ""
                      }
                    >
                      <Dropdown
                        name="selectedOperator"
                        placeholder={selectedOperator || "Operator"}
                        onOptionSelect={(e, value) => {
                          handleOperator(e, value);
                          setValidationErrors({
                            ...validationErrors,
                            selectedOperator: "",
                          });
                        }}
                      >
                        {getOperators().map((opt) => (
                          <Option key={opt.value} value={opt.value}>
                            {opt.label}
                          </Option>
                        ))}
                      </Dropdown>
                    </Field>
                    <Field
                      label={
                        <span>
                          Value
                          {/* {selectedOption && selectedOption.length > 0 && (
                            <span className="required-item">*</span>
                          )} */}
                        </span>
                      }
                      // validationState={
                      //   validationErrors.selectedValue ? "error" : "none"
                      // }
                      // validationMessage={validationErrors.selectedValue || ""}
                    >
                      {types.toString() === "timestamp" ? (
                        <DatePicker
                          allowTextInput
                          value={
                            selectedValue ? new Date(selectedValue) : undefined
                          }
                          formatDate={(date) => formatDate(date)}
                          onSelectDate={handleSelectDate}
                          name="selectedValue"
                          placeholder="Date"
                        />
                      ) : types.toString() === "uuid" ? (
                        <Dropdown />
                      ) : types.toString() === "string" ? (
                        // <Combobox
                        //   aria-labelledby={comboId}
                        //   onOpenChange={(isOpen) => {
                        //     if (isOpen) {
                        //       const tableName = tableOne ? tableOne : tableTwo;
                        //       const columnName = selectedOption;
                        //       getClientDetails(tableName, columnName);
                        //     }
                        //   }}
                        //   onOptionSelect={(event, data) => {
                        //     const { optionText } = data;
                        //     if (optionText) {
                        //       setSelectedValue(optionText);
                        //     }
                        //   }}
                        // >
                        //   {clientDetail.map((option) => (
                        //     <Option key={option.label}>{option.label}</Option>
                        //   ))}
                        // </Combobox>
                        <div style={{ zIndex: 999 }}>
                          <CreatableSelect
                            isClearable
                            options={clientDetail}
                            onChange={(newValue) =>
                              setSelectedValue(newValue.value)
                            }
                          />
                        </div>
                      ) : (
                        <Input
                          onChange={(e) => {
                            handleValue(e, { value: e.target.value });
                            // setValidationErrors({
                            //   ...validationErrors,
                            //   selectedValue: "",
                            // });
                          }}
                          name="selectedValue"
                          type={
                            types.toString() === "number" ? "number" : "text"
                          }
                          placeholder={
                            types.toString() === "number" ? "Number" : "Value"
                          }
                          value={selectedValue}
                        />
                      )}
                    </Field>

                    <div style={{ marginTop: "23px" }}>
                      <Button
                        style={{ backgroundColor: "#0f6cbd" }}
                        appearance="primary"
                        onClick={handleAddCondition}
                      >
                        Add condition
                      </Button>
                    </div>
                  </div>

                  {showDialogPopup && (
                    <>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr 2fr 2fr 1fr",
                          margin: "5px",
                        }}
                      >
                        <div></div>
                        <Field>
                          <Dropdown
                            onOptionSelect={handleCondition}
                            name="selectedCondition"
                            placeholder="Condition"
                            value={selectedCondition}
                          >
                            {isCondition.map((x) => (
                              <Option key={x.id}>{x.name}</Option>
                            ))}
                          </Dropdown>
                        </Field>
                        <div></div>
                        <div></div>
                      </div>

                      <div className={styles.inlineContainer}>
                        <Field
                          label={
                            <span>
                              Field
                              {selectedCondition &&
                                selectedCondition.length > 0 && (
                                  <span className="required-item">*</span>
                                )}
                            </span>
                          }
                          validationState={
                            validationErrors.selectConditionOption
                              ? "error"
                              : "none"
                          }
                          validationMessage={
                            validationErrors.selectConditionOption || ""
                          }
                        >
                          <Dropdown
                            open={isDrodownExpanded}
                            onClick={toggleConditionDropdown}
                            name="selectConditionOption"
                            onOptionSelect={(value) => {
                              handleConditionTreeData(value);
                              setValidationErrors((prevErrors) => ({
                                ...prevErrors,
                                selectConditionOption: "",
                              }));
                            }}
                            placeholder={isConditionDataLabel || "Field"}
                            onOpenChange={handleDropdownToggle}
                            className="custom-dropdown"
                          >
                            <Tree
                              aria-label="Dynamic Tree"
                              key={treeConditionKey}
                              className="custom-tree"
                            >
                              {renderConditionTree(
                                extractData,
                                handleConditionOptionClick
                              )}
                            </Tree>
                          </Dropdown>
                        </Field>
                        <Field
                          label={
                            <span>
                              Operator
                              {selectedCondition &&
                                selectedCondition.length > 0 && (
                                  <span className="required-item">*</span>
                                )}
                            </span>
                          }
                          validationState={
                            validationErrors.selectedConditionOperator
                              ? "error"
                              : "none"
                          }
                          validationMessage={
                            validationErrors.selectedConditionOperator || ""
                          }
                        >
                          <Dropdown
                            onOptionSelect={(e, value) => {
                              handleConditionOperator(e, value);
                              setValidationErrors({
                                ...validationErrors,
                                selectedConditionOperator: "",
                              });
                            }}
                            name="selectedConditionOperator"
                            placeholder={
                              selectedConditionOperator || "Operator"
                            }
                          >
                            {getConditionOperators().map((option) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Dropdown>
                        </Field>

                        <Field
                          label={
                            <span>
                              Value
                              {/* {selectedCondition &&
                                selectedCondition.length > 0 && (
                                  <span className="required-item">*</span>
                                )} */}
                            </span>
                          }
                          // validationState={
                          //   validationErrors.selectedConditionValue
                          //     ? "error"
                          //     : "none"
                          // }
                          // validationMessage={
                          //   validationErrors.selectedConditionValue || ""
                          // }
                        >
                          {conditionDataType.toString() === "timestamp" ? (
                            <DatePicker
                              allowTextInput
                              // onChange={(value) => {
                              //   console.log(value,'value')
                              //   handleConditionValue(null, { value });
                              //   setValidationErrors({
                              //     ...validationErrors,
                              //     selectedConditionValue: "",
                              //   });
                              // }}
                              value={
                                selectedConditionValue
                                  ? new Date(selectedConditionValue)
                                  : undefined
                              }
                              formatDate={(date) => formatDate(date)}
                              // onSelectDate={(date) => {
                              //   if (date) {
                              //     setSelectedConditionValue(date);
                              //   }
                              // }}
                              onSelectDate={handleSelectConditionDate}
                              name="selectedConditionValue"
                              placeholder="Date"
                            />
                          ) : conditionDataType.toString() === "uuid" ? (
                            <Dropdown placeholder="" />
                          ) : conditionDataType.toString() === "string" ? (
                            // <Combobox
                            //   aria-labelledby={comboId}
                            //   onOpenChange={(isOpen) => {
                            //     if (isOpen) {
                            //       const tableName = tableOne
                            //         ? tableOne
                            //         : tableTwo;
                            //       const columnName = selectConditionOption;
                            //       getClientDetails(tableName, columnName);
                            //     }
                            //   }}
                            //   onOptionSelect={(event, data) => {
                            //     const { optionText } = data;
                            //     if (optionText) {
                            //       setSelectedValue(optionText);
                            //       setValidationErrors((prevErrors) => ({
                            //         ...prevErrors,
                            //         selectedConditionValue: "",
                            //       }));
                            //     }
                            //   }}
                            // >
                            //   {clientDetail.map((option) => (
                            //     <Option key={option.label}>
                            //       {option.label}
                            //     </Option>
                            //   ))}
                            // </Combobox>
                            <div style={{ zIndex: 999 }}>
                              <CreatableSelect
                                isClearable
                                options={conditionPopulation}
                                onChange={(newValue) =>
                                  setSelectedConditionValue(newValue.value)
                                }
                              />
                            </div>
                          ) : (
                            <Input
                              onChange={(e) => {
                                handleConditionValue(e, {
                                  value: e.target.value,
                                });
                                // setValidationErrors({
                                //   ...validationErrors,
                                //   selectedConditionValue: "",
                                // });
                              }}
                              type={
                                conditionDataType.toString() === "number"
                                  ? "number"
                                  : "text"
                              }
                              name="selectedConditionValue"
                              placeholder={
                                conditionDataType.toString() === "number"
                                  ? "Number"
                                  : "Value"
                              }
                              value={selectedConditionValue}
                            />
                          )}
                        </Field>
                        <div style={{ marginTop: "23px" }}>
                          <Button
                            style={{ backgroundColor: "#0f6cbd" }}
                            appearance="primary"
                            onClick={handleDeleteCondition}
                            disabled={isDeleted}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    label="Visible to all Users"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "5px",
                      margin: "10px",
                    }}
                  >
                    <Button appearance="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      style={{ backgroundColor: "#0f6cbd" }}
                      onClick={async () => {
                        await createExtract();
                        if (Object.keys(validationErrors).length === 0) {
                          handleClose();
                        }
                      }}
                      appearance="primary"
                    >
                      Save
                    </Button>
                    <Button
                      style={{ backgroundColor: "#0f6cbd" }}
                      onClick={handleSaveAndApply}
                      // disabled={titleData === ""}
                      appearance="primary"
                    >
                      Save and Apply
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div style={{ margin: 12 }}>
              <MaterialReactTable
                columns={tableColumnList.extractList}
                data={extract}
                enableRowSelection={true}
                onRowSelectionChange={setRowSelection}
                enableMultiRowSelection={false}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => setSelectedRow(row.original),
                  sx: { cursor: "pointer" },
                })}
                enableRowActions={true}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Actions",
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    muiTableBodyCellProps: {
                      align: "center",
                    },
                  },
                }}
                renderRowActions={({ row }) => (
                  <div className={styles.wrapper}>
                    <Tooltip
                      content="Edit"
                      relationship="label"
                      positioning="below"
                    >
                      <Button
                        appearance="subtle"
                        icon={
                          <Edit16Filled
                            primaryFill="var(--button-color)"
                            onClick={() => {
                              changeMode("edit", row.original.SearchId);
                            }}
                          />
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      content="Delete"
                      relationship="label"
                      positioning="below"
                    >
                      <Button
                        appearance="subtle"
                        style={{ color: "red" }}
                        icon={<Delete16Regular />}
                        onClick={() => {
                          dispatch(
                            setDialogModalOptions({
                              open: true,
                              content_line_1: "Do you want to delete?",
                              no: {
                                onclick: () => {
                                  dispatch(
                                    setDialogModalOptions({ open: false })
                                  );
                                },
                                label: "No",
                                type: "secondary",
                              },
                              cancel: {
                                onclick: () => {
                                  deleteData();
                                },
                                label: "Yes",
                                type: "primary",
                              },
                            })
                          );
                        }}
                      ></Button>
                    </Tooltip>
                  </div>
                )}
                initialState={{
                  columnPinning: { right: ["mrt-row-actions"] },
                }}
                positionActionsColumn="last"
                positionToolbarAlertBanner="bottom"
                state={{ rowSelection }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            {mode === "" && (
              <Label size="large" style={{ color: "red" }}>
                There are no active records - click Add New to create a new
                record
              </Label>
            )}
          </div>
        )}
      </div>
    </>
  );
};
