import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import ContactHistory from "./ContactHistory/ContactHistory";
import CaseSummary from "./CaseSummary/CaseSummary";
import ClientSummary from "./ClientSummary/ClientSummary";
import ClientPageActions from "./ClientPageActions";
import DependantForm from "./Dependant/DependantForm";
import IncomeForm from "./Income/IncomeForm";
import OutgoingsForm from "./Outgoings/OutgoingsForm";
import VulnerabilityForm from "./Vulnerability/VulnerabilityForm";
import ModalDialog from "../../components/ModalDialog";
import AddressBookForm from "./AddressBook/AddressBookForm";
import FactFindNoteForm from "./FactFindNote/FactFindNoteForm";
import FeesForm from "./FeesCharges/FeesForm";
import ClientDocument from "./Documents/clientDocument";
import ContactForm from "./contact/ContactForm";
import EmploymentForm from "./Employment/EmploymentForm";
import AttitudeToRiskForm from "./AttitudeToRisk/AttitudeToRiskForm";
import IdentityForm from "./Identity/IdentityForm";
import ObjectiveForm from "./objectives/ObjectiveForm";
import ServiceTypeForm from "./ServiceType/ServiceTypeForm";
import UserDefinedForm from "./userDefined/userDefinedForm";
function ClientSubMenuItem(props: any) {
  const { clientId, section, action, assetId, partnerId, sectionId } =
    useParams();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes("contacts") && !props.section) {
      navigate(`/clients/${clientId}/contacts/client-action`);
    }
  }, [location.pathname]);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const { clientInfo, notify } = useOutletContext<any>();
  const renderSectionComponent = () => {
    if (props.module != undefined && props.module == "partner" && clientInfo) {
      let partnerId = clientInfo.associated_with;

      switch (props.section) {
        case "employment":
          return (
            <EmploymentForm activeCustomerId={partnerId} module="partner" />
          );
        case "attitude-to-risk":
          return (
            <AttitudeToRiskForm activeCustomerId={partnerId} module="partner" />
          );
        case "identity":
          return <IdentityForm activeCustomerId={partnerId} module="partner" />;
        case "objectives":
          return (
            <ObjectiveForm activeCustomerId={partnerId} module="partner" />
          );
        case "service-type":
          return (
            <ServiceTypeForm activeCustomerId={partnerId} module="partner" />
          );
        default:
          return "";
      }
    } else {
      switch (props.section) {
        case "dependant":
          return <DependantForm activeCustomerId={clientId} notify={notify} />;

        case "income":
          return <IncomeForm activeCustomerId={clientId} notify={notify} />;
        case "outgoings":
          return <OutgoingsForm activeCustomerId={clientId} notify={notify} />;

        case "address-book":
          return (
            <AddressBookForm activeCustomerId={clientId} notify={notify} />
          );
        case "fact-find-notes":
          return (
            <FactFindNoteForm activeCustomerId={clientId} notify={notify} />
          );
        case "fees-charges":
          return <FeesForm activeCustomerId={clientId} notify={notify} />;
        case "documents":
          return <ClientDocument activeCustomerId={clientId} notify={notify} />;
        case "client-action":
        case "notes":
        case "time-allocation":
          return (
            <ContactForm
              section={props.section}
              activeCustomerId={clientId}
              notify={notify}
            />
          );
        case "vulnerability":
          return (
            <VulnerabilityForm
              activeCustomerId={clientId}
              action={action}
              notify={notify}
              sectionId={sectionId}
              section={props.section}
              module="client"
            />
          );
        case "employment":
          return (
            <EmploymentForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );
        case "attitude-to-risk":
          return (
            <AttitudeToRiskForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );
        case "identity":
          return (
            <IdentityForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );
        case "objectives":
          return (
            <ObjectiveForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );
        case "service-type":
          return (
            <ServiceTypeForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );
        case "user-defined":
          return (
            <UserDefinedForm
              activeCustomerId={clientId}
              module="client"
              notify={notify}
            />
          );

        default:
          return "";
      }
    }
  };
  return (
    <>
      <div>{renderSectionComponent()}</div>{" "}
      <ModalDialog options={dialogModalOptions} />
    </>
  );
}

export default ClientSubMenuItem;
