import * as React from "react";
import {
  Skeleton,
  SkeletonItem,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding("20px"),
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
    gap: "20px", // Space between columns
  },
  column: {
    display: "grid",
    gridTemplateRows: "repeat(6, auto)", // 6 rows per column
    rowGap: "20px", // Space between rows
  },
  labelValuePair: {
    display: "flex",
    flexDirection: "column",
    rowGap: "6px", // Space between label and value
  },
});

export const FormLoader = (props: Partial<SkeletonProps>) => {
  const styles = useStyles();
  return (
    <div className={styles.invertedWrapper}>
      <Skeleton {...props}>
        <div className={styles.gridContainer}>
          {/* Generate 3 columns */}
          {Array.from({ length: 3 }).map((_, columnIndex) => (
            <div key={columnIndex} className={styles.column}>
              {/* Generate 6 rows per column */}
              {Array.from({ length: 6 }).map((_, rowIndex) => (
                <div key={rowIndex} className={styles.labelValuePair}>
                  <SkeletonItem shape="rectangle" size={12} /> {/* Label */}
                  <SkeletonItem shape="rectangle" size={16} /> {/* Value */}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Skeleton>
    </div>
  );
};
