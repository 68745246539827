import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ClientList from "../pages/client/List";
import ClientLayout from "../layout/index";
import ActionList from "../pages/Actionlist/List";
import Report from "../pages/report";
import SystemManagerLayout from "../pages/systemManager/SystemManagerLayout";
import MasterSettings from "../pages/systemManager/masterSettings";
import { useEffect, useState } from "react";
import ManageUser from "../pages/systemManager/manageUser";
import ProfileSettings from "../pages/ProfileSettings";
import { checkUserPermission } from "../utils";
import { UserPermissionsList } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Commission from "../pages/systemManager/commission";
import Utilities from "../pages/utilities/utilities";
import Subscription from "../pages/systemManager/Subscription";
import MasterSttingsPage from "../pages/systemManager/masterSettings/MasterSttingsPage";
import Integrations from "../pages/systemManager/Integrations";
import ContractEnquiry from "../pages/systemManager/ContractEnquiry";
import Templates from "../pages/systemManager/templateEngine/LetterTemplates";
import Providers from "../pages/systemManager/Providers/Providers";
import CompanyDetails from "../pages/systemManager/CompanyDetails/CompanyDetails";
import ReportMain from "../pages/report/ReportMain";
import ReportDemo from "../pages/New_report/ReportDemo";
import ProfileRouting from "../pages/ProfileSettings/profileRouting";
import {
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import ClientDetailPage from "../pages/client/ClientDetailPage";
import SectionHandler from "../pages/client/SectionHandler";
import CaseSectionHandler from "../pages/client/CaseSectionHandler";

import CaseHomePage from "../pages/client/case/CaseHomePage";
import CaseForm from "../pages/client/case/CaseForm";
import CaseDetailPage from "../pages/client/case/CaseDetailPage";
import CaseSubItemHomePage from "../pages/client/case/CaseSubItemHomePage";
import CaseSubItemHandler from "../pages/client/case/CaseSubItemHandler";
import CaseHandler from "../pages/client/CaseHandler";
import CaseSubItemDetailPage from "../pages/client/case/CaseSubItemDetailPage";
import PartnerSectionHandler from "../pages/client/PartnerSectionHandler";
import ClientSubMenuItem from "../pages/client/ClientSubMenuItem";
import ClientSubItemHomePage from "../pages/client/ClientSubItemHomePage";
import Dependant from "../pages/client/Dependant";
import ClientAddForm from "../pages/client/ClientAddForm";
import { Controlled } from "../pages/SamplePage";
import CommissionLayout from "../pages/Commission/CommissionLayout/Layout";
import List from "../pages/Commission/CommissionReceipts/List";
import DistributionDatalist from "../pages/Commission/CommissionDistributon/DistributionDatalist";
import EmailTemplates from "../pages/systemManager/templateEngine/EmailTemplates";
import ListDetils from "../pages/Commission/OtherFeesCharges/ListDetils";
import UtilitiesLayout from "../pages/utilities/UtilitiesLayout";
import TemplateDropdown from "../pages/utilities/EmailNotification/TemplateDropdown";
import LetterGeneration from "../pages/utilities/EmailNotification/LetterGeneration";

export const PrivateRoutes = () => {
  const [mode, setMode] = useState("view");

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const sideMenuGlobal = {
    actionList: [],
    clients: [],
    reports: [],
    utilities: [],
    systemManager: [
      { label: "Subscription" },
      { label: "Manage Users", link: "/system-manager/manage-user" },
      { label: "Commission", link: "/system-manager/Commission" },
    ],
  };

  if (
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
    )
  ) {
    const manageUsersIndex = sideMenuGlobal.systemManager.findIndex(
      (item) => item.label === "Manage Users"
    );
    sideMenuGlobal.systemManager.splice(manageUsersIndex + 1, 0, {
      label: "Master Settings",
      link: "/system-manager",
    });
  }

  const [tabs, setTabs] = useState("");
  const [activeMenu, setActiveMenu] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  useEffect(() => {
    if (
      checkUserPermission(
        loggedUser,
        UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
      )
    ) {
      setIsMaster(true);
      setActiveMenu("Master Settings");
    } else {
      setActiveMenu("Manage Users");
      setIsMaster(false);
    }
  }, [loggedUser]);
  const sections = [
    "dependant",
    "income",
    "outgoings",
    "address-book",
    "fact-find-notes",
    "fees-charges",
    "documents",
    "vulnerability",
    "employment",
    "attitude-to-risk",
    "identity",
    "objectives",
    "service-type",
    "user-defined",
  ];
  const partnerSections = [
    "employment",
    "attitude-to-risk",
    "identity",
    "objectives",
    "service-type",
  ];
  const contactsRoutes = [
    {
      path: "client-action",
      section: "client-action",
    },
    {
      path: "notes",
      section: "notes",
    },
    {
      path: "time-allocation",
      section: "time-allocation",
    },
  ];
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClientLayout
            tabs={tabs}
            sideMenuGlobal={sideMenuGlobal}
            activeMenu={activeMenu}
          />
        }
      >
        <Route index element={<ClientList setTabs={setTabs} />} />
        <Route path="/clients" element={<ClientList setTabs={setTabs} />} />
        <Route path="/clients/add" element={<ClientAddForm />} />
        <Route path="clients/:clientId" element={<ClientDetailPage />}>
          <Route index element={<SectionHandler />} />
          <Route path=":section" element={<SectionHandler />} />
          <Route path=":section/:action" element={<SectionHandler />} />

          {sections.map((section) => (
            <React.Fragment key={section}>
              <Route
                path={section}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:action`}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:selectedItemId`}
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path={`${section}/:selectedItemId/:action`}
                element={<ClientSubMenuItem section={section} />}
              />
            </React.Fragment>
          ))}
          <Route path="contacts" element={<ClientSubMenuItem />} />
          {contactsRoutes.map(({ path, section }) => (
            <Route
              key={path}
              path={`contacts/${path}`}
              element={<ClientSubMenuItem section={section} />}
            >
              <Route
                path=":action"
                element={<ClientSubMenuItem section={section} />}
              />
              <Route
                path=":selectedItemId/:action"
                element={<ClientSubMenuItem section={section} />}
              />
            </Route>
          ))}
          <Route path="partner" element={<PartnerSectionHandler />} />
          <Route path="partner/:section" element={<PartnerSectionHandler />} />
          <Route
            path="partner/:section/:action"
            element={<PartnerSectionHandler />}
          />
          {partnerSections.map((section) => (
            <React.Fragment key={`partner/${section}`}>
              <Route
                path={`partner/${section}`}
                element={
                  <ClientSubMenuItem section={section} module="partner" />
                }
              />
              <Route
                path={`partner/${section}/:action`}
                element={
                  <ClientSubMenuItem section={section} module="partner" />
                }
              />
              <Route
                path={`partner/${section}/:selectedItemId/:action`}
                element={
                  <ClientSubMenuItem section={section} module="partner" />
                }
              />
            </React.Fragment>
          ))}
          <Route path="assets" element={<CaseHomePage section="assets" />} />

          <Route
            path="assets/:action"
            element={<CaseDetailPage section="assets" tab="asset" />}
          />
          <Route
            path="assets/:caseId/asset"
            element={<CaseDetailPage section="assets" tab="asset" />}
          />
          <Route
            path="assets/:caseId/asset/:action"
            element={<CaseDetailPage section="assets" tab="asset" />}
          />
          <Route
            path="assets/:caseId/review"
            element={<CaseDetailPage section="assets" tab="review" />}
          />
          <Route
            path="assets/:caseId/review/:action"
            element={<CaseDetailPage section="assets" tab="review" />}
          />
          <Route
            path="assets/:caseId/funds"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="funds"
                tab="fund"
              />
            }
          />
          <Route
            path="assets/:caseId/funds/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="funds"
                tab="fund"
              />
            }
          />
          <Route
            path="assets/:caseId/funds/:subItemId/fund/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="funds"
                tab="fund"
              />
            }
          />

          <Route
            path="assets/:caseId/payments"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/payment/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/compliance/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="compliance"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/distribution"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="distribution"
              />
            }
          />
          <Route
            path="assets/:caseId/payments/:subItemId/commission/:subSubItemId/distribution/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="payments"
                tab="distribution"
              />
            }
          />
          <Route
            path="assets/:caseId/withdrawals"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="assets/:caseId/withdrawals/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="assets/:caseId/withdrawals/:subItemId/withdrawal/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="assets/:caseId/actions"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="assets/:caseId/actions/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="assets/:caseId/actions/:subItemId/action/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="assets/:caseId/valuations"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
          <Route
            path="assets/:caseId/valuations/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
          <Route
            path="assets/:caseId/valuations/:subItemId/valuation/:action"
            element={
              <CaseSubItemDetailPage
                section="assets"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
          <Route
            path="liabilities"
            element={<CaseHomePage section="liabilities" />}
          />

          <Route
            path="liabilities/:action"
            element={<CaseDetailPage section="liabilities" tab="liability" />}
          />
          <Route
            path="liabilities/:caseId/liability/:action"
            element={<CaseDetailPage section="liabilities" tab="liability" />}
          />
          <Route
            path="liabilities/:caseId/review/:action"
            element={<CaseDetailPage section="liabilities" tab="review" />}
          />

          <Route
            path="liabilities/:caseId/payments"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/payment/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/compliance/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="compliance"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="liabilities/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="payments"
                tab="commission"
              />
            }
          />

          <Route
            path="liabilities/:caseId/actions"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="liabilities/:caseId/actions/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="liabilities/:caseId/actions/:subItemId/action/:action"
            element={
              <CaseSubItemDetailPage
                section="liabilities"
                subItem="actions"
                tab="action"
              />
            }
          />

          <Route
            path="policies"
            element={<CaseHomePage section="policies" />}
          />

          <Route
            path="policies/:action"
            element={<CaseDetailPage section="policies" tab="policy" />}
          />
          <Route
            path="policies/:caseId/policy/:action"
            element={<CaseDetailPage section="policies" tab="policy" />}
          />
          <Route
            path="policies/:caseId/review/:action"
            element={<CaseDetailPage section="policies" tab="review" />}
          />
          <Route
            path="policies/:caseId/funds"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="funds"
                tab="fund"
              />
            }
          />
          <Route
            path="policies/:caseId/funds/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="funds"
                tab="fund"
              />
            }
          />
          <Route
            path="policies/:caseId/funds/:subItemId/fund/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="funds"
                tab="fund"
              />
            }
          />

          <Route
            path="policies/:caseId/payments"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:subItemId/payment/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="payment"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:subItemId/compliance/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="compliance"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="policies/:caseId/payments/:subItemId/commission/:subSubItemId/commission/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="payments"
                tab="commission"
              />
            }
          />
          <Route
            path="policies/:caseId/withdrawals"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="policies/:caseId/withdrawals/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="policies/:caseId/withdrawals/:subItemId/withdrawal/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="withdrawals"
                tab="withdrawal"
              />
            }
          />
          <Route
            path="policies/:caseId/actions"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="policies/:caseId/actions/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="policies/:caseId/actions/:subItemId/action/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="actions"
                tab="action"
              />
            }
          />
          <Route
            path="policies/:caseId/valuations"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
          <Route
            path="policies/:caseId/valuations/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
          <Route
            path="policies/:caseId/valuations/:subItemId/valuation/:action"
            element={
              <CaseSubItemDetailPage
                section="policies"
                subItem="valuations"
                tab="valuation"
              />
            }
          />
        </Route>
        <Route path="/action-list" element={<ActionList setTabs={setTabs} />} />

        {/* <Route path="/report" element={<Report setTabs={setTabs} />} /> */}
        {/* <Route path="/reportdemo" element={<ReportDemo />} /> */}
        <Route path="/report" element={<ReportDemo />} />

        <Route
          path="/commission"
          element={<CommissionLayout setTabs={setTabs} tabs={tabs} />}
        >
          <Route path="" element={<List />} />
          {/* <Route
            path="/commission"
            element={<ClientList module="commission" />}
          /> */}
          <Route path="/commission/receipting" element={<List />} />
          <Route
            path="/commission/distribution"
            element={<DistributionDatalist />}
          />
          <Route
            path="/commission/other-fees-charges"
            element={<ListDetils />}
          />
        </Route>
        <Route
          path="/system-manager"
          element={<SystemManagerLayout setTabs={setTabs} />}
        >
          <Route path="" element={<CompanyDetails />} />
          <Route path="master-settings" element={<MasterSttingsPage />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="company-details" element={<CompanyDetails />} />
          <Route path="contract-enquiry" element={<ContractEnquiry />} />
          <Route path="providers" element={<Providers />} />
          <Route path="/system-manager/templates" element={<Templates />} />
          <Route path="templates/:section" element={<Templates />} />
          <Route path="template/:section" element={<EmailTemplates />} />
          <Route
            path="/system-manager/customisations"
            element={<MasterSttingsPage />}
          />
          <Route
            path="customisations/:section"
            element={<MasterSttingsPage />}
          />
          <Route
            path="manage-user"
            element={<ManageUser setActiveMenu={setActiveMenu} />}
          />
          <Route path="commission" element={<Commission />} />
          <Route path="commission/:section" element={<Commission />} />
        </Route>

        <Route
          path="/profile"
          element={
            <ProfileSettings
              setActiveMenu={setActiveMenu}
              mode={mode}
              setMode={setMode}
            />
          }
        >
          <Route
            path=":section"
            element={<ProfileRouting mode={mode} setMode={setMode} />}
          />
          <Route index element={<Navigate to="/profile/account" replace />} />
        </Route>
        <Route path="/utilities" element={<UtilitiesLayout />}>
          <Route index element={<TemplateDropdown />} />
          {/* <Route path="report" element={<ReportDemo />} /> */}
          <Route path="email" element={<TemplateDropdown />} />
          <Route path="letter" element={<LetterGeneration />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
