import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import React from "react";

function DeletePopup(props: any) {
  return (
    <Dialog open={props.deletePopup}>
      <DialogSurface>
        <DialogBody>
          <h3>Do you want to delete?</h3>
          <DialogActions>
            <Button
              className="asc-button-primary"
              appearance="primary"
              onClick={() => props.setDeletePopup(false)}
            >
              No
            </Button>
            <Button
              appearance="secondary"
              onClick={() => props.deleteData()}
              icon={props.deleteApiProcessing ? <Spinner size="tiny" /> : null}
            >
              {props.deleteApiProcessing ? "Processing..." : "Yes"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default DeletePopup;
