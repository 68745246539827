import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { formBuilder } from "../../utils/formGenerator";
import FormContainer from "../../components/Form/FormContainer";
import { FormLoader } from "../../components/Loader/FormLoader";

type ConfigFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};

const ClientPageActions = ({
  clientId,
  action,
  section,
  clientInfo,
  notify,
  module,
  partnerId,
}: any) => {
  const [configForm, setConfigForm] = useState<ConfigFm | null>(null);
  const [formContext, setFormContext] = useState("");
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);

  const ClientContexts = [
    { key: "name-and-address", value: "CLIENT_NAME_ADDRESS" },
    { key: "contact", value: "CLIENT_CONTACT" },
    { key: "nationality", value: "CLIENT_NATIONALITY" },
    { key: "personal", value: "CLIENT_PERSONAL" },
    { key: "vulnerability", value: "CLIENT_VULNERABILITY" },
    { key: "health-note", value: "CLIENT_HEALTH_NOTE" },
    { key: "employment", value: "CLIENT_EMPLOYMENT" },
    { key: "attitude-to-risk", value: "CLIENT_ATTITUDE_TO_RISK" },
    { key: "identity", value: "CLIENT_IDENTITY" },
    { key: "objectives", value: "CLIENT_OBJECTIVES" },
    { key: "administration", value: "ADMINISTRATION" },
    { key: "compliance", value: "COMPLIANCE" },
    { key: "service-type", value: "CLIENT_SERVICE_TYPE" },
    { key: "user-defined", value: "CLIENT_USER_DEFINED" },
  ];

  useEffect(() => {
    const context = ClientContexts.find((ctx) => ctx.key === section)?.value;
    console.log("context===", context);
    setFormContext(context || "");
  }, [section]);

  useEffect(() => {
    if (formContext && formSettingsObj?.data?.formConfig) {
      const configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      console.log("formContext===", formContext);
      console.log("configObj===", configObj);
      if (configObj) {
        setConfigForm(configObj);
        const { initialValues, inputs, validationSchema } = formBuilder({
          context: formContext,
          formSettings: formSettingsObj.data,
        });
        setInitialData({ initialValues, inputs, validationSchema });
      }
      setIsLoading(false);
    }
  }, [formContext, formSettingsObj]);

  const changeMode = (action: string) => {
    if (module != undefined && module == "partner") {
      navigate(`/clients/${clientId}/partner/${section}/${action}`);
    } else {
      navigate(`/clients/${clientId}/${section}/${action}`);
    }
  };

  const renderFormContent = () => {
    if (!formContext || formContext !== configForm?.context) return null;

    return (
      <div className="form-container-wrapper">
        <FormContainer
          context={formContext}
          mode={action}
          activeCustomerId={clientId}
          initialData={
            ["edit", "view"].includes(action) ? clientInfo : initialData
          }
          notify={notify}
          changeMode={changeMode}
          config={configForm}
          module={module}
          partnerId={partnerId}
        />
      </div>
    );
  };

  return <>{isLoading ? <FormLoader /> : <div>{renderFormContent()}</div>}</>;
};

export default ClientPageActions;
