import instance, { axiosPublicInstance } from "./apiService";
import { clientInfo } from "../types/client";
import { ApiConstants } from "../config";

const apiServices = {
  getFormSettingsFiles: {
    getSettings: () => {
      return instance.get(`${ApiConstants.formSettings}`);
    },
  },
  auth: {
    login: (data: any) => {
      return axiosPublicInstance.post<any>(`${ApiConstants.auth.login}`, data);
    },
    login2: (data: any) => {
      return instance.post<any>(`${ApiConstants.auth.login2}`, data);
    },
    getUserInfo: (data: any) => {
      return axiosPublicInstance.post<any>(
        `${ApiConstants.auth.authUser}`,
        data
      );
    },
    logout: () => {
      return instance.get(`${ApiConstants.auth.logout}`);
    },
  },
  client: {
    getClientLists: (
      page?: number,
      limit?: number,
      search?: string,
      scopeSelected?: string,
      selectedClientType?: string,
      search_id?: string
    ) => {
      let apiUrl = `${ApiConstants.client.listAll}?type=client_list&limit=${limit}&page=${page}`;
      if (search) {
        apiUrl += `&search=${search}`;
      }
      if (scopeSelected) {
        apiUrl += `&scope=${scopeSelected}`;
      }
      if (selectedClientType) {
        apiUrl += `&client_type=${selectedClientType}`;
      }
      if (search_id) {
        apiUrl += `&search_id=${search_id}`;
      }
      return instance.get(apiUrl);
    },
    getClients: (
      page?: number,
      limit?: number,
      search?: string,
      scopeSelected?: string,
      selectedClientType?: string,
      search_id?: string
    ) => {
      let apiUrl = `${ApiConstants.client.listAll}?limit=${limit}&page=${page}`;
      if (search) {
        apiUrl += `&search=${search}`;
      }
      if (scopeSelected) {
        apiUrl += `&scope=${scopeSelected}`;
      }
      if (selectedClientType) {
        apiUrl += `&client_type=${selectedClientType}`;
      }
      if (search_id) {
        apiUrl += `&search_id=${search_id}`;
      }
      return instance.get(apiUrl);
      // instance({
      //   method: "GET",
      //   url:
      //     `${ApiConstants.client.listAll}?limit=${limit}&page=${page}&search=` +
      //     search,
      // });
    },
    getDetailByTab: (id: any, type?: string, page?: number, limit?: number) => {
      const params = new URLSearchParams();
      if (page !== undefined) params.append("page", page.toString());
      if (limit !== undefined) params.append("limit", limit.toString());

      let url = `${ApiConstants.client.detailByTab}/${id}/${type}`;
      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      return instance.get<any>(url);
    },
    getDetail: (id: any) => {
      const url = `${ApiConstants.client.detail}/${id}`;
      return instance.get<clientInfo>(url);
    },

    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.client.add}`, data);
    },
    updateClient: (id: any, data: any) => {
      return instance.patch<any>(`${ApiConstants.client.update}/${id}`, data);
    },
    getClientDetail: (id: any) => {
      // return instance.get<clientInfo>(`${ApiConstants.client.detail}/${id}`);
      return instance.get<clientInfo>(`${ApiConstants.client.detail}/${id}`);
    },
    updateServiceType: (clientId: any, serviceTypeId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.client.update}/${clientId}/servicetype/${serviceTypeId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.client.update}/${clientId}/servicetype/${serviceTypeId}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.client.delete}/${id}`);
    },
    deleteServiceType: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.client.deleteServiceType}/${id}`
      );
    },
    updateObjectives: (clientId: any, objectiveId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.client.update}/${clientId}/objective/${objectiveId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.client.update}/${clientId}/objective/${objectiveId}`,
        data
      );
    },
    deleteObjective: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.client.deleteObjective}/${id}`
      );
    },
    updateIdentity: (clientId: any, identityId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.client.update}/${clientId}/identity/${identityId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.client.update}/${clientId}/identity/${identityId}`,
        data
      );
    },
    deleteIdentity: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.client.deleteIdentity}/${id}`
      );
    },
    updateEmployment: (clientId: any, employmentId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.client.update}/${clientId}/employment/${employmentId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.client.update}/${clientId}/employment/${employmentId}`,
        data
      );
    },
    employmentDelete: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.client.employmentDelete}/${id}`
      );
    },
    addUserDefined: (data: any) => {
      return instance.post<any>(`${ApiConstants.clientUserDefined.add}`, data);
    },
    updateUserDefined: (clientId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.clientUserDefined.update}/${clientId}`,
        data
      );
    },
    getUserDefined: (customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.clientUserDefined.get}/${customerId}`
      );
    },
    vulnerability: {
      create: (data: any) => {
        return instance.post<any>(`${ApiConstants.vulnerability.add}`, data);
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.vulnerability.update}/${id}`,
          data
        );
      },
      get: (customerId: any) => {
        return instance.get<any>(
          `${ApiConstants.vulnerability.get}/${customerId}`
        );
      },
      getDriver: () => {
        return instance.get<any>(`${ApiConstants.vulnerability.getDriver}`);
      },
      getCharacteristics: (driverId: any) => {
        return instance.get<any>(
          `${ApiConstants.vulnerability.getCharacteristics}/${driverId}`
        );
      },
    },
  },
  assets: {
    getAssets: (id: any) =>
      instance({
        method: "GET",
        url: `${ApiConstants.asset.list}/${id}`,
      }),
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.asset.add}`, data);
    },
    updateAsset: (id: any, data: any) => {
      return instance.patch<any>(`${ApiConstants.asset.update}/${id}`, data);
    },
    getDetail: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.asset.getDetail}/${id}/customer/${customerId}`
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.asset.delete}/${id}`);
    },
  },
  policy: {
    getPolicies: (id: any) =>
      instance({
        method: "GET",
        url: `${ApiConstants.policy.list}/${id}`,
      }),
    getDetail: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.policy.getDetail}/${id}/customer/${customerId}`
      );
    },
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.policy.add}`, data);
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(`${ApiConstants.policy.update}/${id}`, data);
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.policy.delete}/${id}`);
    },
  },
  liability: {
    getLiabilities: (id: any) =>
      instance({
        method: "GET",
        url: `${ApiConstants.liability.list}/${id}`,
      }),
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.liability.add}`, data);
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.liability.update}/${id}`,
        data
      );
    },
    getDetail: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.liability.getDetail}/${id}/customer/${customerId}`
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.liability.delete}/${id}`);
    },
  },
  commission: {
    addCommission: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.commission.addCommission}`,
        data
      );
    },
    getCommission: (paymentId: any, customerId: any, caseId: any) => {
      return instance.get<any>(
        `${ApiConstants.commission.getCommission}/${paymentId}/customer/${customerId}/case/${caseId}`
      );
    },
    updateCommission: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.commission.updateCommission}/${id}`,
        data
      );
    },
  },
  distribution: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.distribution.add}`, data);
    },
    get: (paymentId: any, customerId: any, caseId: any) => {
      return instance.get<any>(
        `${ApiConstants.distribution.get}/${paymentId}/customer/${customerId}/case/${caseId}`
      );
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.distribution.update}/${id}`,
        data
      );
    },
  },
  outgoings: {
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.outgoings.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.outgoings.list}/${id}`);
    },
    updateOutgoings: (outgoingId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.outgoings.update}/${outgoingId}`,
        data
      );
    },
    delete: (outgoingId: any) => {
      return instance.delete<any>(
        `${ApiConstants.outgoings.delete}/${outgoingId}`
      );
    },
  },
  income: {
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.income.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.income.list}/${id}`);
    },
    updateIncome: (income_id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.income.update}/${income_id}`,
        data
      );
    },
    delete: (income_id: any) => {
      return instance.delete<any>(`${ApiConstants.income.delete}/${income_id}`);
    },
  },
  dependant: {
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.dependant.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.dependant.list}/${id}`);
    },
    updateDependant: (customerId: any, dependentId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.dependant.update}/${customerId}/depentant/${dependentId}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.dependant.delete}/${id}`);
    },
  },
  addressBook: {
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.addressBook.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.addressBook.list}/${id}`);
    },
    updateAddressBook: (customerId: any, addressBookId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.addressBook.update}/${customerId}/addressbook/${addressBookId}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.addressBook.delete}/${id}`);
    },
  },
  timeAllocation: {
    create: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.clientContacts.timeAllocation.add}`,
        data
      );
    },
    getList: (id: any) => {
      // return instance.get<any>(
      //   `${ApiConstants.clientContacts.timeAllocation.list}/${id}`
      // );
      return instance.get<any>(
        `${ApiConstants.clientContacts.timeAllocation.list}/${id}`
      );
    },
    update: (id: any, timeallocation_id: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.clientContacts.timeAllocation.update}/${id}/timeallocation/${timeallocation_id}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.clientContacts.timeAllocation.update}/${id}/timeallocation/${timeallocation_id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.clientContacts.timeAllocation.delete}/${id}`
      );
    },
  },
  clientAction: {
    create: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.clientContacts.clientAction.add}`,
        data
      );
    },
    getList: (id: any) => {
      return instance.get<any>(
        `${ApiConstants.clientContacts.clientAction.list}/${id}`
      );
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.clientContacts.clientAction.update}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.clientContacts.clientAction.delete}/${id}`
      );
    },
  },
  actionList: {
    listItems: (
      userId: any,
      optionDate?: any,
      category?: any,
      optionConsutant?: any,
      assigned?: any,
      page?: number,
      search?: string,
      limit?: number
    ) => {
      let apiUrl = `${ApiConstants.actionList.listItems}/${userId}?page=${page}`;
      if (limit) {
        apiUrl += `&limit=${limit}`;
      }
      if (optionDate) {
        apiUrl += `&dateCondition=${optionDate}`;
      }
      if (category) {
        let cat = category.length > 0 ? category.join(",") : "";
        apiUrl += `&category=${cat}`;
      }
      if (optionConsutant) {
        let consult =
          optionConsutant.length > 0 ? optionConsutant.join(",") : "";
        apiUrl += `&consultant=${consult}`;
      }
      if (assigned) {
        let assign = assigned.length > 0 ? assigned.join(",") : "";
        apiUrl += `&assignee=${assign}`;
      }
      if (search) {
        apiUrl += `&search=${search}`;
      }
      return instance.get(apiUrl);
    },
    getList: (
      userId: any,
      optionDate?: any,
      category?: any,
      optionConsutant?: any,
      assigned?: any,
      page?: number,
      search?: string,
      limit?: number
    ) => {
      let apiUrl = `${ApiConstants.actionList.listItems}/${userId}?page=${page}`;
      if (limit) {
        apiUrl += `&limit=${limit}`;
      }
      if (optionDate) {
        apiUrl += `&dateCondition=${optionDate}`;
      }
      if (category) {
        let cat = category.length > 0 ? category.join(",") : "";
        apiUrl += `&category=${cat}`;
      }
      if (optionConsutant) {
        let consult =
          optionConsutant.length > 0 ? optionConsutant.join(",") : "";
        apiUrl += `&consultant=${consult}`;
      }
      if (assigned) {
        let assign = assigned.length > 0 ? assigned.join(",") : "";
        apiUrl += `&assignee=${assign}`;
      }
      if (search) {
        apiUrl += `&search=${search}`;
      }
      return instance.get(apiUrl);
    },
    getConsultant: (userId: any) => {
      return instance.get<any>(
        `${ApiConstants.actionList.getConsultant}/${userId}`
      );
    },
    getAssignee: (userId: any) => {
      return instance.get<any>(
        `${ApiConstants.actionList.getAssignee}/${userId}`
      );
    },
  },
  clientHistory: {
    getClientHistory: (userId: any) => {
      return instance.get<any>(`${ApiConstants.contactHistory.list}/${userId}`);
    },
  },
  clientActionList: {
    getClientActionList: () => {
      return instance.get<any>(`${ApiConstants.clientActionList.list}`);
    },
  },
  caseActionList: {
    getCaseActionList: () => {
      return instance.get<any>(`${ApiConstants.caseActionList.list}`);
    },
  },
  // document: {
  //   getDocument: (id: any) => {
  //     return instance.get<any>(`${ApiConstants.document}/${id}`);
  //   },
  // },
  document: {
    getDocument: (id: string, customer_id: string) => {
      return instance.get<any>(
        `${ApiConstants.document}/${id}/customer/${customer_id}`
      );
    },
  },
  clientDocument: {
    getAllList: (customer_id: any) => {
      return instance.get<any>(
        `${ApiConstants.clientDocument.getList}?customerid=${customer_id}`
      );
    },
    addFolder: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.clientDocument.addFolder}`,
        data
      );
    },
    addDocument: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.clientDocument.addDocument}`,
        data
      );
    },
  },

  notes: {
    create: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.clientContacts.notes.add}`,
        data
      );
    },
    getList: (id: any) => {
      return instance.get<any>(
        `${ApiConstants.clientContacts.notes.list}/${id}`
      );
    },
    updateNotes: (noteId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.clientContacts.notes.update}/notes/${noteId}`,
        data
      );
    },
    update: (clientId: any, noteId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.clientContacts.notes.update}/${clientId}/note/${noteId}`,
        data
      );
    },
    getById: (noteId: string, customerId: string) => {
      return instance.get<any>(
        `${ApiConstants.clientContacts.notes.getById}/${noteId}/customer/${customerId}`
      );
    },
    getRecent: (id: any) => {
      return instance.get<any>(
        `${ApiConstants.clientContacts.notes.getRecent}/${id}`
      );
    },
  },
  factFindNote: {
    createNew: (data: any) => {
      return instance.post<any>(`${ApiConstants.FactFind.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(
        `${ApiConstants.FactFind.list}/${id}?type=fact-find`
      );
    },
    updateFactFindNote: (noteId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.clientContacts.notes.update}/${clientId}/note/${noteId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.FactFind.update}/notes/${noteId}`,
        data
      );
    },
    update: (clientId: any, noteId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.FactFind.update}/${clientId}/note/${noteId}`,
        data
      );
    },
    delete: (id: any, customerId: string, isforce?: boolean) => {
      return instance.delete<any>(
        `${ApiConstants.FactFind.delete}/${id}/${customerId}`
      );
    },
  },
  caseAction: {
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.caseAction.list}/${id}`);
    },
  },
  caseSummaryAsset: {
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.caseSummaryAsset.list}/${id}`);
    },
  },
  caseSummaryLiability: {
    getList: (id: any) => {
      return instance.get<any>(
        `${ApiConstants.caseSummaryLiability.list}/${id}`
      );
    },
  },
  caseSummaryList: {
    getCaseSummary: (userId: any, show: any) => {
      return instance.get<any>(
        `${ApiConstants.caseSummaryList.list}/${userId}?show=${show}`
      );
    },
  },
  attitudeToRisk: {
    create: (data: any) => {
      return instance.post<any>(`${ApiConstants.attitudeToRisk.add}`, data);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.attitudeToRisk.list}/${id}`);
    },
    update: (customerId: any, id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.attitudeToRisk.update}/${customerId}/attituderisk/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.attitudeToRisk.delete}/${id}`
      );
    },
  },
  contactClientAction: {
    createNew: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.contactClientAction.add}`,
        data
      );
    },
    getList: (id: any, date?: any) => {
      return date !== undefined
        ? instance.get<any>(
            `${ApiConstants.contactClientAction.list}/${id}?date=${date}`
          )
        : instance.get<any>(`${ApiConstants.contactClientAction.list}/${id}`);
    },
    updateFactFindNote: (costumerId: any, appointmentId: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.contactClientAction.update}/${appointmentId}`,
        data
      );
    },
    getById: (id: string, customerId: string) => {
      return instance.get<any>(
        `${ApiConstants.contactClientAction.detail}/${id}/customer/${customerId}`
      );
    },
  },
  feesCharges: {
    create: (data: any) => {
      // return instance.post<any>(`${ApiConstants.feesCharges.add}`, data);
      return instance.post<any>(`${ApiConstants.feesCharges.add}`, data);
    },
    getList: (id: any) => {
      // return instance.get<any>(`${ApiConstants.feesCharges.list}/${id}`);
      return instance.get<any>(`${ApiConstants.feesCharges.list}/${id}`);
    },
    update: (costumerId: any, commissionId: any, data: any) => {
      // return instance.patch<any>(
      //   `${ApiConstants.feesCharges.update}/${costumerId}/commission/${commissionId}`,
      //   data
      // );
      return instance.patch<any>(
        `${ApiConstants.feesCharges.update}/${costumerId}/commission/${commissionId}`,
        data
      );
    },
    // update: ( commissionId: any, data: any) => {
    //   return instance.patch<any>(
    //     `${ApiConstants.feesCharges.update}/${id}`,
    //     data
    //   );
    // },
  },
  fund: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.fund.add}`, data);
    },
    getList: (customerId: any, id: any) => {
      return instance.get<any>(
        `${ApiConstants.fund.list}/customer/${customerId}/case/${id}`
      );
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(`${ApiConstants.fund.update}/${id}`, data);
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.fund.delete}/${id}`);
    },
    getDetail: (customerId: any, id: any) => {
      return instance.get<any>(
        `${ApiConstants.fund.detail}/${id}/customer/${customerId}`
      );
    },
  },
  Payments: {
    addPayment: (data: any) => {
      return instance.post<any>(`${ApiConstants.Payments.addPayment}`, data);
    },
    getPayments: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.Payments.getPayments}/${id}/customer/${customerId}`
      );
    },
    updatePayment: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.Payments.updatePayment}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(
        `${ApiConstants.Payments.deletePayment}/${id}`
      );
    },
  },
  Withdrawals: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.Withdrawals.add}`, data);
    },
    getList: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.Withdrawals.list}/${id}/customer/${customerId}`
      );
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.Withdrawals.update}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.Withdrawals.delete}/${id}`);
    },
  },
  Actions: {
    getActions: (id: any, customerId: any) => {
      return instance.get<any>(
        `${ApiConstants.Actions.getActions}/customer/${customerId}/case/${id}`
      );
    },
    addActions: (data: any) => {
      return instance.post<any>(`${ApiConstants.Actions.addActions}`, data);
    },

    updateActions: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.Actions.updateActions}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.Actions.delete}/${id}`);
    },
  },
  valuation: {
    getvaluation: (id: any, costumerId: any) => {
      return instance.get<any>(
        `${ApiConstants.valuation.getvaluation}/${id}/customer/${costumerId}`
      );
    },
    addvaluation: (data: any) => {
      return instance.post<any>(`${ApiConstants.valuation.addvaluation}`, data);
    },

    updatevaluation: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.valuation.updatevaluation}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.valuation.delete}/${id}`);
    },
  },
  notification: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.notification.add}`, data);
    },
    getList: () => {
      return instance.get<any>(`${ApiConstants.notification.list}`);
    },
    clientList: (
      page_number: number = 1,
      limit: number = 100,
      search?: string
    ) => {
      let apiUrl = `${ApiConstants.notification.clientList}?limit=${limit}&page_number=${page_number}`;

      if (search) {
        apiUrl += `&search=${search}`;
      }
      return instance.get<any>(apiUrl);
    },
    // createContent: (data: any) => {
    //   return instance.post<any>(
    //     `${ApiConstants.notification.createContent}`,
    //     data
    //   );
    // },
    createContent: (template_id: string, customer_id: string) => {
      return instance.get<any>(
        `${ApiConstants.notification.createContent}/get-template/${template_id}/customer/${customer_id}`
      );
    },
    sendEmail: (data: any) => {
      return instance.post<any>(`${ApiConstants.notification.sendEmail}`, data);
    },
    sendBulkEmail: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.notification.sendBulkEmail}`,
        data
      );
    },
    getPreview: (customer: any) => {
      return instance.get<any>(
        `${ApiConstants.notification.getPreview}?customers_ids=${customer}`
      );
    },
  },
  exportToExcel: {
    get: (customerId: any) => {
      return instance.get<any>(`${ApiConstants.exportToExcel}${customerId}`, {
        responseType: "blob",
      });
    },
  },
  getDefaultAttitudeToRisk: {
    add: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.getDefaultAttitudeToRisk.add}`,
        data
      );
    },
  },
  getDefaultIncome: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.getDefaultIncome.add}`, data);
    },
  },
  getDefaultexpense: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.getDefaultexpense.add}`, data);
    },
  },
  getDefaultobjective: {
    add: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.getDefaultobjective.add}`,
        data
      );
    },
  },
  userProfile: {
    getDetail: (userId: any) => {
      let link = `${ApiConstants.userProfile.getDetail}/${userId}`;
      return instance.get<any>(link);
    },
    updateDetail: (userId: any, data: any) => {
      let link = `${ApiConstants.userProfile.updateDetail}/${userId}`;
      return instance.patch<any>(link, data);
    },
  },
  tackingActions: {
    getList: () => {
      return instance.get<any>(`${ApiConstants.getTrackingAction.list}`);
    },
    getClient: () => {
      return instance.get<any>(`${ApiConstants.getTrackingAction.listClient}`);
    },
    addStandardActions: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.loadStandardActions.add}`,
        data
      );
    },
    addclientAction: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.loadStandardActions.addclientAction}`,
        data
      );
    },
  },
  systemManager: {
    incomeCategory: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.incomeCategory.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.incomeCategory.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.incomeCategory.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.incomeCategory.delete}/${id}`
        );
      },
    },
    commissionType: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.commissionType.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.commissionType.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.commissionType.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.commissionType.delete}/${id}`
        );
      },
    },
    commissionRule: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.commissionRule.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.commissionRule.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.commissionRule.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.commissionRule.delete}/${id}`
        );
      },
    },
    expenseCategory: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.expenseCategory.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.expenseCategory.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.expenseCategory.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.expenseCategory.delete}/${id}`
        );
      },
    },
    attitudeToRiskcategory: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.attitudeToRiskcategory.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.attitudeToRiskcategory.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.attitudeToRiskcategory.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.attitudeToRiskcategory.delete}/${id}`
        );
      },
    },
    attitudeToRiskRating: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.attitudeToRiskRating.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.attitudeToRiskRating.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.attitudeToRiskRating.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.attitudeToRiskRating.delete}/${id}`
        );
      },
    },
    objectives: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.objectives.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.objectives.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.objectives.update}/${id}`,
          data
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.objectives.delete}/${id}`
        );
      },
    },
    providers: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.providers.add}`,
          data
        );
      },
      getList: (page?: number, limit?: number, search?: string) => {
        const params: Record<string, string | number> = {};
        if (page !== undefined) params.page = page;
        if (limit !== undefined) params.limit = limit;
        if (search) params.search = search;
        return instance.get<any>(
          `${ApiConstants.systemManager.providers.list}`,
          {
            params,
          }
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.providers.update}/${id}`,
          data
        );
      },
      getProvider: (id: any) => {
        return instance.get<any>(
          `${ApiConstants.systemManager.providers.providerList}/${id}`
        );
      },
      delete: (id: any, data: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.providers.delete}/${id}`
        );
      },
    },
    userDefined: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.userDefined.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.userDefined.list}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.userDefined.update}/${id}`,
          data
        );
      },
      delete: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.userDefined.delete}/${id}`
        );
      },
    },
    loadTrackingAction: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.loadTrackingAction.add}`,
          data
        );
      },
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.loadTrackingAction.list}`
        );
      },
      getClient: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.loadTrackingAction.listClient}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.systemManager.loadTrackingAction.update}/${id}`,
          data
        );
      },
      getCaseAction: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.loadTrackingAction.listCaseAction}`
        );
      },
      delete: (id: any) => {
        return instance.delete<any>(
          `${ApiConstants.systemManager.loadTrackingAction.delete}/${id}`
        );
      },
    },
    manageUsers: {
      getList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.manageUsers.getUsers}`
        );
      },
      getuserList: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.manageUsers.getUserlist}`
        );
      },
      getPermissions: () => {
        return instance.get<any>(
          `${ApiConstants.systemManager.manageUsers.getPermissions}`
        );
      },
      createUser: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.systemManager.manageUsers.createUser}`,
          data
        );
      },
      updateUser: (userId: any, data: any) => {
        let link =
          `${ApiConstants.systemManager.manageUsers.updateUser}`.replace(
            ":user_id",
            userId
          );
        // let link = `${ApiConstants.systemManager.manageUsers.updateUser}`;
        return instance.patch<any>(link, data);
      },
      getDetail: (id: any) => {
        let link =
          `${ApiConstants.systemManager.manageUsers.getDetails}`.replace(
            ":user_id",
            id
          );
        return instance.get<any>(link);
      },
      deleteUser: (userId: any) => {
        let link =
          `${ApiConstants.systemManager.manageUsers.deleteUser}`.replace(
            ":user_id",
            userId
          );
        // let link = `${ApiConstants.systemManager.manageUsers.updateUser}`;
        return instance.delete<any>(link);
      },
    },
  },
  report: {
    getReport: (formatType: string, context: string, limit: number) => {
      return instance.get(
        `${ApiConstants.report.list}?formatType=${formatType}&context=${context}&limit=${limit}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportFactFindSummary: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/factfind/summary/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportFactFindStandard: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/factfind/standard/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioNetworth: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/networth/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioPotrait: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/portrait/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioMain: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/main/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioAnnualGrowth: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/annualgrowth/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioPerformance: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/performance/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
    getReportPortfolioReturns: (
      formatType: string,
      context: string,
      customerId: string
    ) => {
      return instance.get(
        `${ApiConstants.report.list}/portfolio/returns/${customerId}?formatType=${formatType}&context=${context}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
    },
  },

  selectionList: {
    getOptions: (field: any) => {
      let link = `${ApiConstants.selectionList.getOptions}?fields=${field}`;

      return instance.get<any>(link);
    },
    addOptions: (data: any) => {
      return instance.post<any>(
        `${ApiConstants.selectionList.addOptions}`,
        data
      );
    },
  },
  commissionModule: {
    commissionClient: {
      add: (data: any) => {
        return instance.post<any>(
          `${ApiConstants.commissionModule.commissionClient.add}`,
          data
        );
      },
      getList: (id: any) => {
        return instance.get<any>(
          `${ApiConstants.commissionModule.commissionClient.list}/${id}`
        );
      },
      update: (id: any, data: any) => {
        return instance.patch<any>(
          `${ApiConstants.commissionModule.commissionClient.update}/${id}`,
          data
        );
      },
    },
    receipting: {
      getList: (
        userId?: any,
        optionDate?: any,
        provider?: any,
        page?: number,
        limit?: number
      ) => {
        let apiUrl = `${ApiConstants.commissionModule.receipting.list}/c905c3c0-d8f1-46f7-86ff-0aee64799621?page=1?limit=10&dateCondition=includeAllDates&provider=Provider1`;
        // if (limit) {
        //   apiUrl += `&limit=${limit}`;
        // }
        // if (optionDate) {
        //   apiUrl += `&dateCondition=${optionDate}`;
        // }

        // if (provider) {
        //   let assign = provider.length > 0 ? provider.join(",") : "";
        //   apiUrl += `&provider=${assign}`;
        // }

        return instance.get(apiUrl);
      },
    },
  },

  extract: {
    getList: () => {
      return instance.get<any>(`${ApiConstants.extract.getList}`);
    },
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.extract.add}`, data);
    },
    getExtract: () => {
      return instance.get<any>(`${ApiConstants.extract.getExtract}`);
    },
    update: (id: any, data: any) => {
      return instance.patch<any>(
        `${ApiConstants.extract.getExtract}/${id}`,
        data
      );
    },
    delete: (id: any) => {
      return instance.delete<any>(`${ApiConstants.extract.getExtract}/${id}`);
    },
    getClientList: (tableName: string, columnName: string) => {
      let link = `${ApiConstants.extract.getClientList}?tableName=${tableName}&columnName=${columnName}`;
      return instance.get<any>(link);
    },
  },
  getReport: {
    getList: () => {
      return instance.get<any>(`${ApiConstants.reportList.list}`);
    },
  },

  credentials: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.credentials.add}`, data);
    },
    getList: () => {
      return instance.get<any>(`${ApiConstants.credentials.list}`);
    },
  },
  emailCredentials: {
    add: (data: any) => {
      return instance.post<any>(`${ApiConstants.emailCredentials.add}`, data);
    },
    // getList: () => {
    //   return instance.get<any>(`${ApiConstants.credentials.list}`);
    // },
  },
  exportExcel: {
    getFields: () => {
      return instance.get<any>(`${ApiConstants.export.fieldList}`);
    },
    getList: () => {
      return instance.get<any>(`${ApiConstants.export.getListing}`);
    },
    addList: (data: any) => {
      return instance.post<any>(`${ApiConstants.export.addList}`, data);
    },
    updateList: (data: any, id: any) => {
      return instance.patch<any>(`${ApiConstants.export.addList}/${id}`, data);
    },
    deleteList: (id: any) => {
      return instance.delete<any>(`${ApiConstants.export.deleteListing}/${id}`);
    },
    clientList: (data: string | string[]) => {
      const customerIds = Array.isArray(data) ? data : [data];

      const formattedIds = customerIds.map((id) => `"${id}"`).join(",");

      const url = `${ApiConstants.export.clientList}/customers?customers_ids=[${formattedIds}]`;

      return instance.get<any>(url);
    },
  },
  ClientPartnerSplit: {
    getClientDetail: (id: any) => {
      return instance.get<any>(`${ApiConstants.client.detail}/${id}`);
    },
    getList: (id: any) => {
      return instance.get<any>(`${ApiConstants.split.list}/${id}`);
    },
    splitCP: (id: any, data: any) => {
      return instance.post<any>(`${ApiConstants.split.splitData}/${id}`, data);
    },
  },
  TemplateEngine: {
    templateEmailUpload: (data: {
      description: string;
      title: string;
      files: FormData;
    }) => {
      const queryParams = `scope=custom&type=Email&Description=${encodeURIComponent(
        data.description
      )}&title=${encodeURIComponent(data.title)}`;
      return instance.post<any>(
        `${ApiConstants.templates.upload}?${queryParams}`,
        data.files
      );
    },
    templateLetterUpload: (data: {
      description: string;
      title: string;
      files: FormData;
    }) => {
      const queryParams = `scope=custom&type=Letter&Description=${encodeURIComponent(
        data.description
      )}&title=${encodeURIComponent(data.title)}`;
      return instance.post<any>(
        `${ApiConstants.templates.upload}?${queryParams}`,
        data.files
      );
    },
    updateTemplateLetter: (data: {
      description: string;
      title: string;
      files: FormData;
    }) => {
      const queryParams = `template?description=${encodeURIComponent(
        data.description
      )}&title=${encodeURIComponent(data.title)}`;
      return instance.patch<any>(
        `${ApiConstants.templates.edit}${queryParams}`,
        data.files
      );
    },

    generateTemplate: (scope: any, type: any, customer_id: any) => {
      return instance.post<any>(
        `${ApiConstants.templates.template}/${type}/${scope}`,
        customer_id
      );
    },
    getTemplateList: () => {
      return instance.get<any>(`${ApiConstants.templates.list}`);
    },
    getLetterTemplate: () => {
      return instance.get<any>(`${ApiConstants.templates.letterList}`);
    },
    getEmailTemplate: () => {
      return instance.get<any>(`${ApiConstants.templates.emailList}`);
    },
    deleteLetterTemplate: (deleteId: any) => {
      return instance.delete<any>(
        `${ApiConstants.templates.deleteLetter}/delete/template/Letter/${deleteId}`
      );
    },
    deleteEmailTemplate: (deleteId: any) => {
      return instance.delete<any>(
        `${ApiConstants.templates.deleteEmail}/delete/template/Email/${deleteId}`
      );
    },
    generateLetterTemplate: (data: any, datas: any) => {
      return instance.post<any>(
        `${ApiConstants.templates.letterGeneration}/${datas}`,
        data
      );
    },
    generateEmailTemplate: (data: any, datas: any) => {
      return instance.post<any>(
        `${ApiConstants.templates.letterGeneration}/${datas}`,
        data
      );
    },
    getSampleLetter: () => {
      return instance.get<any>(`${ApiConstants.templates.sampleLetter}`);
    },
  },
};

export default apiServices;
