import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import React from "react";

function ConfirmationPopup(props: any) {
  return (
    <Dialog open={props.confirmationPopup}>
      <DialogSurface>
        <DialogBody style={{ display: "flex", flexDirection: "column" }}>
          <h4>
            Don't forget to change any allocation amounts that are attached to
            this commission entry
          </h4>
          <DialogActions>
            <div className="button-end-popup">
              <Button
                appearance="secondary"
                onClick={() => {
                  props.setReceiptEditpopup(true);
                  props.setConfirmationPopupPopup(false);
                }}
              >
                OK
              </Button>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default ConfirmationPopup;
