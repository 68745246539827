import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  makeStyles,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import React, { useState } from "react";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
  },
  widthcontent: {
    width: "450px",
  },
});
const SavePopup = (props: any) => {
  const styles = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const onSelectDate = (date: any) => {
    setSelectedDate(date);
  };
  return (
    <div>
      <Dialog open={props.confirmsavepopup}>
        <DialogSurface className={styles.widthcontent}>
          <DialogBody className={styles.content}>
            <div>
              <h4>Commission Receipt Date</h4>
            </div>
            <p>180.00 receipted</p>
            <p>180.00 targeted for receipt</p>
            <p>Amounts Matched</p>
            <p>Do you want to save the receipt(s) ? (Yes/No)</p>
            <div style={{ display: "flex" }}>
              <p>Receipt Data : </p>
              <DatePicker
                as="input"
                style={{ marginLeft: "10px" }}
                placeholder=""
                allowTextInput
                value={selectedDate}
                onSelectDate={onSelectDate}
                name={"review_completed"}
              />
            </div>
          </DialogBody>
          <DialogActions>
            <div className="button-end-popup">
              <Button
                className="asc-button-primary"
                appearance="primary"
                type="submit"
                size="small"
                style={{ marginRight: 6 }}
              >
                Save
              </Button>
              <Button
                appearance="secondary"
                shape="square"
                type="submit"
                size="small"
                onClick={() => props.setConfirmsavepopup(false)}
              >
                No
              </Button>
            </div>
          </DialogActions>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default SavePopup;
