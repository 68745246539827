import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  Dropdown,
  Field,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import ClientSelection from "./Clients";
import apiServices from "../../../service";
import { ChevronDownRegular } from "@fluentui/react-icons";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import OverlayLoader from "../../../components/OverlayLoader";
import store from "../../../redux/store";
import axios from "axios";
type selectedValue = {
  customer_id: string;
  first_names: string;
  last_name: string;
};
const LetterGeneration = () => {
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [clients, setClients] = useState<selectedValue[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<selectedValue[]>([]);
  const [templateListing, setTemplateListing] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>("");
  const [openSampleTemplate, setOpenSampleTemplate] = useState<boolean>(false);
  const [selectedTemplateCode, setSelectedTemplateCode] = useState<any>("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [sampleTemplate, setSampleTemplate] = useState<any>([]);



  const getLetterList = () => {
    apiServices.TemplateEngine.getLetterTemplate().then((res) => {
      const namesAndPaths = res.data.map((item: any) => ({
        title: item.title,
        code: item.code,
      }));
      setTemplateListing(namesAndPaths);
    });
  };

  useEffect(() => {
    getLetterList();
  }, []);
  const handleTemplateClick = (title: string, code: string) => {

    setSelectedTemplate(title);
    setSelectedTemplateCode(code);
  };
  const sampleTemplates = async () => {
    setLoading(true);
    try {
      const res = await apiServices.TemplateEngine.getSampleLetter();
      setSampleTemplate(res.data);
    } catch (error) {
      console.error("Error fetching the template list:", error);
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   sampleTemplates();
  // }, []);
  const renderMenuItems = templateListing.map((item: any) => (
    <MenuItem
      key={item.title}
      onClick={() => handleTemplateClick(item.title, item.code)}
    >
      {item.title}
    </MenuItem>
  ));

  const handleClientSelection = (selectedIds: string[]) => {
    const selectedClients = clients.filter((client: any) =>
      selectedIds.includes(client?.customer_id)
    );

    if (selectedClients.length > 0) {
      setSelectedOptions(selectedClients.map((client) => client?.customer_id));
      setSelectedClient(selectedClients);
    }
  };
  // const generateLetter = async () => {
  //   try {
  //     const requestData = {
  //       customerIds: selectedOptions,
  //     };

  //     console.log(requestData, "Request Data");
  //     console.log(selectedTemplateCode, "Selected Template Code");

  //     setLoading(true);

  //     const response = await apiServices.TemplateEngine.generateLetterTemplate(
  //       requestData,
  //       selectedTemplateCode
  //     );

  //     console.log(response, "API Response");
  //   } catch (error) {
  //     console.error("Error generating letter:", error);
  //   } finally {
  //     setSelectedOptions([]);
  //     setSelectedTemplateCode("");

  //     setLoading(false);
  //   }
  // };
  let timestamp: any = new Date();
  timestamp = timestamp.toISOString();
  const generateNonce = () => {
    const CryptoJS = require("crypto-js");
    const requestData = {};
    const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
    return hash.toString(CryptoJS.enc.Hex);
  };
  const nonce = generateNonce();

  const generateLetter = async () => {
    try {
      setLoading(true);

      const requestData = {
        customerIds: selectedOptions,
      };

      const token = store.getState().authUser.userToken;
      const userId = store.getState().authUser.userId;
      const refreshToken = store.getState().authUser.refreshToken;

      const fileExtension = "zip";

      const url = `${process.env.REACT_APP_BASE_URL}report/new/custom/${selectedTemplateCode}`;

      const response = await axios.post(url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Nonce": nonce,
          "X-Timestamp": timestamp,
          UserId: userId,
          RefreshToken: refreshToken,
        },
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = fileUrl;

      link.setAttribute("download", `${selectedTemplateCode}.${fileExtension}`);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating the letter:", error);
    } finally {
      setLoading(false);
    }
  };

  const columnsData: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "title",
      header: "Title",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "signedUrl",
      header: "Link",
      Cell: ({ row }: any) => (
        <>
          <a href={row.original.signedUrl} style={{ textDecoration: "none" }}>
            {row.original.title} - {row.original.templateFilePath}
          </a>{" "}
        </>
      ),
    },
  ];

  const handleRowSelection = (row: any) => {
    setSelectedRow(row.original);
  };
  const getClientPlaceholder = () => {
    if (selectedClient.length === 0) {
      return "Select a Client / Multiple Clients";
    } else if (selectedClient.length === 1) {
      return `${selectedClient[0].first_names} ${selectedClient[0].last_name}`;
    } else if (selectedClient.length === 2) {
      return `${selectedClient[0].first_names} ${selectedClient[0].last_name}, ${selectedClient[1].first_names} ${selectedClient[1].last_name}`;
    } else {
      return `${selectedClient[0].first_names}, ${
        selectedClient[1].first_names
      } + ${selectedClient.length - 2} others`;
    }
  };

  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <div className="main-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Field label="Select Template :">
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Button
                    style={{
                      fontWeight: "normal",
                      width: "350px",
                      color: "#606060",
                      border: "1px solid grey",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    <span style={{ flexGrow: 1, textAlign: "left" }}>
                      {selectedTemplate || "Select Template"}
                    </span>
                    <ChevronDownRegular />
                  </Button>
                </MenuTrigger>
                <MenuPopover
                  style={{
                    minWidth: "350px",
                    minHeight: "400px",
                    maxHeight: "400px",
                    overflowX: "hidden",
                    overflowY:  "scroll",
                  }}
                >
                  <MenuList
                    style={{
                      minWidth: "350px",
                      minHeight: "400px",
                      maxHeight: "400px",

                    }}
                  >
                    {renderMenuItems}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </Field>
          </div>
          <div>
            <Button
              disabled={
                !(
                  selectedOptions?.length > 0 &&
                  selectedTemplateCode?.length > 0
                )
              }
              appearance="primary"
              onClick={generateLetter}
            >
              Generate
            </Button>
          </div>
        </div>
        <div>
          <Field label="Select Client (s) :">
            <Dropdown
              style={{ width: "350px" }}
              placeholder={getClientPlaceholder()}
              onClick={() => setShowClientSelection(true)}
            ></Dropdown>
          </Field>
        </div>
      </div>
      <Dialog
        modalType="alert"
        open={showClientSelection}
        onOpenChange={(event, data) => setShowClientSelection(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <span className="mui-table">Client Selection</span>
            <DialogContent>
              <ClientSelection
                onSelect={handleClientSelection}
                handleClientSelection={handleClientSelection}
                setClientList={setClients}
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                appearance="secondary"
                onClick={() => {
                  setShowClientSelection(false);
                  setSelectedClient([]);
                  setSelectedOptions([]);
                }}
              >
                Close
              </Button>
              <Button
                className="asc-button-primary"
                appearance="primary"
                onClick={() => setShowClientSelection(false)}
                size="small"
              >
                Select
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <Dialog modalType="alert" open={openSampleTemplate}>
        <DialogSurface>
          <DialogBody>
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              Sample Templates{" "}
            </span>
            <DialogContent>
              <MaterialReactTable
                columns={columnsData}
                data={sampleTemplate}
                // onRowSelectionChange={setRowSelection}
                // onPaginationChange={setPagination}
                // rowCount={rowCount}
                manualPagination
                // state={{ pagination, rowSelection }}
                enableGlobalFilter={true}
                enableColumnActions={false}
                enableColumnFilters={true}
                enableRowSelection
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => handleRowSelection(row),
                })}
                muiTableBodyCellProps={({ column }) => ({
                  style: {
                    padding: "6px 12px",
                    fontSize: "0.875rem",
                  },
                })}
                enableMultiRowSelection={false}
                enableFullScreenToggle={false}
                muiTablePaginationProps={{
                  showFirstButton: false,
                  showLastButton: false,
                }}
                onGlobalFilterChange={(text: string) => {
                  if (text) {
                    setSearchText(text);
                  } else {
                    setSearchText("");
                  }
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={() => {
                  setOpenSampleTemplate(false);
                  setSelectedRow(null);
                }}
              >
                Close
              </Button>
              {/* <Button
                size="small"
                appearance="primary"
                onClick={() => {
                  setOpenSampleTemplate(false);
                }}
              >
                Save
              </Button> */}
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default LetterGeneration;
