import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import CaseList from "./CaseList";
import CaseNavList from "./CaseNavList";
import CaseForm from "./CaseForm";
import apiServices from "../../../service";
import { getCaseCategoryByCaseType } from "../../../utils";
import {
  assetsSlice,
  getAssetDetailById,
  getAssetListItems,
} from "../../../redux/Case/assetSlice";
import { TableListLoader } from "../../../components/Loader/TableListLoader";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { FormLoader } from "../../../components/Loader/FormLoader";

import { reloadData, reloadDetails } from "../../../redux/appSettingsSlice";
import {
  getLiabilityDetailById,
  getLiabilityListItems,
} from "../../../redux/Case/liabilitySlice";
import {
  getPolicyDetailById,
  getPolicyListItems,
} from "../../../redux/Case/policySlice";
function CaseDetailPage(props: any) {
  console.log("props in case detail page", props);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const assets = useSelector((state: RootState) => state.assets);
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const policies = useSelector((state: RootState) => state.policies);
  const [loading, setLoading] = useState(false);
  const { clientId, action, caseId } = useParams();
  const [caseItemList, setCaseItemList] = useState<any>([]);
  const [caseDetail, setCaseDetail] = useState<any>({});
  const [caseType, setCaseType] = useState<string>("");
  const [listLoading, setListLoading] = useState(true);
  const [detailLoading, setDetailLoading] = useState(true);

  const isReloadDataList = useSelector(
    (state: RootState) => state.appSettings.reloadDataList
  );
  const isReloadDetails = useSelector(
    (state: RootState) => state.appSettings.reloadDetails
  );

  const formloading =
    (
      {
        assets: assets?.activeItemloading,
        liabilities: liabilities?.activeItemloading,
        policies: policies?.activeItemloading,
      } as Record<string, boolean | undefined>
    )[props.section] || false;

  const listloading =
    (
      {
        assets: assets?.loading,
        liabilities: liabilities?.loading,
        policies: policies?.loading,
      } as Record<string, boolean | undefined>
    )[props.section] || false;

  useEffect(() => {
    if (isReloadDataList === true && clientId != undefined) {
      fetchCaseLists();
    }
  }, [isReloadDataList, dispatch]);
  useEffect(() => {
    if (isReloadDetails === true && caseId != undefined) {
      fetchCaseDetail(caseId);
    }
  }, [isReloadDetails, dispatch]);
  useEffect(() => {
    if (location.state?.caseType) {
      setCaseType(location.state.caseType);
    }
  }, [location]);
  useEffect(() => {
    if (clientId != undefined) {
      fetchCaseLists();
    }
  }, [clientId]);
  useEffect(() => {
    if (caseId != undefined) {
      fetchCaseDetail(caseId);
    }
  }, [caseId]);
  useEffect(() => {
    if (props.section) {
      if (props.section == "assets" && assets) {
        setCaseItemList(assets.listItems);
        setCaseDetail(assets.activeItem);
      } else if (props.section == "liabilities" && liabilities) {
        setCaseItemList(liabilities.listItems);
        setCaseDetail(liabilities.activeItem);
      } else if (props.section == "policies" && policies) {
        setCaseItemList(policies.listItems);
        setCaseDetail(policies.activeItem);
      }
    }
  }, [assets, liabilities, policies, dispatch, props.section]);
  useEffect(() => {
    if (Object.keys(caseDetail).length !== 0) {
      setCaseType(getCaseCategoryByCaseType(caseDetail["case_type"]));
    }
  }, [caseDetail]);

  const fetchCaseDetail = (id: string) => {
    if (props.section == "assets" && clientId) {
      dispatch(getAssetDetailById({ id, customerId: clientId }));
      // apiServices.assets
      //   .getDetail(id, clientId)
      //   .then((response) => {
      //     setLoading(false);
      //     console.log("asset detail", response);
      //     if (response.data) {
      //       if (response.data.data) {
      //         setCaseDetail(response.data.data);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     console.log("err");
      //   });
    } else if (props.section == "liabilities" && clientId) {
      dispatch(getLiabilityDetailById({ id, customerId: clientId }));
      // apiServices.liability
      //   .getDetail(id, clientId)
      //   .then((response) => {
      //     setLoading(false);
      //     setDetailLoading(false);
      //     dispatch(reloadDetails(false));
      //     // console.log("liability detail", response);
      //     if (response.data) {
      //       if (response.data.data) {
      //         setCaseDetail(response.data.data);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setDetailLoading(false);
      //     dispatch(reloadDetails(false));
      //     console.log("err");
      //   });
    } else if (props.section == "policies" && clientId) {
      dispatch(getPolicyDetailById({ id, customerId: clientId }));
      // apiServices.policy
      //   .getDetail(id, clientId)
      //   .then((response) => {
      //     setLoading(false);
      //     setDetailLoading(false);
      //     dispatch(reloadDetails(false));
      //     // console.log("liability detail", response);
      //     if (response.data) {
      //       if (response.data.data) {
      //         setCaseDetail(response.data.data);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setDetailLoading(false);
      //     dispatch(reloadDetails(false));
      //     console.log("err");
      //   });
    }
  };
  const fetchCaseLists = () => {
    const fetchActions: any = {
      assets: getAssetListItems,
      liabilities: getLiabilityListItems,
      policies: getPolicyListItems,
    };

    if (clientId && fetchActions[props.section]) {
      const action = fetchActions[props.section];
      dispatch(action(clientId));
    }
  };
  return (
    <div
      style={{
        height: "calc(100vh - 50px)",
        overflow: "auto",
        marginBottom: "12px",
        width: "100%",
      }}
    >
      {props.section && action && (
        <>
          <CaseNavList
            {...{
              menuItem: props.subItem,
              section: props.section,
              tab: props.tab,
              caseType: caseType,
              caseDetail: caseDetail,
              mode: action ? action : "",
            }}
          />
          {formloading ? (
            <FormLoader />
          ) : (
            <CaseForm
              mode={action ? action : ""}
              section={props.section ? props.section : ""}
              caseType={caseType}
              caseDetail={caseDetail}
              caseItemList={caseItemList}
              tab={props.tab}
            />
          )}
        </>
      )}
      <div
        style={{
          height: "100%",
          overflow: "auto",
          width: "100%",
        }}
      >
        {listloading ? (
          <TableListLoader rows={8} columns={6} />
        ) : (
          <>
            {caseItemList.length > 0 && (
              <CaseList
                {...{
                  module: props.section,
                  data: caseItemList,
                  activeItem: caseDetail,
                  section: props.section ? props.section : "",
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CaseDetailPage;
