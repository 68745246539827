import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link,
  useToastController,
  Toaster,
  Toast,
  useId,
  ToastBody,
  ToastTitle,
  ToastTrigger,
  Spinner,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { PauseIcon, PlayIcon, StopIcon } from "@fluentui/react-icons-mdl2";
import { TextField } from "@fluentui/react";
import { AppDispatch, RootState } from "../../../redux/store";
import CreatableSelectionList from "../../../components/Creatablelist";
import apiServices from "../../../service";
import TextEditor from "../../../components/TextEditor";
import { formatedDate2 } from "../../../utils";
import { setIsFloatingMenuOpen } from "../../../redux/timerSlice";

const Timer = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const activeCustomerId = useSelector(
    (state: any) => state.opentimer.activeCustomer
  );
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  const isFloatingMenuOpen = useSelector(
    (state: any) => state.opentimer.storedValue
  );
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isTimeAllocationDialogOpen, setIsTimeAllocationDialogOpen] =
    useState(false);
  const [isStartDialogOpen, setIsStartDialogOpen] = useState(false);
  const [isStopDialogOpen, setIsStopDialogOpen] = useState(false);
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<
    SelectionOption[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    getSelectionListOptions();
    formik.setFieldValue("allocated_date", new Date());
  }, []);

  const getSelectionListOptions = async () => {
    try {
      const res = await apiServices.selectionList.getOptions(
        "resource:ModifiableList"
      );
      if (res.data && res.data.data) {
        setSelectFieldOptions(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  interface SelectionOption {
    label: string;
    value: string;
  }
  const handleStartTimer = () => {
    setIsStartDialogOpen(true);
  };

  const newSelectOptionAdded = (
    field: string,
    selectionLabel: string,
    selectionType: string,
    options: any,
    operation: string
  ) => {
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );

      setNewlyAddedSelectOptions(filteredRes);
    } else {
      let newVall: any = {
        type: selectionType,
        options: [],
      };
      newVall["options"].push({
        field: selectionLabel,
        label: options.label,
        value: options.value,
      });

      setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
    }
  };
  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };

  const confirmStartTimer = () => {
    setIsStartDialogOpen(false);
    const intervalId = setInterval(
      () => setElapsedTime((prev) => prev + 1),
      1000
    );
    setTimerInterval(intervalId);
    setIsTimerRunning(true);
  };
  const handleStopTimer = () => {
    setIsStopDialogOpen(true);
  };
  const confirmStopTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
      setIsTimerRunning(false);
      setDuration(elapsedTime);
      setElapsedTime(0);
      setIsStopDialogOpen(false);
      setIsTimeAllocationDialogOpen(true);
    }
  };
  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const convertToUtcTimestamp = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const todayUtc = Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      hours,
      minutes,
      seconds
    );
    return new Date(todayUtc).toISOString();
  };

  const formik = useFormik({
    initialValues: {
      allocated_date: null,
      duration: formatDuration(duration),
      note_text: "",
      resource: "",
    },
    validate: (values: any) => {
      const errors: any = {};
      if (!values.allocated_date) errors.allocated_date = "Date is required";
      if (!values.note_text) {
        errors.note_text = "Note is required";
      }
    },

    onSubmit: async (values: any) => {
      setApiProcessing(true);
      try {
        const Timedata = formatDuration(duration);
        // console.log(Timedata, "Formatted Duration");
        // console.log(Timedata, "Time String Before Conversion");
        const Timeduration = convertToUtcTimestamp(Timedata);
        // console.log(Timeduration, "UTC Timestamp");
        // console.log(duration, "...");
        // const Timedata = formatDuration(duration);
        // console.log(formatDuration(duration), "///");

        // const Timeduration = convertToUtcTimestamp(Timedata);
        // console.log(convertToUtcTimestamp(Timedata), ";;;");
        let payload = {
          contacts_time_allocation: {
            customer_id: activeClient?.customer_id,
            allocated_date: formatedDate2(values.allocated_date),
            duration: Timeduration,
            note_text: values.note_text,
            resource: values.resource,
          },
        };
        // console.log("Payload:", payload);
        const response = await apiServices.timeAllocation.create(payload);
        setLoading(true);
        if (!response.data.isError) {
          notify(response.data.message, "", "success");

          setTimeout(() => {
            setApiProcessing(false);
            AddSelectOptions();
            setLoading(false);
            dispatch(setIsFloatingMenuOpen(false));
          }, 1000);

          // props.getActionListData();
          // console.log(props.getActionListData(), "..");
        } else {
          setLoading(false);
          setApiProcessing(false);
          notify("Something went wrong", "", "error");
        }
      } catch (error) {
        notify("Failed to save time allocation.", "", "error");
        setApiProcessing(false);
      } finally {
        setTimeout(() => {
          setIsTimeAllocationDialogOpen(false); // Close dialog after notification
          setApiProcessing(false);
          formik.resetForm();
        }, 1000);
      }
    },
  });

  const handleNewAllocation = () => {
    setElapsedTime(0);
    setDuration(0);
    formik.resetForm();
    setIsTimeAllocationDialogOpen(true);
  };
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };
  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      {isFloatingMenuOpen && (
        <div
          style={{
            top: "20px",
            right: "20px",
            background: "rgb(87 78 78)",
            border: "1px solid #ddd",
            borderRadius: "6px",
            padding: "8px",
            zIndex: 1000,
            fontSize: "14px",
            color: "whitesmoke",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {isTimerRunning ? (
              <Button
                onClick={() => {
                  if (timerInterval) clearInterval(timerInterval);
                  setTimerInterval(null);
                  setIsTimerRunning(false);
                }}
                style={{ padding: "4px 8px", minWidth: "auto" }}
              >
                <PauseIcon style={{ fontSize: "16px" }} />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleStartTimer();
                }}
                style={{ padding: "4px 8px", minWidth: "auto" }}
              >
                <PlayIcon />
              </Button>
            )}
            <Button
              onClick={handleStopTimer}
              disabled={!isTimerRunning}
              style={{ padding: "4px 8px", minWidth: "auto" }}
            >
              <StopIcon style={{ fontSize: "16px" }} />{" "}
            </Button>
            <span style={{ fontSize: "14px", fontWeight: "normal" }}>
              {" "}
              {formatDuration(elapsedTime)}
            </span>
          </div>
        </div>
      )}

      {isStartDialogOpen && (
        <Dialog open={isStartDialogOpen} onOpenChange={(event, data) => {}}>
          <DialogSurface>
            <DialogContent>
              <span style={{ fontWeight: "bold" }}>
                Are you sure you want to start the timer?
              </span>
              <DialogActions>
                <Button appearance="primary" onClick={confirmStartTimer}>
                  Yes
                </Button>
                <Button
                  appearance="secondary"
                  onClick={() => setIsStartDialogOpen(false)}
                >
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </DialogSurface>
        </Dialog>
      )}

      {isStopDialogOpen && (
        <Dialog
          open={isStopDialogOpen}
          onOpenChange={(event, data) => {
            if (!data.open) {
              setIsStopDialogOpen(false);
            }
          }}
        >
          <DialogSurface>
            <DialogContent>
              <span style={{ fontWeight: "bold" }}>
                Do you want to stop the timer?
              </span>
              <DialogActions>
                <Button appearance="primary" onClick={confirmStopTimer}>
                  Yes
                </Button>
                <Button
                  appearance="secondary"
                  onClick={() => setIsStopDialogOpen(false)}
                >
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </DialogSurface>
        </Dialog>
      )}
      <Dialog open={isTimeAllocationDialogOpen}>
        <DialogSurface>
          <DialogTitle>Time Allocation</DialogTitle>

          <DialogContent>
            <form>
              <Field
                label={
                  <span>
                    Date<span className="required-item">*</span>
                  </span>
                }
                validationState={
                  formik.errors.allocated_date ? "error" : "none"
                }
              >
                <DatePicker
                  name="allocated_date"
                  value={
                    formik.values.allocated_date
                      ? new Date(formik.values.allocated_date)
                      : null
                  }
                  onSelectDate={(date) =>
                    formik.setFieldValue("allocated_date", date || new Date())
                  }
                  allowTextInput
                  formatDate={onFormatDate}
                />
              </Field>

              <Field label={"Duration"}>
                <TextField
                  name="duration"
                  value={formatDuration(duration)}
                  onChange={(e) => {}}
                />
              </Field>
              {/* <TextField
                name="duration"
                value={formatDuration(convertUtcToLocal(duration))}
                onChange={(e) => {}}
              /> */}

              <Field label={"Note"}>
                <TextEditor
                  name="note_text"
                  inputData={formik.values.note_text}
                  onChange={(text: any) =>
                    formik.setFieldValue("note_text", text)
                  }
                />
              </Field>

              <Field label={"Resource"}>
                <CreatableSelectionList
                  width="fullwidth"
                  name="resource"
                  selectionLabel="resource"
                  selectFieldOptions={selectFieldOptions}
                  newSelectOptionAdded={newSelectOptionAdded}
                  selectionType="ModifiableList"
                  values={formik.values.resource}
                  onChange={(value: any) =>
                    formik.setFieldValue("resource", value)
                  }
                />
              </Field>
            </form>
            <div style={{ marginTop: "20px" }}>
              <DialogActions>
                <Button onClick={handleNewAllocation}>New</Button>
                <Button
                  appearance="primary"
                  onClick={() => formik.handleSubmit()}
                  icon={apiProcessing ? <Spinner size="tiny" /> : null}
                >
                  {apiProcessing ? "Processing..." : "Save"}
                </Button>
                <Button onClick={() => setIsTimeAllocationDialogOpen(false)}>
                  Cancel
                </Button>
              </DialogActions>
            </div>
          </DialogContent>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default Timer;
