import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { getProvidersList } from "../../../redux/providersSlice";
const Provider = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const providersList = useSelector((state: RootState) => state.providers);
  const [providerCategory, setProviderCategory] = useState<any>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | {}>(props.data);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "group_name",
        header: "Group",
      },
      {
        accessorKey: "provider_name",
        header: "Name",
      },
      {
        accessorKey: "contact_name",
        header: "Contact",
      },

      {
        accessorKey: "address1",
        header: "Address 1",
      },
      {
        accessorKey: "address2",
        header: "Address 2",
      },
      {
        accessorKey: "address3",
        header: "Address 3",
      },
      {
        accessorKey: "town",
        header: "Town",
      },
      {
        accessorKey: "county",
        header: "County",
      },
      {
        accessorKey: "postcode",
        header: "Postcode",
      },
      {
        accessorKey: "salutation",
        header: "Salutation",
      },
      {
        accessorKey: "fax",
        header: "Fax",
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  useEffect(() => {
    if (providerCategory.length && Object.keys(rowSelection)[0]) {
      let index = 0;
      index = providerCategory.findIndex(
        (x: any) => x.id === Object.keys(rowSelection)[0]
      );
      if (providerCategory[index] !== undefined) {
        setSelectedItem(providerCategory[index]);
        props.providerId(providerCategory[index]);
      }
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      props.options !== undefined &&
      Object.keys(props.options).length !== 0 &&
      props.options.getProviderId !== undefined &&
      providerCategory.length > 0
    ) {
      let index = 0;

      if (props.options.getProviderId["provider_correspondence"]) {
        index = providerCategory.findIndex(
          (x: any) =>
            x.id == props.options.getProviderId["provider_correspondence"]
        );
      } else {
        index = providerCategory.findIndex(
          (x: any) => x.id == props.options.getProviderId["id"]
        );
      }

      if (providerCategory[index] !== undefined) {
        setRowSelection({ [providerCategory[index]["id"]]: true });
        setSelectedItem(providerCategory[index]);
        props.providerId(providerCategory[index]);
      }
    }
  }, [props.options, providerCategory]);
  useEffect(() => {
    setProviderCategory(providersList.list);
    setRowCount(providersList.count);
  }, [providersList]);
  useEffect(() => {
    dispatch(
      getProvidersList({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        search: globalFilter,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize, globalFilter]);

  return providersList.loading ? (
    <ContentLoader style={{ minHeight: "250px" }} />
  ) : (
    <>
      <div className="">
        <MaterialReactTable
          columns={columns}
          data={providerCategory}
          onRowSelectionChange={setRowSelection}
          getRowId={(row) => row.id}
          enableColumnFilters={true}
          enableGlobalFilter={true}
          enableDensityToggle={true}
          enableRowSelection={true}
          enableSelectAll={false}
          enableColumnActions={false}
          enableMultiRowSelection={false}
          enableRowActions={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          state={{ pagination, rowSelection }}
          manualPagination
          manualFiltering={true}
          onPaginationChange={setPagination}
          onGlobalFilterChange={setGlobalFilter}
          rowCount={rowCount}
          enableStickyHeader
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
        />
      </div>
    </>
  );
};

export default Provider;
