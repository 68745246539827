import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumbs from "../../layout/breadcrumb/Breadcrumbs";
import Sidebar from "../../layout/sidebar/Sidebar";
function UtilitiesLayout() {
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <aside
        style={{
          width: "230px",

          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <Sidebar />
      </aside>
      <main style={{ flex: 1, padding: "0.6rem", overflowY: "auto" }}>
        <Breadcrumbs />
        <Outlet />
      </main>
    </div>
  );
}

export default UtilitiesLayout;
