import {
  Badge,
  Button,
  Checkbox,
  makeStyles,
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
} from "@fluentui/react-components";
import React, { useEffect, useRef, useState } from "react";
import { ChevronDownRegular } from "@fluentui/react-icons";
import DateFilter from "./DateFilter";

enum TabValues {
  selectDate = "tab1",
  SelectProvider = "tab2",
}

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px",
    width: "500px",
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    gap: "3px",
    padding: "5px",
  },
});

interface FilterTabProps {
  filterPopup: boolean;
  setFilterPopup: (value: boolean) => void;
  selectedMenuItem: string;
  title: string;
  providerOptions: { label: string; checked: boolean; id: any }[];
}
const FilterTab: React.FC<FilterTabProps> = (props) => {
  const { filterPopup, setFilterPopup, title, providerOptions } = props;

  const [selectedTab, setSelectedTab] = useState<TabValues>(
    TabValues.selectDate
  );

  const [currentSelectedDates, setCurrentSelectedDates] =
    useState<string>("Custom");
  const [appliedSelectedDates, setAppliedSelectedDates] =
    useState<string>("Custom");

  const [currentOptions, setCurrentOptions] = useState(
    providerOptions.map(() => true)
  );
  const [appliedOptions, setAppliedOptions] = useState(
    providerOptions.map(() => true)
  );

  const styles = useStyles();
  const selectAllRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectAllRef.current) {
      const allChecked = currentOptions.every((checked) => checked);
      const noneChecked = currentOptions.every((checked) => !checked);
      selectAllRef.current.indeterminate = !allChecked && !noneChecked;
    }
  }, [currentOptions]);

  useEffect(() => {
    // Sync current dates with applied dates when opening the filter
    setCurrentSelectedDates(appliedSelectedDates);
    setCurrentOptions(appliedOptions);
  }, [filterPopup, appliedSelectedDates, appliedOptions]);

  const handleSelectAllChange = (checked: boolean) => {
    setCurrentOptions(currentOptions.map(() => checked));
  };

  const handleOptionChange = (index: number) => {
    setCurrentOptions((prevOptions) =>
      prevOptions.map((checked, i) => (i === index ? !checked : checked))
    );
  };

  const handleApplyFilters = () => {
    setAppliedOptions(currentOptions);
    setAppliedSelectedDates(currentSelectedDates);
    setFilterPopup(false);
  };

  const selectedProviders = providerOptions
    .filter((_, index) => appliedOptions[index])
    .map((option) => option.label);

  return (
    <Menu open={filterPopup}>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          onClick={() => setFilterPopup(true)}
          style={{
            fontWeight: "normal",
            color: "#606060",
            border: "1px solid grey",
          }}
        >
          <div>
            {!appliedSelectedDates && selectedProviders.length === 0 ? (
              <span className="customTriggerText">Filter</span>
            ) : (
              <div>
                {appliedSelectedDates && (
                  <span className="customTriggerText">
                    {appliedSelectedDates},
                  </span>
                )}
                {selectedProviders.length > 0 && (
                  <span>
                    {selectedProviders.slice(0, 1).join(", ")}
                    {selectedProviders.length > 1 && (
                      <>
                        ,{" "}
                        <Badge className="badge">
                          +{selectedProviders.length - 1}
                        </Badge>
                      </>
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        </MenuButton>
      </MenuTrigger>
      <MenuPopover style={{ minWidth: "500px", minHeight: "300px" }}>
        <div className={styles.root}>
          <TabList
            defaultSelectedValue={TabValues.selectDate}
            onTabSelect={(event, data) =>
              setSelectedTab(data.value as TabValues)
            }
          >
            <Tab value={TabValues.selectDate}>Select Dates</Tab>
            <Tab value={TabValues.SelectProvider}>{title}</Tab>
          </TabList>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "stretch",
            padding: "3px",
          }}
        >
          {selectedTab === TabValues.selectDate && (
            <div className={styles.fullWidthContainer}>
              <DateFilter
                optionDateValue={currentSelectedDates}
                setSelectedDates={(value: string | null) => {
                  if (value !== null) {
                    setCurrentSelectedDates(value);
                  }
                }}
              />
            </div>
          )}
          {selectedTab === TabValues.SelectProvider && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "3px",
                gap: "5px",
                width: "500px",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              <Checkbox
                ref={selectAllRef}
                checked={currentOptions.every((checked) => checked)}
                onChange={(_ev, data: any) =>
                  handleSelectAllChange(data.checked)
                }
                label="Select All"
              />
              {providerOptions.map((option, index) => (
                <Checkbox
                  key={option.id}
                  checked={currentOptions[index]}
                  onChange={() => handleOptionChange(index)}
                  label={option.label}
                />
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            size="small"
            onClick={() => {
              setFilterPopup(false);
              setSelectedTab(TabValues.selectDate);
            }}
          >
            Cancel
          </Button>
          <Button
            className="asc-button-primary"
            size="small"
            appearance="primary"
            onClick={() => {
              handleApplyFilters();
              setSelectedTab(TabValues.selectDate);
            }}
          >
            Apply
          </Button>
        </div>
      </MenuPopover>
    </Menu>
  );
};

export default FilterTab;
