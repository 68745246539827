import { Badge, Button, Label, Tooltip } from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_RowSelectionState,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Input } from "@fluentui/react-components";
import FilterTab from "../CommissionReceipts/FilterTab";
import DistributionDetailsTree from "./DistributionDetailsTree";
import DistibutionEditForm from "./DistibutionEditForm";
import StopDistribution from "./StopDistribution";
interface DataRow {
  expected: string;
  actual: string;
  Duedate: string;
  party_name: string;
  party_type: string;
  note: string;
  rule_amount: string;
  received_amount: number;
  commission_type: string;
  refernce: string;
  client: string;
  provider: string;
  case_type: string;
  subtype: string;
  reference: string;
}
const DistributionList = (props: any) => {
  const [confirmationPopup, setConfirmationPopupPopup] = useState(false);
  const [editCommissionpopup, setEditCommissionpopup] = useState(false);
  const [stopDistributionpopup, setStopdistributionpopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});
  const [selectedRowData, setSelectedRowData] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [amountData, setAmountData] = useState<number>(0);

  const [datalist, setDataList] = useState<DataRow[]>([
    {
      expected: "34.54",
      actual: "34.54",
      Duedate: "10/12/2024",
      party_name: "string",
      party_type: "string",
      note: "string",
      rule_amount: "234.45",
      received_amount: 45.67,
      commission_type: "string",
      refernce: "string",
      client: "string",
      provider: "string",
      case_type: "string",
      subtype: "string",
      reference: "string",
    },
    {
      expected: "34.54",
      actual: "34.54",
      Duedate: "10/12/2024",
      party_name: "string",
      party_type: "string",
      note: "string",
      rule_amount: "234.45",
      received_amount: 45.67,
      commission_type: "string",
      refernce: "string",
      client: "string",
      provider: "string",
      case_type: "string",
      subtype: "string",
      reference: "string",
    },
    {
      expected: "34.54",
      actual: "34.54",
      Duedate: "10/12/2024",
      party_name: "string",
      party_type: "string",
      note: "string",
      rule_amount: "234.45",
      received_amount: 45.67,
      commission_type: "string",
      refernce: "string",
      client: "string",
      provider: "string",
      case_type: "string",
      subtype: "string",
      reference: "string",
    },
  ]);
  const partiesOptions = [
    { label: "A J Bell", checked: true, id: "1234" },
    { label: "Bob", checked: false, id: "7234" },
    { label: "Tom", checked: false, id: "1634" },
    { label: "Anderson", checked: true, id: "1274" },
  ];
  const handleCellEdit = (
    rowIndex: number,
    accessorKey: keyof DataRow,
    value: string
  ) => {
    setDataList((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = {
        ...newData[rowIndex],
        [accessorKey]: value,
      };
      return newData;
    });
  };
  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      {
        accessorKey: "expected",
        header: "Expected",
      },
      {
        accessorKey: "actual",
        header: "Actual",
        Cell: ({ cell, row }: { cell: MRT_Cell<DataRow>; row: any }) => (
          <Input
            style={{ border: "none" }}
            className="hover-border"
            value={cell.getValue<string>()}
            onChange={(e) =>
              handleCellEdit(
                row.index,
                "actual",
                (e.target as HTMLInputElement).value
              )
            }
          />
        ),
      },
      {
        accessorKey: "Duedate",
        header: "Due Date",
      },
      {
        accessorKey: "party_name",
        header: "Party Name",
      },
      {
        accessorKey: "party_type",
        header: "Party Type",
      },
      {
        accessorKey: "note",
        header: "Note",
      },
      {
        accessorKey: "rule_amount",
        header: "Rule Amount",
      },
      {
        accessorKey: "received_amount",
        header: "Received Amount",
      },
      {
        accessorKey: "commission_type",
        header: "Commission Type",
      },
      {
        accessorKey: "client",
        header: "Client",
      },
      {
        accessorKey: "provider",
        header: "Provider",
      },
      {
        accessorKey: "case_type",
        header: "Case Type",
      },
      {
        accessorKey: "subtype",
        header: "Subtype",
      },
      {
        accessorKey: "reference",
        header: "Reference",
      },
    ],
    []
  );

  useEffect(() => {
    // Calculate the sum of `received_amount` for selected rows
    const total = Object.keys(selectedRow)
      .filter((key) => selectedRow[key]) // Filter selected rows
      .reduce((sum, rowIndex) => {
        const row = datalist[parseInt(rowIndex)];
        return sum + (row?.actual ? parseFloat(row.actual) : 0);
      }, 0);

    setAmountData(total);
  }, [selectedRow, datalist]);
  return (
    <div>
      <div className="commission_options">
        <div className="commission_filter">
          <Label>Amount of Distribution</Label>
          <Input
            value={amountData ? amountData.toString() : ""}
            disabled={
              !amountData &&
              !Object.values(selectedRow).some((isSelected) => isSelected)
            }
            type="number"
          />
        </div>
        <Button
          className="asc-button-primary"
          appearance="primary"
          disabled={
            !Object.values(selectedRow).some((isSelected) => isSelected)
          }
        >
          Save
        </Button>
      </div>
      <div className="client-list-wrapper">
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: "10px",
              }}
            >
              <Button
                onClick={() => {
                  setEditCommissionpopup(true);
                }}
                disabled={
                  Object.values(selectedRow).filter((isSelected) => isSelected)
                    .length === 1
                    ? false
                    : true
                }
              >
                Adjust Commission Distribution
              </Button>
              <FilterTab
                filterPopup={props.filterPopup}
                setFilterPopup={props.setFilterPopup}
                selectedMenuItem="Select Provider"
                title="Select Providers"
                providerOptions={partiesOptions}
              />
            </div>
          )}
          columns={columns}
          data={datalist}
          enableColumnFilters={false}
          enableGlobalFilter={false}
          enableDensityToggle={false}
          enableRowSelection={true}
          enableSelectAll={false}
          enableColumnActions={false}
          enableMultiRowSelection={true}
          enableRowActions={false}
          enableHiding={false}
          enableFullScreenToggle={true}
          enableStickyHeader
          enablePinning
          manualPagination
          enableExpanding={true}
          enableExpandAll={true}
          enableRowVirtualization={true}
          renderDetailPanel={({ row }) => <DistributionDetailsTree />}
          initialState={{
            columnPinning: {
              left: ["mrt-row-expand", "mrt-row-select", "expected", "actual"],
            },
          }}
          displayColumnDefOptions={{
            "mrt-row-select": {
              header: "Allocated",
              size: 80,
            },
          }}
          muiSelectCheckboxProps={({ row }: any) => ({
            onClick: () => {
              setRowSelection({ [row.index]: true });
              const rowId = row.id;
              const isSelected = !selectedRow[rowId];

              setSelectedRow((prev) => ({
                ...prev,
                [rowId]: isSelected,
              }));

              setSelectedRowData(
                (prevData: any) =>
                  isSelected
                    ? [...prevData, row.original.commission_id] // Add commission_id
                    : prevData.filter(
                        (id: any) => id !== row.original.commission_id
                      ) // Remove commission_id
              );
            },
            selected: selectedRow[row.id],
            color: "primary",
          })}
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
          muiTableContainerProps={{
            style: {
              minHeight: "100px",
              maxHeight: "100vh",
              overflow: "auto",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: "14px",
              lineHeight: "0.9",
            },
          }}
        />
      </div>
      <DistibutionEditForm
        setEditCommissionpopup={setEditCommissionpopup}
        editCommissionpopup={editCommissionpopup}
        stopDistributionpopup={stopDistributionpopup}
        setStopdistributionpopup={setStopdistributionpopup}
      />
      <StopDistribution
        stopDistributionpopup={stopDistributionpopup}
        setStopdistributionpopup={setStopdistributionpopup}
      />
      ;
    </div>
  );
};

export default DistributionList;
