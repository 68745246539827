import React, { useEffect, useState } from "react";
import "../style.css";
import {
  Caption1,
  Caption1Strong,
  makeStyles,
  shorthands,
  Subtitle2,
} from "@fluentui/react-components";
import { fetchSettings } from "../../../redux/formSlice";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";

import FormContainerWithList from "../../../components/FormContainerWithList";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { reloadData } from "../../../redux/appSettingsSlice";
type ComponentProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  closePage?: () => void;
};
const IncomeForm = (props: ComponentProps) => {
  const [formContext, setFormContext] = useState("CLIENT_INCOME");
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState({});
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const appSettings = useSelector((state: RootState) => state.appSettings);
  const [configForm, setConfigForm] = useState<configFm>();

  useEffect(() => {
    if (
      formContext &&
      formSettingsObj?.data &&
      formSettingsObj.data.formConfig !== undefined
    ) {
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj]);
  useEffect(() => {
    if (props.activeCustomerId && props.activeCustomerId !== null) {
      getDataList();
    }
  }, [props.activeCustomerId]);
  useEffect(() => {
    console.log("appSettings", appSettings);
    if (appSettings.reloadDataList == true) {
      getDataList();
    }
  }, [appSettings, dispatch]);

  const loadListData = () => {
    getDataList();
  };
  const getDataList = async () => {
    console.log("income get call" + Date.now());
    setLoading(true);
    try {
      const res = await apiServices.income.getList(props.activeCustomerId);
      dispatch(reloadData(false));
      if (res.data !== undefined) {
        if (
          res.data.data &&
          res.data.data.result &&
          res.data.data.result.length > 0
        ) {
          let singleData = {
            income: res.data.data.result,
            incomeTotals: res.data.data.total,
          };
          setInitialData(singleData);
        }
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return loading ? (
    <ContentLoader />
  ) : (
    <div className="form-container-wrapper">
      <FormContainerWithList
        activePartnerId={props.activePartnerId}
        setActivePartnerId={props.setActivePartnerId}
        context={formContext}
        config={configForm}
        activeCustomerId={props.activeCustomerId}
        notify={props.notify}
        initialData={initialData}
        reloadData={loadListData}
        reloadDetail={props.reloadDetail}
        section="income"
      />
    </div>
  );
};

export default IncomeForm;
