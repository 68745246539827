import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  makeStyles,
  tokens,
  shorthands,
} from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { checkUserPermission } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import apiServices from "../../../service";
import { getAssetListItems } from "../../../redux/Case/assetSlice";
import ContentLoader from "../../../components/Loader/ContentLoader";
import ASCButton from "../../../components/Button";
import { getLiabilityListItems } from "../../../redux/Case/liabilitySlice";
import { getPolicyListItems } from "../../../redux/Case/policySlice";

const useStyles = makeStyles({
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "10px",
  },
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});
const ModuleLabel: any = {
  assets: "Asset",
  liabilities: "Liability",
  policies: "Policy",
};
const radioOptions: any = {
  assets: [
    { value: "investment", label: "Investment" },
    { value: "shares", label: "Share Holdings" },
    { value: "home-personal", label: "Home and Personal" },
    { value: "bank-societies", label: "Bank/Building Societies" },
  ],
  liabilities: [
    { value: "morgages", label: "Mortgages" },
    { value: "loans-lease-hp", label: "Loan and Hire Purchase" },
    { value: "credit-cards", label: "Credit Cards" },
  ],
  policies: [
    { value: "Life Assurance", label: "Life Assurance" },
    { value: "Pensions", label: "Pensions" },
    { value: "Investments", label: "Investments" },
    { value: "Savings Plans", label: "Savings Plans" },
    { value: "Income Protection", label: "Income Protection" },
    { value: "Health Assurance", label: "Health Assurance" },
  ],
};
function CaseHomePage({ section }: { section: string }) {
  const [caseItemList, setCaseItemList] = useState<any[]>([]);
  const [caseCategoryPopup, setCaseCategoryPopup] = useState(false);
  const [selectedCaseType, setSelectedCaseType] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { clientId, action } = useParams();
  const navigate = useNavigate();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const assets = useSelector((state: RootState) => state.assets);
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const policies = useSelector((state: RootState) => state.policies);
  const styles = useStyles();
  const pageloading =
    {
      assets: assets?.loading,
      liabilities: liabilities?.loading,
      policies: policies?.loading,
    }[section] || false;
  // Fetch case list on clientId change
  useEffect(() => {
    if (clientId) fetchCaseLists();
  }, [clientId]);
  useEffect(() => {
    const listItems: any = {
      assets: assets?.listItems,
      liabilities: liabilities?.listItems,
      policies: policies?.listItems,
    }[section];
    if (listItems) setCaseItemList(listItems);
  }, [assets, liabilities, policies, dispatch, section]);

  // Navigate to the first case item when caseItemList changes
  useEffect(() => {
    if (caseItemList.length > 0) {
      const idKeyMap: Record<string, string> = {
        assets: "asset_id",
        liabilities: "liability_id",
        policies: "policy_id",
      };

      const typeKeyMap: Record<string, string> = {
        assets: "asset",
        liabilities: "liability",
        policies: "policy",
      };

      const idKey = idKeyMap[section];
      const typeKey = typeKeyMap[section];

      if (idKey && typeKey) {
        navigate(
          `/clients/${clientId}/${section}/${
            caseItemList[0]?.[idKey]
          }/${typeKey}/${action || "view"}`
        );
      }
    }
  }, [caseItemList, clientId, section, action, navigate]);

  const fetchCaseLists = () => {
    const actions: any = {
      assets: getAssetListItems,
      liabilities: getLiabilityListItems,
      policies: getPolicyListItems,
    };
    if (clientId) dispatch(actions[section](clientId));
  };

  const handleAddCase = () => {
    if (selectedCaseType) {
      setCaseCategoryPopup(false);
      navigate(`/clients/${clientId}/${section}/add`, {
        state: { caseType: selectedCaseType },
      });
    }
  };

  const renderEmptyState = () => (
    <>
      <div className={styles.wrapper}>
        {checkUserPermission(
          loggedUser,
          UserPermissionsList.ALLOW_UPDATE_CLIENT
        ) && (
          <ASCButton
            appearance="primary"
            className="asc-button-primary"
            icon={<Add16Filled />}
            size="medium"
            shape="rounded"
            onItemClick={() => setCaseCategoryPopup(true)}
            label="Add New"
          />
        )}
      </div>
      <div className={styles.emptyState}>
        <Label size="large" style={{ color: "red" }}>
          There are no active records - click Add New to create a new record
        </Label>
      </div>
    </>
  );

  return (
    <div>
      {pageloading ? (
        <ContentLoader />
      ) : (
        <>
          {caseItemList.length === 0 && renderEmptyState()}
          <Dialog open={caseCategoryPopup} modalType="modal">
            <DialogSurface>
              <DialogBody>
                <DialogContent>
                  <div className={styles.field}>
                    <Label weight="semibold">
                      Select {ModuleLabel[section]} Category
                    </Label>
                    <RadioGroup
                      value={selectedCaseType}
                      onChange={(_, data) => setSelectedCaseType(data.value)}
                    >
                      {radioOptions[section]?.map((radio: any) => (
                        <Radio
                          key={radio.value}
                          value={radio.value}
                          label={radio.label}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      appearance="secondary"
                      size="small"
                      onClick={() => setCaseCategoryPopup(false)}
                    >
                      Close
                    </Button>
                  </DialogTrigger>
                  <Button
                    appearance="primary"
                    size="small"
                    className="asc-button-primary"
                    onClick={handleAddCase}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default CaseHomePage;
