import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../service";

const initialState: any = {
  listItems: [],

  payments: {
    listItems: [],
    activeItem: {},
    totalPayment: {},
    loading: false,
    activeItemloading: false,
    commissions: {
      listItems: [],
      activeItem: {},
      loading: false,
      activeItemloading: false,
    },
  },

  actions: {
    listItems: [],
    activeItem: {},
    loading: false,
    activeItemloading: false,
  },

  activeItem: {},
  loading: false,
  activeItemloading: false,
};
export const getLiabilityListItems = createAsyncThunk(
  "liabilities/getLiabilityListItems",
  async (id: string, { rejectWithValue }) => {
    if (!id) return rejectWithValue("Invalid customer ID");
    try {
      const response: any = await apiServices.liability.getLiabilities(id);
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset list");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getLiabilityDetailById = createAsyncThunk(
  "liabilities/getLiabilityDetailById",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.liability.getDetail(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getLiabilityActionsListItems = createAsyncThunk(
  "liabilities/getLiabilityActionsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Actions.getActions(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch asset details");
      }
      console.log("liabilities/getLiabilityActionsListItems", response.data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getLiabilityActionDetailById = createAsyncThunk(
  "liabilities/getLiabilityActionDetailById",
  async (actionId: string, { getState, rejectWithValue }) => {
    if (!actionId) return rejectWithValue("Invalid actionId ID");

    // Access the current state
    const state: any = getState();

    const actionsList = state.liabilities.actions.listItems;
    console.log("liabilities-actionsList", actionsList);

    // Find the valuation detail by ID in the listItems array
    const actionDetail = actionsList.find(
      (item: any) => item.business_id === actionId
    );
    console.log("liabilities-actionDetail", actionDetail);
    if (actionDetail) {
      return actionDetail; // Return the detail if found
    } else {
      return rejectWithValue("action detail not found in state");
    }
  }
);
export const getLiabilityPaymentsListItems = createAsyncThunk(
  "assets/getLiabilityPaymentsListItems",
  async (
    { id, customerId }: { id: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!id || !customerId) return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.Payments.getPayments(
        id,
        customerId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getLiabilityPaymentDetailById = createAsyncThunk(
  "assets/getLiabilityPaymentDetailById",
  async (paymentId: string, { getState, rejectWithValue }) => {
    if (!paymentId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.liabilities.payments.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.payment.payment_id === paymentId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const getLiabilityPaymentsCommissionsListItems = createAsyncThunk(
  "assets/getLiabilityPaymentsCommissionsListItems",
  async (
    {
      paymentId,
      caseId,
      customerId,
    }: { paymentId: string; caseId: string; customerId: string },
    { rejectWithValue }
  ) => {
    if (!paymentId || !caseId || !customerId)
      return rejectWithValue("Invalid ID or Customer ID");

    try {
      const response: any = await apiServices.commission.getCommission(
        paymentId,
        customerId,
        caseId
      );
      const { isError, data } = response.data;
      if (isError || !data) {
        return rejectWithValue("Failed to fetch fund details");
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);
export const getLiabilityPaymentsCommissionDetailById = createAsyncThunk(
  "assets/getLiabilityPaymentsCommissionDetailById",
  async (commissionId: string, { getState, rejectWithValue }) => {
    if (!commissionId) return rejectWithValue("Invalid paymentId ID");

    // Access the current state
    const state: any = getState();

    const itemList = state.liabilities.payments.commissions.listItems;

    // Find the valuation detail by ID in the listItems array
    const itemDetail = itemList.find(
      (item: any) => item.commission_id === commissionId
    );

    if (itemDetail) {
      return itemDetail; // Return the detail if found
    } else {
      return rejectWithValue("paymenty detail not found in state");
    }
  }
);
export const liabilitiesSlice = createSlice({
  name: "liabilities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLiabilityListItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLiabilityListItems.fulfilled, (state, action) => {
        state.loading = false;
        state.listItems = action.payload;
      })
      .addCase(getLiabilityListItems.rejected, (state, action) => {
        state.loading = false;
        console.error("Error fetching asset list:", action.payload);
      })
      .addCase(getLiabilityDetailById.pending, (state) => {
        state.activeItemloading = true;
      })
      .addCase(getLiabilityDetailById.fulfilled, (state, action) => {
        state.activeItemloading = false;
        state.activeItem = action.payload;
      })
      .addCase(getLiabilityDetailById.rejected, (state, action) => {
        state.activeItemloading = false;
        console.error("Error fetching asset details:", action.payload);
      })
      .addCase(getLiabilityActionsListItems.pending, (state) => {
        state.actions.loading = true;
      })
      .addCase(getLiabilityActionsListItems.fulfilled, (state, action) => {
        state.actions.loading = false;
        state.actions.listItems = action.payload;
      })
      .addCase(getLiabilityActionsListItems.rejected, (state, action) => {
        state.actions.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getLiabilityActionDetailById.pending, (state) => {
        state.actions.activeItemloading = true;
      })
      .addCase(getLiabilityActionDetailById.fulfilled, (state, action) => {
        state.actions.activeItemloading = false;
        state.actions.activeItem = action.payload;
      })
      .addCase(getLiabilityActionDetailById.rejected, (state, action) => {
        state.actions.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getLiabilityPaymentsListItems.pending, (state) => {
        state.payments.loading = true;
      })
      .addCase(getLiabilityPaymentsListItems.fulfilled, (state, action) => {
        state.payments.loading = false;
        state.payments.listItems = action.payload?.result || [];
        state.payments.totalPayment = action.payload?.total;
      })
      .addCase(getLiabilityPaymentsListItems.rejected, (state, action) => {
        state.payments.loading = false;
        console.error("Error fetching asset actions:", action.payload);
      })
      .addCase(getLiabilityPaymentDetailById.pending, (state) => {
        state.payments.activeItemloading = true;
      })
      .addCase(getLiabilityPaymentDetailById.fulfilled, (state, action) => {
        state.payments.activeItemloading = false;
        state.payments.activeItem = action.payload;
      })
      .addCase(getLiabilityPaymentDetailById.rejected, (state, action) => {
        state.payments.activeItemloading = false;
        console.error("Error fetching asset action detail:", action.payload);
      })
      .addCase(getLiabilityPaymentsCommissionsListItems.pending, (state) => {
        state.payments.commissions.loading = true;
      })
      .addCase(
        getLiabilityPaymentsCommissionsListItems.fulfilled,
        (state, action) => {
          state.payments.commissions.loading = false;
          state.payments.commissions.listItems = action.payload;
        }
      )
      .addCase(
        getLiabilityPaymentsCommissionsListItems.rejected,
        (state, action) => {
          state.payments.commissions.loading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      )
      .addCase(getLiabilityPaymentsCommissionDetailById.pending, (state) => {
        state.payments.commissions.activeItemloading = true;
      })
      .addCase(
        getLiabilityPaymentsCommissionDetailById.fulfilled,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          state.payments.commissions.activeItem = action.payload;
        }
      )
      .addCase(
        getLiabilityPaymentsCommissionDetailById.rejected,
        (state, action) => {
          state.payments.commissions.activeItemloading = false;
          console.error("Error fetching asset actions:", action.payload);
        }
      );
  },
});
export const {} = liabilitiesSlice.actions;
export default liabilitiesSlice.reducer;
