import { useEffect, useState } from "react";
import "../style.css";
import {
  useId,
  makeStyles,
  Button,
  shorthands,
  Toast,
  ToastTitle,
  ToastTrigger,
  Link,
  ToastBody,
  useToastController,
  Toaster,
} from "@fluentui/react-components";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { configFm } from "../../../types";
import apiServices from "../../../service";
import SystemManagerFormContainer from "../SystemManagerFormContainer";
import OverlayLoader from "../../../components/OverlayLoader";
import { getProvidersList } from "../../../redux/providersSlice";
import ContentLoader from "../../../components/Loader/ContentLoader";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
});
type ComponentProps = {
  [x: string]: any;
};
const Providers = (props: any) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const [formContext, setFormContext] = useState("MASTER_SETTINGS_PROVIDERS");
  const [isLoading, setIsLoading] = useState(false);

  const [initialData, setInitialData] = useState({});
  const [providerdata, setproviderdata] = useState<any>([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const providersList = useSelector((state: RootState) => state.providers);
  const [configForm, setConfigForm] = useState<configFm>();

  useEffect(() => {
    if (
      formSettingsObj &&
      formSettingsObj?.systemAdmin &&
      formSettingsObj.systemAdmin.formConfig !== undefined &&
      formContext
    ) {
      let configObj = formSettingsObj?.systemAdmin.formConfig.find(
        (item: any) => item.context === formContext
      );
      if (configObj) {
        setConfigForm(configObj);
      }
    }
  }, [formSettingsObj, formContext]);

  useEffect(() => {
    const fetchProvidersList = async () => {
      setIsLoading(true);
      try {
        await dispatch(getProvidersList({ page: 1, limit: 10, search: "" }));
      } catch (err) {
        console.error("Error fetching providers:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProvidersList();
  }, [dispatch]);
  // useEffect(() => {
  //   setproviderdata(providersList.list);
  // }, [providersList]);
  // const loadListData = () => {
  //   dispatch(
  //     getProvidersList({
  //       page: 1,
  //       limit: 10,
  //       search: "",
  //     })
  //   );
  // };
  // const getDataList = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await apiServices.systemManager.providers.getList();
  //     if (res.data !== undefined) {
  //       if (res.data.data) {
  //         setproviderdata(res.data.data);
  //       }
  //     }
  //     setIsLoading(false);
  //   } catch (err) {
  //     setIsLoading(false);
  //     console.log(err);
  //   }
  // };
  return isLoading ? (
    <ContentLoader style={{ minHeight: "250px" }} />
  ) : (
    <div className="admin-form-container">
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />

      <SystemManagerFormContainer
        context={formContext}
        config={configForm}
        notify={notify}
        title=""
        isFormAdmin={true}
        dataList={providersList.list}
        reloadData={() => {}}
      />
    </div>
  );
};

export default Providers;
