import * as React from "react";
import { Button } from "@fluentui/react-components";
import { DismissFilled } from "@fluentui/react-icons";

const InitialPage = ({
  clickToTitle,
  cancelFirst,
  BackToTab,
  specificationList,
  setSelectedSpecId,
  selectedSpecId,
  deleteSpecification,
  editClick,
}: {
  clickToTitle: () => void;
  cancelFirst: () => void;
  BackToTab: () => void;
  specificationList: any[];
  setSelectedSpecId: (id: any) => void;
  selectedSpecId: () => void;
  deleteSpecification: (id: any) => void;
  editClick: () => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <div>
        <div>
          <span style={{ fontWeight: "700" }}>Extract Specifications</span>
        </div>
        <br />
        <span>
          Select a saved specification from this list or Click New to create a
          new one
        </span>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button appearance="primary" size="small" onClick={clickToTitle}>
          New
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #D1D1D1",
          maxHeight: "300px",
          minHeight: "300px",
          overflowX: "auto",
        }}
      >
        {specificationList.map((spec: any) => (
          <div
            key={spec.SearchId}
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              background:
                selectedSpecId === spec.SearchId ? "#f5f5f5" : "transparent",
            }}
            onClick={() => setSelectedSpecId(spec.SearchId)}
          >
            <div style={{ padding: "5px" }}>
              <span>{spec.Title}</span>
            </div>
            {spec.id !== 1 && (
              <div
                style={{ padding: "5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSpecification(spec.SearchId);
                }}
              >
                <DismissFilled />
              </div>
            )}
          </div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        <div>
          <Button size="small" onClick={BackToTab}>
            Back
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Button disabled={!selectedSpecId} size="small" onClick={editClick}>
            Next
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Button size="small" onClick={cancelFirst}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InitialPage;
