import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  Menu,
  MenuButton,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { ChevronDownRegular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import MainPage from "./MainPage";
import apiServices from "../../../service";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setFilterSelectionData, startLoading, stopLoading } from "../../../redux/clientSlice";
import OverlayLoader from "../../../components/OverlayLoader";

enum TabValues {
  ExportByCondition = "tab1",
  ExportBySelection = "tab2",
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  fullWidthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "160px",
    overflowX: "auto",
    gap: "3px",
    padding: "5px",
  },
  fullWidthItem: {
    width: "100%",
    justifyContent: "flex-start",
    borderRadius: "3px",
    height: "25px",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e0f7fa",
    },
  },
  selectedItem: {
    backgroundColor: "#cce5ff", // Light blue for selection
    fontWeight: "bold", // Optional for emphasis
  },
});

const MainTab = (props: any) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectionOfLists, setSelectionOfLists] = useState<any>("");
  const [getClientId, setClientId] = useState<string>("");
  const styles = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const filterSelectionData = useSelector(
    (state: RootState) => state.selectionFilter.storedValue
  );
  const listsOfSelection = useSelector((state:RootState)=>state.list.storedValue)
  const [isLoading, setLoading] = useState<boolean>(false);
  const extractGetList = () => {
    setLoading(true); 
    apiServices.extract
      .getExtract()
      .then((res) => {
        const filteredResponse = res.data?.data.filter(
          (item: any) => item.Type === "Selection"
        );
  
        if (filteredResponse.length === 0) {
        } else {
          setSelectionOfLists(filteredResponse);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  useEffect(() => {
    if (props.selectedTab === TabValues.ExportBySelection) {
      extractGetList();
    }
  }, [props.selectedTab]);

  const handleItemClick = (searchId: string) => {
    const selectedItem:any = listsOfSelection.find( 
      (item: any) => item.SearchId === searchId
    );
    props.setSearchIds(searchId);
    props.setTypeOfExtract(selectedItem.Type);
    if (selectedItem) {
      try {
        const condition = JSON.parse(selectedItem.Condition);

        const customerIds = condition || [""];
        setClientId(customerIds);

        setSelectedItem(selectedItem);
      } catch (error) {
        console.error("Error parsing Condition field:", error);
      }
    }
  };

  const handleAddNewSelection = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setSelectedItem(null);
    setMenuOpen(false);
    props.setTabOpen(false);
    props.setSelectedSearch("");
    props.setSelectedSearches([]);
    props.setSelectedTab(TabValues.ExportByCondition);
  };

  const handleConditionApply = () => {
    const searchId = props.searchId;
    if (searchId) {
      props.handleSearchId(searchId);
    } else {
    }
    props.setApplyFilter(true);
    setMenuOpen(false);
    setSelectedItem(null);
    props.setTabOpen(false);
    props.setSelectedTab(TabValues.ExportByCondition);
  };

  const transformClientData = (data: any) => {
    return {
      customer_id: data.CustomerId,
      NameAndAddress: {
        first_names: data.FirstNames,
        last_name: data.LastName,
        title: data.Title,
        initials: data.Initials,
        former_last_name: data.FormerLastName,
        honours: data.Honours,
        known_as: data.KnownAs,
        address_1: data.Address1,
        address_2: data.Address2,
        address_3: data.Address3,
        salutation: data.Salutation,
        joint_salutation: data.JointSalutation,
        joint_address_label: data.JointAddressLabel,
        town: data.Town,
        country: data.Country,
        county: data.County,
      },
      Contact: {
        home_phone: data.HomePhone,
        work_phone: data.WorkPhone,
        fax: data.Fax,
        work_fax: data.WorkFax,
        mobile: data.Mobile,
        work_email: data.WorkEmail,
        email: data.Email,
        email_marketing: data.EmailMarketing,
      },
      Nationality: {
        nationality: data.Nationality,
        place_of_birth: data.PlaceOfBirth,
        uk_residence: data.UKResidence,
        country_of_residence: data.CountryOfResidence,
        country_of_domicile: data.CountryOfDomicile,
      },
      Personal: {
        sex: data.Sex,
        date_of_birth: data.DateOfBirth,
        preferred_retirement_age: data.PreferredRetirementAge,
        state_retirement_date: data.StateRetirementDate,
        pension_life_allowance: data.PensionLifeAllowance,
        marital_status: data.MaritalStatus,
        date_of_marriage: data.DateOfMarriage,
        esg_attitude: data.ESGAttitude,
        esg_attitude_note: data.ESGAttitudeNote,
        will_date: data.WillDate,
        will_note: data.WillNote,
        power_of_attorney: data.PowerOfAttorney,
        deceased: data.Deceased,
        death_date: data.DeathDate,
        education_profession_experience: data.EducationProfessionExperience,
        ni_number: data.NINumber,
        unique_tax_ref: data.UniqueTaxRef,
        tax_ref: data.TaxRef,
        tax_office: data.TaxOffice,
        tax_rate: data.TaxRate,
        marriage_allowance: data.MarriageAllowance,
        vulnerability: data.Vulnerability,
        vulnerability_note: data.VulnerabilityNote,
      },
      HealthNotes: {
        height: data.Height,
        weight: data.Weight,
        state_of_health: data.StateOfHealth,
        health_note: data.HealthNote,
        long_term_care_needed: data.LongTermCareNeeded,
        smoker: data.Smoker,
        smoker_note: data.SmokerNote,
        alcohol_note: data.AlcoholNote,
        hazardous_pursuit_note: data.HazardousPursuitNote,
      },
      Administration: {
        client_type: data.ClientType,
        fsa_client_type: data.FSAClientType,
        client_reference: data.ClientReference,
        client_since: data.ClientSince,
        consultant: data.Consultant,
        administrator: data.ConsultantId,
        introduced_by: data.IntroducedBy,
        review_interval: String(data.ReviewInterval),
        review_date: data.ReviewDate,
        review_note: data.ReviewNote,
        review_assigned: data.ReviewAssigned,
        review_completed: data.ReviewCompleted,
        client_agreement: data.ClientAgreement,
        terms_of_business_sent: data.TermsOfBusinessSent,
        idd: data.Idd,
        fact_find_completed: data.FactfindCompleted,
        menu: data.Menu,
        data_protection_form_sent: data.DataProtectionFormSent,
        risk_profile: data.RiskProfile,
        risk_profile_note: data.RiskProfileNote,
        verification_of_identity: data.VerificationOfIdentity,
        sanctions_check: data.SanctionsCheck,
        note_text: data.NoteText,
        headline_note_popout: data.HeadlineNotePopOut,
      },
    };
  };

  const handleSelectionApply = () => {
    const data = getClientId;

    // setLoading(true);
    props.setTabOpen(false);
    dispatch(startLoading())
    apiServices.exportExcel
      .clientList(data)
      .then((response) => {
        if (response.data && !response.data.isError) {
          const transformedData = response.data.data.map(transformClientData);
          dispatch(setFilterSelectionData(transformedData));
          console.log(transformedData,'transformed data')
        } else {
          console.error("API Response Error:", response.data);
        }
      })
      .catch((e) => {
        console.error("API Error:", e);
      })
      .finally(() => {
        // setLoading(false);
        dispatch(stopLoading())
        setSelectedItem(null);
        props.setSelectedTab(TabValues.ExportByCondition);
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);

    if (props.selectedTab === TabValues.ExportBySelection) {
      setSelectedItem(null);
    }
  };
  useEffect(() => {
    props.getExtracts();
  }, []);
  return (
    <div>
      <Dialog
        open={props.getDialogState}
        onOpenChange={(_, data) => {
          if (!data.open) {
            props.closeMainTabDialog(); // Close dialog when requested
          }
        }}
      >
        {/* <Dialog open={props.tabOpen} onOpenChange={props.getExtracts}> */}

        {/* <DialogTrigger disableButtonEnhancement>
          <Button
            icon={<ChevronDownRegular />}
            iconPosition="after"
            style={{
              fontWeight: 400,
              color: "#606060",
              border: "1px solid grey",
            }}
            onClick={() => setTabOpen(true)}
          >
            Saved Searches
          </Button>
        </DialogTrigger> */}
        <DialogSurface>
        <OverlayLoader isLoading={isLoading} />

          <DialogBody>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>
                  <span style={{ fontSize: "16px", padding: "10px" }}>
                    <b>Extract Type</b>
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "14px", padding: "10px" }}>
                    Choose which type of extract you want to create
                  </span>
                </div>
              </div>

              <br />
              <div className={styles.root}>
                <TabList
                  defaultSelectedValue={TabValues.ExportByCondition}
                  onTabSelect={(event, data) => {
                    props.setSelectedTab(data.value as TabValues);
                    setSelectedItem(null); // Reset item on tab change
                    props.setSelectedSearches([]);
                    setClientId("");
                    props.setSearchIds("");
                  }}
                  {...props}
                >
                  <Tab value={TabValues.ExportByCondition}>
                    Extract by Condition
                  </Tab>
                  <Tab value={TabValues.ExportBySelection}>
                    Extract by Selection
                  </Tab>
                </TabList>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "stretch",
                  padding: "3px",
                  minHeight: "160px",
                }}
              >
                {props.selectedTab === TabValues.ExportByCondition && (
                  <div className={styles.fullWidthContainer}>
                    <span
                      style={{
                        minHeight: "10px",
                        cursor: "pointer",
                        paddingInline: "3px",
                      }}
                    >
                      {props.renderedMenuItems ? props.renderedMenuItems : ""}
                    </span>
                  </div>
                )}

                {props.selectedTab === TabValues.ExportBySelection && (
                  
                  <div className={styles.fullWidthContainer}>

                    {listsOfSelection && listsOfSelection.length > 0
                      ? listsOfSelection.map((item: any) => (
                          <div
                            style={{ minHeight: "30px" }}
                            key={item.SearchId}
                            className={`${styles.fullWidthItem} ${
                              props.searchIds === item.SearchId
                                ? styles.selectedItem
                                : ""
                            }`}
                            onClick={() => handleItemClick(item.SearchId)}
                          >
                            <span
                              style={{
                                paddingInline: "5px",
                                cursor: "pointer",
                              }}
                            >
                              {item.Title}
                            </span>
                          </div>
                        ))
                      : ""}
                  </div>
                )}
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <div>
                  {props.selectedTab === TabValues.ExportByCondition ? (
                    <Button
                      appearance="primary"
                      size="small"
                      onClick={props.handleAddSearchClick}
                    >
                      Add New Condition
                    </Button>
                  ) : (
                    <Button
                      appearance="primary"
                      size="small"
                      onClick={handleAddNewSelection}
                    >
                      Add New Selection
                    </Button>
                  )}
                </div>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Button size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                  {props.selectedTab === TabValues.ExportByCondition ? (
                    <Button
                      size="small"
                      disabled={!props.selectedSearches}
                      onClick={() => {
                        handleConditionApply();
                      }}
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      disabled={!selectedItem}
                      onClick={() => {
                        handleSelectionApply();
                      }}
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <MainPage isOpen={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
};

export default MainTab;
