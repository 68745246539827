import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Option, Spinner } from "@fluentui/react-components";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setClients, setPartners } from "../../redux/clientSlice";
import { setDynamicResponseData } from "../../redux/dynamicResponse";
import {
  formatedDate,
  getAssetCategoryByCaseType,
  getClientType,
  getYes_NoConversion,
} from "../../utils";
import ReactQuillComponent from "../../components/ReactQuillComponent";

type TableData = {
  recordId: any;
  borrower: string;
  liability_type: string;
  provider: string;
  status: string;
  assignedTo: string;
  bank_account_type: string;
  bank_contribution: string;
  bank_date_opened: string;
  bank_frequency: string;
  case_type: string;
  assured_detail: string;
  assured_name1: string;
  income_type: string;
  owner: any;
  frequency: string;
  source: string;
  sort_order: any;
  client_amount: any;
  partner_amount: any;
  joint_amount: any;
  first_names: string;
  financially_dependant: string;
  known_as: string;
  last_name: string;
  address_type: string;
  organisation: string;
  contact_name: string;
  address_1: string;
  address_2: string;
  address_3: string;
  postcode: string;
  address_id: string;
  record_Id: string;
  //
  policy_holder: any;
  subtype: string;
  other_reference: string;
  gross_amount: any;
  net_amount: any;
  start_date: any;
  end_date: any;
  effective_date: any;
  date_of_birth: string;
  relation: string;
  parent: string;

  bank_sort_code: string;
  joint_indicator: any;
  owner_name2: string;
  owner_name1: string;
  current_value: string;
  value_date: string;
  purpose: string;
  note_text: string;
  report_note: string;
  investment_type: string;
  share_type: string;
  investment_subtype: string;
  share_subtype: string;
  investment_account_number: string;
  bank_account_number: string;
  investment_start_date: string;
  investment_end_date: string;
  investment_status: string;
  bank_status: string;
  share_status: string;
  status_date: string;
  service_status: string;
  investment_provider: string;
  provider_contact_name: string;
  provider_phone: string;
  provider_note: string;
  share_company: string;
  company_contact_name: string;
  company_phone: string;
  company_note: string;
  share_current_number: string;
  share_current_price: string;
  acquired_date: string;
  share_original_value: string;
  home_description: string;
  bank_name: string;
  balance_value: string;
  balance_date: string;
  bank_interest_rate: string;
  bank_account_name: string;
  borrower_name1:string;
  borrower_name2:string;
  repayment_method:string;
  term:string;
  amount_borrowed:string;
  amount_outstanding:string;
  amount_outstanding_date:string;
  interest_rate:string;
  interest_basis:string;
  account_number:string;
  mortgage_fixed_rate_end:string;
  property_value:string;
  property_address1:string;
  loan_valuation_percentage:string;
  provider_address1:string;
  property_town:string;
  property_county:string;
  property_country:string;
  provider_town:string;
  provider_county:string;
  provider_country:string;
  property_postcode:string;
  provider_postcode:string;
  covered:string;


  assured_name2:string;
  scheme_name:string;
  policy_number:string;
  premium_indexed:string;
  periodic_benefit:string;
  benefit_frequency:string;
  benefits_indexed:string;
  benefit_text:string;
  admin_note:string;
  link_to_mortgage:any;
  rated:string;
  waiver:string;
  surrender_value:string;
  future_value:string;
  age_admitted:string;
  in_trust:string;
  in_trust_note:string;
  valuation_date:string;
  assigned_to:string;
  contracted_out:string;
  transfer_value:string;
  total_fund_value:string;
  expression_of_wish:string;
  lump_sum_benefit:any;
  sum_assured:string;
  expression_of_wishnote:string;

};

export const Example = () => {
  const [assignedTo, setAssignedTo] = useState<any>();
  const [dropdownValue, setDropDownValue] = useState("Not Assigned");
  const dispatch: AppDispatch = useDispatch();
  const tableDatas = useSelector(
    (state: any) => state.tableData.storedValue || []
  );
  const tableNames = useSelector((state: any) => state.headerValue.storedValue);
  const partnerId = useSelector((state: any) => state.partnerId.storedValue);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const clientValue = useSelector((state: any) => state.clientid.storedValue);
  const loader = useSelector((state: any) => state.loading.loadingTableData);
  const dynamicResponseData = useSelector(
    (state: any) => state.splitData.dynamicResponseData
  );
  useEffect(() => {
    if (Array.isArray(tableDatas)) {
      const updatedData = tableDatas.map((item: any) => ({
        ...item,
        assignedTo: item.assignedTo || "Not Assigned",
      }));
      setTableData(updatedData);
    } else {
      setTableData([]);
    }
  }, [tableDatas, tableNames]);

  const handleDropdownChange = (value: string, rowIndex: number) => {
    const updatedData = [...tableData];

    updatedData[rowIndex].assignedTo = value || "Not Assigned";

    setTableData(updatedData);

    const currentTableName = tableNames.toLowerCase();

    const dynamicResponse = updatedData.flatMap((item) => {
      if (item.assignedTo === "Both") {
        return [
          {
            ...item,
            assignedTo: "Client",
            assignmentType: item.assignedTo,
            tableName: tableNames,
          },
          {
            ...item,
            assignedTo: "Partner",
            assignmentType: item.assignedTo,
            tableName: tableNames,
          },
        ];
      }
      return [
        {
          ...item,
          assignedTo: item.assignedTo,
          assignmentType: item.assignedTo,
          tableName: tableNames,
        },
      ];

      if (item.assignedTo === "Client" || item.assignedTo === "Partner") {
        return [
          {
            ...item,
            assignedTo: item.assignedTo,
            assignmentType:
              item.assignedTo === "Client" ? clientValue : partnerId,
            tableName: tableNames,
          },
        ];
      }

      return [];
    });

    const previousData = dynamicResponseData[currentTableName] || [];

    const filteredData = previousData.filter(
      (existingItem: any) =>
        existingItem.recordId !== updatedData[rowIndex].recordId
    );

    const newTableData = [...filteredData, ...dynamicResponse];

    const uniqueTableData = newTableData.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.recordId === item.recordId && t.assignedTo === item.assignedTo
        )
    );

    const newDynamicResponseData = {
      ...dynamicResponseData,
      [currentTableName]: uniqueTableData,
    };

    dispatch(setDynamicResponseData(newDynamicResponseData));
  };

  const columns = useMemo<MRT_ColumnDef<TableData>[]>(() => {
    const dropdownCell = ({ row }: { row: any }) => {
      const rowIndex = row.index;
      const recordId = tableData[rowIndex]?.recordId;
      const tableSpecificData =
        dynamicResponseData?.[tableNames.toLowerCase()] || [];
      const assignedToValue =
        tableSpecificData.find((item: any) => item.recordId === recordId)
          ?.assignmentType || "Not Assigned";

      return (
        <Dropdown
          key={rowIndex}
          placeholder="Select an option"
          value={assignedToValue}
          onOptionSelect={(_, data) => {
            handleDropdownChange(data.optionValue as string, rowIndex);
          }}
        >
          <Option value="Not Assigned">Not Assigned</Option>
          <Option value="Client">Client</Option>
          <Option value="Partner">Partner</Option>
          <Option value="Both">Both</Option>
        </Dropdown>
      );
    };

    if (tableNames === "Liability") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
      {
        accessorKey: "case_type",
        header: "Liability Category",
      },
      {
        accessorKey: "joint_indicator",
        header: "Owner",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.joint_indicator)}</>
        ),
      },
      {
        accessorKey: "borrower",
        header: "Borrower",
      },
      {
        accessorKey: "borrower_name1", //access nested data with dot notation
        header: "Borrower Name1",
      },
      {
        accessorKey: "borrower_name2",
        header: "Borrower Name2",
      },
      {
        accessorKey: "liability_type",
        header: "Type",
      },
      {
        accessorKey: "subtype",
        header: "Subtype",
      },
      {
              accessorKey: "repayment_method",
              header: "Repayment Method",
            },
            {
              accessorKey: "purpose",
              header: "Purpose",
            },
            {
              accessorKey: "start_date",
              header: "Start Date",
              Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
            },
            {
              accessorKey: "end_date",
              header: "End Date",
              Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
            },
            {
              accessorKey: "term",
              header: "Term",
            },
            {
              accessorKey: "amount_borrowed",
              header: "Amount Borrowed",
            },
            {
              accessorKey: "amount_outstanding",
              header: "Amount Outstanding",
            },
            {
              accessorKey: "amount_outstanding_date",
              header: "Outstanding Date",
              Cell: ({ row }: any) => (
                <>{formatedDate(row.original.amount_outstanding_date)}</>
              ),
            },
            {
              accessorKey: "interest_rate",
              header: "Interest Rate",
            },
            {
              accessorKey: "interest_basis",
              header: "Interest Basis",
            },
      
            {
              accessorKey: "status",
              header: "Status",
            },
            {
              accessorKey: "status_date",
              header: "Status Date",
              Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
            },
            {
              accessorKey: "note_text",
              header: "Admin Note",
              Cell: ({ row }: any) => (
                <>
                  <ReactQuillComponent value={row.original.note_text} />
                </>
              ),
            },
            {
              accessorKey: "report_note",
              header: "Report Note",
              Cell: ({ row }: any) => (
                <>
                  <ReactQuillComponent value={row.original.report_note} />
                </>
              ),
            },
            {
                    accessorKey: "account_number",
                    header: "Account Number",
                  },
                  {
                    accessorKey: "mortgage_fixed_rate_end",
                    header: "End of Fixed Rate",
                    Cell: ({ row }: any) => (
                      <>{formatedDate(row.original.mortgage_fixed_rate_end)}</>
                    ),
                  },
                  {
                    accessorKey: "property_value",
                    header: "Property value",
                  },
                  {
                    accessorKey: "property_address1",
                    header: "Property Address",
                    Cell: ({ row }: any) => (
                      <p>
                        {row.original.property_address1}
                        <br />
                        {row.original.property_address2}
                        <br />
                        {row.original.property_address3}
                      </p>
                    ),
                  },
                  {
                    accessorKey: "loan_valuation_percentage",
                    header: "Loan to valuation %",
                  },
            
                  {
                    accessorKey: "provider",
                    header: "Provider",
                  },
                  {
                    accessorKey: "provider_contact_name",
                    header: "Provider Contact",
                  },
                  {
                    accessorKey: "provider_phone",
                    header: "Provider Phone",
                  },
                  {
                    accessorKey: "provider_address1",
                    header: "Provider Address",
                    Cell: ({ row }: any) => (
                      <p>
                        {row.original.provider_address1}
                        <br />
                        {row.original.provider_address2}
                        <br />
                        {row.original.provider_address3}
                      </p>
                    ),
                  },
                  {
                    accessorKey: "property_town",
                    header: "Property Town",
                  },
                  {
                    accessorKey: "property_county",
                    header: "Property County",
                  },
                  {
                    accessorKey: "property_country",
                    header: "Property Country",
                  },
                  {
                    accessorKey: "provider_town",
                    header: "Provider Town",
                  },
                  {
                    accessorKey: "provider_county",
                    header: "Provider Country",
                  },
                  {
                    accessorKey: "provider_country",
                    header: "Provider Country",
                  },
                  {
                    accessorKey: "property_postcode",
                    header: "Property Postcode",
                  },
                  {
                    accessorKey: "provider_postcode",
                    header: "Provider Postcode",
                  },
                  {
                    accessorKey: "provider_note",
                    header: "Provider Note",
                    Cell: ({ row }: any) => (
                      <>
                        <ReactQuillComponent value={row.original.provider_note} />
                      </>
                    ),
                  },
                  {
                    accessorKey: "covered",
                    header: "Premium Covered",
                    Cell: ({ row }: any) =>
                      row.original.case_type !== "Mortgages" ? row.original.covered : "",
                  },
                  {
                    accessorKey: "covered",
                    header: "Covered by Insurance",
                    Cell: ({ row }: any) =>
                      row.original.case_type === "Mortgages" ? row.original.covered : "",
                  },
      ];
    } else if (tableNames === "Asset") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        {
          accessorKey: "case_type",
          header: "Asset Category",
          Cell: ({ renderedCellValue, row }: any) => (
            <>{getAssetCategoryByCaseType(row.original.case_type)}</>
          ),
        },
        { accessorKey: "bank_sort_code", header: "Sort Code" },
        {
          accessorKey: "joint_indicator",
          header: "Owner",
          Cell: ({ renderedCellValue, row }: any) => (
            <>{getClientType(row.original.joint_indicator)}</>
          ),
        },
        { accessorKey: "owner", header: "Owner Detail" },
        { accessorKey: "owner_name1", header: "Owner Detail" },
        { accessorKey: "owner_name2", header: "Owner Name2" },
        { accessorKey: "current_value", header: "Current Value" },
        {
          accessorKey: "value_date",
          header: "Value Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        { accessorKey: "purpose", header: "Purpose" },
        {
          accessorKey: "note_text",
          header: "Admin Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.note_text} />
            </>
          ),
        },
        {
          accessorKey: "report_note",
          header: "Report Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.report_note} />
            </>
          ),
        },
        {
          accessorKey: "investment_type" || "share_type",
          header: "Type",
          Cell: ({ row }: any) => (
            <>{row.original.investment_type || row.original.share_type}</>
          ),
        },
        {
          accessorKey: "investment_subtype" || "share_subtype",
          header: "Subtype",
          Cell: ({ row }: any) => (
            <>{row.original.investment_subtype || row.original.share_subtype}</>
          ),
        },
        {
          accessorKey: "investment_account_number" || "bank_account_number",
          header: "Account Number",
          Cell: ({ row }: any) => (
            <>
              {row.original.investment_account_number ||
                row.original.bank_account_number}
            </>
          ),
        },
        {
          accessorKey: "investment_start_date",
          header: "Start Date",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.investment_start_date)}</>
          ),
        },
        {
          accessorKey: "investment_end_date",
          header: "End Date",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.investment_end_date)}</>
          ),
        },
        {
          accessorKey: "investment_status" || "bank_status" || "share_status",
          header: "Status",
          Cell: ({ row }: any) => (
            <>
              {row.original.investment_status ||
                row.original.bank_status ||
                row.original.share_status}
            </>
          ),
        },
        {
          accessorKey: "status_date",
          header: "Status Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
        },
        {
          accessorKey: "service_status",
          header: "Service Status",
          Cell: ({ row }: any) => (
            <>
              {row.original.case_type === "Home/Personal"
                ? ""
                : row.original.service_status}
            </>
          ),
        },
        {
          accessorKey: "investment_provider",
          header: "Provider",
        },
        {
          accessorKey: "provider_contact_name",
          header: "Provider Contact",
        },
        {
          accessorKey: "provider_phone",
          header: "Provider Phone",
        },
        {
          accessorKey: "provider_note",
          header: "Provider Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.provider_note} />
            </>
          ),
        },
        {
          accessorKey: "share_company",
          header: "Company",
        },
        {
          accessorKey: "company_contact_name",
          header: "Company Contact",
          Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
        },
        {
          accessorKey: "company_phone",
          header: "Company Phone",
          Cell: ({ row }: any) => <>{row.original.provider_phone}</>,
        },
        {
          accessorKey: "company_note",
          header: "Company Note",
          Cell: ({ row }: any) => (
            <>
              <ReactQuillComponent value={row.original.provider_note} />
            </>
          ),
        },
        {
          accessorKey: "share_current_number",
          header: "Quantity",
        },
        {
          accessorKey: "share_current_price",
          header: "Current Price",
        },
        {
          accessorKey: "acquired_date",
          header: "Date Acquired",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        {
          accessorKey: "share_original_value",
          header: "Original Value",
          Cell: ({ row }: any) => (
            <>
              {row.original.case_type === "Home/Personal"
                ? row.original.home_original_value
                : row.original.share_original_value}
            </>
          ),
        },
        {
          accessorKey: "home_description",
          header: "Description",
        },
        {
          accessorKey: "bank_account_type",
          header: "Account Type",
        },
        {
          accessorKey: "bank_name",
          header: "Bank Name",
        },
        {
          accessorKey: "contact_name",
          header: "Contact",
          Cell: ({ row }: any) => <>{row.original.provider_contact_name}</>,
        },
        {
          accessorKey: "bank_account_name",
          header: "Account Name",
        },
        {
          accessorKey: "balance_value",
          header: "Balance",
          Cell: ({ row }: any) => <>{row.original.current_value}</>,
        },
        {
          accessorKey: "balance_date",
          header: "Balance Date",
          Cell: ({ row }: any) => <>{formatedDate(row.original.value_date)}</>,
        },
        {
          accessorKey: "bank_interest_rate",
          header: "Interest Rate",
        },
        {
          accessorKey: "bank_date_opened",
          header: "Date Opened",
          Cell: ({ row }: any) => (
            <>{formatedDate(row.original.bank_date_opened)}</>
          ),
        },
      ];
    } else if (tableNames === "Policy") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        {
                accessorKey: "case_type",
                header: "Policy Category",
              },
              {
                accessorKey: "policy_holder",
                header: "Policyholder",
                Cell: ({ renderedCellValue, row }) => (
                  <>{getClientType(row.original.policy_holder)}</>
                ),
              },
              {
                accessorKey: "assured_detail",
                header: "Assured Detail",
              },
              {
                accessorKey: "assured_name1",
                header: "Assured Name1",
              },
              {
                accessorKey: "assured_name2",
                header: "Assured Name2",
              },
              {
                accessorKey: "subtype",
                header: "Subtype",
              },
              {
                accessorKey: "provider",
                header: "Provider",
              },
              {
                accessorKey: "provider_contact_name",
                header: "Provider Contact",
              },
              {
                accessorKey: "provider_phone",
                header: "Provider Phone",
              },
              {
                accessorKey: "provider_note",
                header: "Provider Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.provider_note} />
                  </>
                ),
              },
              {
                accessorKey: "purpose",
                header: "Purpose",
              },
              {
                accessorKey: "start_date",
                header: "Start Date",
                Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
              },
              {
                accessorKey: "end_date",
                header: "End Date",
                Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
              },
              {
                accessorKey: "term",
                header: "Term",
              },
              {
                accessorKey: "scheme_name",
                header: "Scheme Name",
              },
              {
                accessorKey: "policy_number",
                header: "Policy Number",
              },
              {
                accessorKey: "other_reference",
                header: "Other Reference",
              },
              {
                accessorKey: "status",
                header: "Status",
              },
              {
                accessorKey: "status_date",
                header: "Status Date",
                Cell: ({ row }: any) => <>{formatedDate(row.original.status_date)}</>,
              },
              {
                accessorKey: "service_status",
                header: "Service Status",
              },
              {
                accessorKey: "premium_indexed",
                header: "Premium Indexed",
                Cell: ({ row }: any) => <>{row.original.service_status}</>,
              }, 
              {
                accessorKey: "periodic_benefit",
                header: "Periodic Benefit",
              },
              {
                accessorKey: "benefit_frequency",
                header: "Benefit Frequency",
              },
              {
                accessorKey: "benefits_indexed",
                header: "Benefits Indexed",
              },
              {
                accessorKey: "benefit_text",
                header: "Benefit Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.benefit_text} />
                  </>
                ),
              },
              {
                accessorKey: "admin_note",
                header: "Admin Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.admin_note} />
                  </>
                ),
              },
              {
                accessorKey: "report_note",
                header: "Report Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.report_note} />
                  </>
                ),
              },
              {
                accessorKey: "link_to_mortgage",
                header: "Linked to Mortgage",
                Cell: ({ renderedCellValue, row }) => (
                  <>{getYes_NoConversion(row.original.link_to_mortgage)}</>
                ),
              },
              {
                accessorKey: "rated",
                header: "Rate",
              },
              {
                accessorKey: "waiver",
                header: "Waiver",
              },
              {
                accessorKey: "surrender_value",
                header: "Surrender Value",
              },
              {
                accessorKey: "future_value",
                header: "Future Value",
              },
              {
                accessorKey: "age_admitted",
                header: "Age Admitted",
              },
        
              {
                accessorKey: "in_trust",
                header: "In trust",
              },
              {
                accessorKey: "in_trust_note",
                header: "In Trust Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.in_trust_note} />
                  </>
                ),
              },
              {
                accessorKey: "valuation_date",
                header: "Valuation Date",
                Cell: ({ row }: any) => (
                  <>{formatedDate(row.original.valuation_date)}</>
                ),
              },
              {
                accessorKey: "assigned_to",
                header: "Assigned To",
              },
              {
                accessorKey: "contracted_out",
                header: "Contracted Out",
              },
              {
                accessorKey: "transfer_value",
                header: "Transfer Value",
              },
              {
                accessorKey: "total_fund_value",
                header: "Total Fund Value",
              },
              {
                accessorKey: "expression_of_wish",
                header: "Expression of Wish",
              },
              {
                accessorKey: "lump_sum_benefit",
                header: "Lump Sum Benefit",
                Cell: ({ row }: any) => (
                  <>
                    {row.original.case_type !== "Life Assurance"
                      ? row.original.lump_sum_benefit
                      : null}
                  </>
                ),
              },
              {
                accessorKey: "sum_assured",
                header: "Sum Assured",
                Cell: ({ row }: any) => (
                  <>
                    {row.original.case_type === "Life Assurance"
                      ? row.original.lump_sum_benefit
                      : null}
                  </>
                ),
              },
        
              {
                accessorKey: "expression_of_wishnote",
                header: "Expression of Wish Note",
                Cell: ({ row }: any) => (
                  <>
                    <ReactQuillComponent value={row.original.expression_of_wishnote} />
                  </>
                ),
              },
        
      ];
    } else if (tableNames === "Income") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
      {
        accessorKey: "owner",
        header: "Owner",
        Cell: ({ renderedCellValue, row }) => (
          <>{getClientType(row.original.owner)}</>
        ),
      },
        { accessorKey: "income_type", header: "Type" },
        { accessorKey: "gross_amount", header: "Gross Amount" },
        { accessorKey: "net_amount", header: "Net Amount" },
        { accessorKey: "frequency", header: "Frequency" },
            {
              accessorKey: "start_date",
              header: "Start Date",
              Cell: ({ row }: any) => <>{formatedDate(row.original.start_date)}</>,
            },
            {
              accessorKey: "end_date",
              header: "End Date",
              Cell: ({ row }: any) => <>{formatedDate(row.original.end_date)}</>,
            },
      ];
    } else if (tableNames === "Expense") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "sort_order", header: "Sort Order" },
        { accessorKey: "client_amount", header: "Client Amount" },
        { accessorKey: "partner_amount", header: "Partner Amount" },
        { accessorKey: "joint_amount", header: "Joint Amount" },
        { accessorKey: "frequency", header: "Frequency" },
      ];
    } else if (tableNames === "Dependant") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "first_names", header: "First Name" },
        { accessorKey: "last_name", header: "Last Name" },
        { accessorKey: "date_of_birth", header: "Date of Birth" },
        { accessorKey: "relation", header: "Relation" },
        { accessorKey: "parent", header: "Parent" },
      ];
    } else if (tableNames === "Address") {
      return [
        {
          accessorKey: "assignedTo",
          header: "Assigned To",
          Cell: dropdownCell,
        },
        { accessorKey: "organisation", header: "Organisation" },
        { accessorKey: "contact_name", header: "Contact Name" },
        { accessorKey: "address_1", header: "Address 1" },
        { accessorKey: "postcode", header: "Postcode" },
        { accessorKey: "effective_date", header: "Effective Date" },
      ];
    }

    return [];  
  }, [tableData, tableNames]);

  return (
    <>
      {loader ? (
        <div className="spinner-container">
          <Spinner size="medium" />
        </div>
      ) : (
        <div
          style={{
            marginTop: "-56px",
            overflow: "hidden",
          }}
        >
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={true}
            initialState={{
              pagination: {
                pageSize: 5, // Set initial rows per page to 5
                pageIndex: 0, // Set initial page index to 0 (first page)
              },
            }}
            enableSorting={false}
            enableGlobalFilter={false}
            enableColumnOrdering={false}
            enableColumnResizing={false}
            enableColumnFilterModes={false}
            enableExpandAll={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableFilters={false}
            enableHiding={false}
            muiTableBodyCellProps={({ column }) => ({
              style: {
                padding: "6px 12px",
                fontSize: "0.875rem",
                overflow: "visible",
                ...(column.id === "assignedTo" && {
                  width: "280px",
                  maxWidth: "280px",
                }),
              },
            })}
            muiTableProps={{
              style: {
                margin: "0",
                padding: "0",
                border: "1px solid rgba(81, 81, 81, .5)",
                overflow: "visible",
                position: "relative",
              },
            }}
            muiTableContainerProps={{
              style: {
                minHeight: "200px",
                maxHeight: "400px",
                overflow: "auto",
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Example;
