import { Dispatch, SetStateAction, useEffect } from "react";
import { Outlet } from "react-router-dom";
import CommissionSidebar from "../CommissionSidebar/Sidebar";
import Breadcrumbs from "../../../layout/breadcrumb/Breadcrumbs";
interface PropsType {
  setTabs: Dispatch<SetStateAction<string>>;
}

const CommissionLayout = (props: any) => {
  useEffect(() => {
    props.setTabs("receipting");
  }, []);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <aside
        style={{
          width: "230px",

          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <CommissionSidebar />
      </aside>
      <main style={{ flex: 1, padding: "0.6rem", overflowY: "auto" }}>
        <Breadcrumbs />
        <Outlet />
      </main>
    </div>
  );
};

export default CommissionLayout;
