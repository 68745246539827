import React, { useEffect, useState, useRef } from "react";
import {
  Hamburger,
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavItem,
  NavSubItem,
  NavSubItemGroup,
  NavDrawerProps,
  NavItemValue,
  OnNavItemSelectData,
} from "@fluentui/react-nav-preview";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { makeStyles, tokens, Tooltip } from "@fluentui/react-components";
import apiServices from "../../service";
import { removeEmptyValues } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "calc(100vh - 50px)",
    width: "200px",
  },
  content: {
    flex: "1",
    padding: "16px",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "4px",
    marginLeft: "8px",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
  disabledNavItem: {
    color: tokens.colorNeutralForegroundDisabled,
    pointerEvents: "none",
    opacity: 0.9,
  },
});

function ClientPageSidebar(
  props: Partial<NavDrawerProps> & { [key: string]: any }
) {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, assetId, mode } = useParams();
  const pathSegments = location.pathname.split("/");
  const activeClient: any = useSelector(
    (state: RootState) => state.activeClient
  );
  const [selectedMenu, setSelectedMenu] = useState<string>("");
  const [selectedCategoryValue, setSelectedCategoryValue] = useState<
    string | undefined
  >("");
  const [openCategories, setOpenCategories] = useState<NavItemValue[]>([]);

  const refs: any = {
    "client-summary": useRef(null),
    "case-summary": useRef(null),
    "contact-history": useRef(null),
    "name-and-address": useRef(null),
    contact: useRef(null),
    nationality: useRef(null),
    personal: useRef(null),
    "health-note": useRef(null),
    vulnerability: useRef(null),
    employment: useRef(null),
    "attitude-to-risk": useRef(null),
    identity: useRef(null),
    objectives: useRef(null),
    administration: useRef(null),
    compliance: useRef(null),
    "service-type": useRef(null),
    "user-defined": useRef(null),
    "partner-name-and-address": useRef(null),
    "partner-contact": useRef(null),
    "partner-nationality": useRef(null),
    "partner-personal": useRef(null),
    "partner-health-note": useRef(null),
    "partner-vulnerability": useRef(null),
    "partner-employment": useRef(null),
    "partner-attitude-to-risk": useRef(null),
    "partner-identity": useRef(null),
    "partner-objectives": useRef(null),

    "partner-service-type": useRef(null),
    dependant: useRef(null),

    income: useRef(null),
    outgoings: useRef(null),
    "address-book": useRef(null),
    assets: useRef(null),
    liabilities: useRef(null),
    policies: useRef(null),
    "fact-find-notes": useRef(null),
    "client-action": useRef(null),
    notes: useRef(null),
    "time-allocation": useRef(null),
    documents: useRef(null),
    "fees-charges": useRef(null),
  };

  const [clientInfo, setClientInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [hasPartner, setHasPartner] = useState<boolean>(false);
  const [isDisableMenu, setIsDisableMenu] = useState<boolean>(false);
  const getClientId = "";
  useEffect(() => {
    if (pathSegments && pathSegments.length > 3) {
      if (pathSegments.includes("partner")) {
        setSelectedMenu(`partner-${pathSegments[4]}`);
        setSelectedCategoryValue("partner");
        setOpenCategories([...openCategories, "partner"]);
      } else if (pathSegments.includes("contacts")) {
        setSelectedMenu(`${pathSegments[4]}`);
        setSelectedCategoryValue("contacts");
        setOpenCategories([...openCategories, "contacts"]);
      } else {
        setSelectedMenu(pathSegments[3]);
        if (
          [
            "name-and-address",
            "contact",
            "nationality",
            "personal",
            "health-note",
            "vulnerability",
            "employment",
            "attitude-to-risk",
            "identity",
            "objectives",
            "service-type",
            "user-defined",
            "administration",
            "compliance",
          ].includes(pathSegments[3])
        ) {
          setOpenCategories([...openCategories, "client"]);
          setSelectedCategoryValue("client");
        } else {
          setOpenCategories([...openCategories]);
          setSelectedCategoryValue("");
        }
      }
    }
  }, [pathSegments]);
  useEffect(() => {
    if (location.pathname == "/clients/add") {
      setIsDisableMenu(true);
    }
  }, [location]);
  useEffect(() => {
    Object.keys(refs).forEach((key) => {
      if (
        (pathSegments.includes(key) || pathSegments.includes("partner")) &&
        refs[key]?.current
      ) {
        refs[key].current.focus();
      }
    });
  }, [location, clientId]);

  useEffect(() => {
    if (
      activeClient.basicDetails &&
      Object.keys(activeClient.basicDetails).length !== 0 &&
      activeClient.basicDetails.associated_type == 0 &&
      activeClient.basicDetails.associated_with !== null &&
      activeClient.basicDetails.associated_with !== ""
    ) {
      setHasPartner(true);
    }
  }, [activeClient]);

  const handleItemSelect = (
    ev: Event | React.SyntheticEvent<Element, Event>,
    data: OnNavItemSelectData
  ) => {
    setSelectedCategoryValue(data.categoryValue as string);
    setSelectedMenu(data.value as string);
  };
  const handleCategoryToggle = (
    ev: Event | React.SyntheticEvent<Element, Event>,
    data: OnNavItemSelectData
  ) => {
    // if it's already open, remove it from the list
    if (openCategories.includes(data.categoryValue as string)) {
      setOpenCategories([
        ...openCategories.filter((category) => category !== data.categoryValue),
      ]);
    } else {
      // otherwise add it
      setOpenCategories([...openCategories, data.categoryValue as string]);
    }
  };
  return (
    <div className={styles.root}>
      <NavDrawer
        type="inline"
        size="medium"
        open={true}
        style={{ overflow: "auto" }}
        onNavItemSelect={handleItemSelect}
        onNavCategoryItemToggle={handleCategoryToggle}
        selectedValue={selectedMenu}
        openCategories={openCategories}
        selectedCategoryValue={selectedCategoryValue}
        multiple={true}
      >
        <NavDrawerHeader>{props.renderIcon}</NavDrawerHeader>
        <NavDrawerBody className="sideNavBody">
          <NavItem
            value="client-summary"
            ref={refs["client-summary"]}
            onClick={() => navigate(`/clients/${clientId}/client-summary`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Client Summary
          </NavItem>
          <NavItem
            value="case-summary"
            ref={refs["case-summary"]}
            onClick={() => navigate(`/clients/${clientId}/case-summary`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Case Summary
          </NavItem>
          <NavItem
            value="contact-history"
            ref={refs["contact-history"]}
            onClick={() => navigate(`/clients/${clientId}/contact-history`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Contact History
          </NavItem>
          <NavDivider />

          <NavCategory value="client">
            <NavCategoryItem>Client</NavCategoryItem>
            <NavSubItemGroup>
              <NavSubItem
                value="name-and-address"
                ref={refs["name-and-address"]}
                onClick={
                  isDisableMenu
                    ? undefined
                    : () => {
                        navigate(`/clients/${clientId}/name-and-address/view`);
                      }
                }
              >
                Name and Address
              </NavSubItem>
              <NavSubItem
                value="contact"
                ref={refs["contact"]}
                onClick={() => navigate(`/clients/${clientId}/contact/view`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Contact
              </NavSubItem>
              <NavSubItem
                value="nationality"
                ref={refs["nationality"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/nationality/view`)
                }
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Nationality
              </NavSubItem>
              <NavSubItem
                value="personal"
                ref={refs["personal"]}
                onClick={() => navigate(`/clients/${clientId}/personal/view`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Personal
              </NavSubItem>
              <NavSubItem
                value="health-note"
                ref={refs["health-note"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/health-note/view`)
                }
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Health Note
              </NavSubItem>
              <NavSubItem
                value="vulnerability"
                ref={refs["vulnerability"]}
                onClick={() => navigate(`/clients/${clientId}/vulnerability`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Vulnerability
              </NavSubItem>
              <NavSubItem
                value="employment"
                ref={refs["employment"]}
                onClick={() => navigate(`/clients/${clientId}/employment`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Employment
              </NavSubItem>
              <NavSubItem
                value="attitude-to-risk"
                ref={refs["attitude-to-risk"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/attitude-to-risk`)
                }
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Attitude to Risk
              </NavSubItem>
              <NavSubItem
                value="identity"
                ref={refs["identity"]}
                onClick={() => navigate(`/clients/${clientId}/identity`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Identity
              </NavSubItem>
              <NavSubItem
                value="objectives"
                ref={refs["objectives"]}
                onClick={() => navigate(`/clients/${clientId}/objectives`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Objectives
              </NavSubItem>
              <NavSubItem
                value="administration"
                ref={refs["administration"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/administration/view`)
                }
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Administration
              </NavSubItem>
              <NavSubItem
                value="compliance"
                ref={refs["compliance"]}
                onClick={() => navigate(`/clients/${clientId}/compliance/view`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Compliance
              </NavSubItem>
              <NavSubItem
                value="service-type"
                ref={refs["service-type"]}
                onClick={() => navigate(`/clients/${clientId}/service-type`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                Service Type
              </NavSubItem>
              <NavSubItem
                value="user-defined"
                ref={refs["user-defined"]}
                onClick={() => navigate(`/clients/${clientId}/user-defined`)}
                disabled={isDisableMenu ? true : false}
                className={isDisableMenu ? styles.disabledNavItem : undefined}
              >
                User Defined
              </NavSubItem>
            </NavSubItemGroup>
          </NavCategory>
          <NavCategory value="partner">
            <NavCategoryItem
              disabled={isDisableMenu ? true : false}
              className={isDisableMenu ? styles.disabledNavItem : undefined}
            >
              Partner
            </NavCategoryItem>
            <NavSubItemGroup>
              <NavSubItem
                value="partner-name-and-address"
                ref={refs["partner-name-and-address"]}
                onClick={() =>
                  navigate(
                    `/clients/${clientId}/partner/name-and-address/${
                      hasPartner ? "view" : "add"
                    }`
                  )
                }
              >
                Name and Address
              </NavSubItem>
              <NavSubItem
                value="partner-contact"
                ref={refs["partner-contact"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/contact/view`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Contact
              </NavSubItem>
              <NavSubItem
                value="partner-nationality"
                ref={refs["partner-nationality"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/nationality/view`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Nationality
              </NavSubItem>
              <NavSubItem
                value="partner-personal"
                ref={refs["partner-personal"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/personal/view`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Personal
              </NavSubItem>
              <NavSubItem
                value="partner-health-note"
                ref={refs["partner-health-note"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/health-note/view`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Health Note
              </NavSubItem>
              <NavSubItem
                value="partner-vulnerability"
                ref={refs["partner-vulnerability"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/vulnerability`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Vulnerability
              </NavSubItem>
              <NavSubItem
                value="partner-employment"
                ref={refs["partner-employment"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/employment`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Employment
              </NavSubItem>
              <NavSubItem
                value="partner-attitude-to-risk"
                ref={refs["partner-attitude-to-risk"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/attitude-to-risk`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Attitude to Risk
              </NavSubItem>
              <NavSubItem
                value="partner-identity"
                ref={refs["partner-identity"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/identity`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Identity
              </NavSubItem>
              <NavSubItem
                value="partner-objectives"
                ref={refs["partner-objectives"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/objectives`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Objectives
              </NavSubItem>
              <NavSubItem
                value="partner-service-type"
                ref={refs["partner-service-type"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/partner/service-type`)
                }
                disabled={hasPartner ? false : true}
                className={hasPartner ? undefined : styles.disabledNavItem}
              >
                Service Type
              </NavSubItem>
            </NavSubItemGroup>
          </NavCategory>
          <NavItem
            value="dependant"
            ref={refs["dependant"]}
            onClick={() => navigate(`/clients/${clientId}/dependant`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Dependants
          </NavItem>
          <NavDivider />
          <NavItem
            value="income"
            ref={refs["income"]}
            onClick={() => navigate(`/clients/${clientId}/income`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Income
          </NavItem>
          <NavItem
            value="outgoings"
            ref={refs["outgoings"]}
            onClick={() => navigate(`/clients/${clientId}/outgoings`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Outgoings
          </NavItem>
          <NavItem
            value="address-book"
            ref={refs["address-book"]}
            onClick={() => navigate(`/clients/${clientId}/address-book`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Address Book
          </NavItem>
          <NavItem
            value="assets"
            ref={refs["assets"]}
            onClick={() => navigate(`/clients/${clientId}/assets`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Assets
          </NavItem>
          <NavItem
            value="liabilities"
            ref={refs["liabilities"]}
            onClick={() => navigate(`/clients/${clientId}/liabilities`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Liabilities
          </NavItem>
          <NavItem
            value="policies"
            ref={refs["policies"]}
            onClick={() => navigate(`/clients/${clientId}/policies`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Policies
          </NavItem>
          <NavItem
            value="fact-find-notes"
            ref={refs["fact-find-notes"]}
            onClick={() => navigate(`/clients/${clientId}/fact-find-notes`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Fact-find Note
          </NavItem>
          <NavItem
            value="fees-charges"
            ref={refs["fees-charges"]}
            onClick={() => navigate(`/clients/${clientId}/fees-charges`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Fees/Charges
          </NavItem>
          <NavDivider />
          <NavCategory value="contacts">
            <NavCategoryItem
              disabled={isDisableMenu ? true : false}
              className={isDisableMenu ? styles.disabledNavItem : undefined}
            >
              Contacts
            </NavCategoryItem>
            <NavSubItemGroup>
              <NavSubItem
                value="client-action"
                ref={refs["client-action"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/contacts/client-action`)
                }
              >
                Client Action
              </NavSubItem>
              <NavSubItem
                value="notes"
                ref={refs["notes"]}
                onClick={() => navigate(`/clients/${clientId}/contacts/notes`)}
              >
                Notes
              </NavSubItem>
              <NavSubItem
                value="time-allocation"
                ref={refs["time-allocation"]}
                onClick={() =>
                  navigate(`/clients/${clientId}/contacts/time-allocation`)
                }
              >
                Time Allocation
              </NavSubItem>
            </NavSubItemGroup>
          </NavCategory>
          <NavItem
            value="documents"
            ref={refs["documents"]}
            onClick={() => navigate(`/clients/${clientId}/documents`)}
            disabled={isDisableMenu ? true : false}
            className={isDisableMenu ? styles.disabledNavItem : undefined}
          >
            Documents
          </NavItem>
        </NavDrawerBody>
      </NavDrawer>
    </div>
  );
}

export default ClientPageSidebar;
