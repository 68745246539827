import { Badge, Button, Label, Tooltip } from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_RowSelectionState,
  MRT_PaginationState,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Edit16Filled } from "@fluentui/react-icons";
import ConfirmationPopup from "./ConfirmationPopup";
import StopcommissionPopup from "./StopcommissionPopup";
import { Input } from "@fluentui/react-components";
import FilterTab from "./FilterTab";
import apiServices from "../../../service";
import { DataRow } from "../CommissionTypes/ReceiptlistType";
import RecieptForm from "./RecieptForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { setFilteredData } from "../../../redux/Commission/commissionSlice";
import SavePopup from "./savePopup";

const ReceiptList = (props: any) => {
  const [confirmationPopup, setConfirmationPopupPopup] = useState(false);
  const [editpopup, setEditpopup] = useState(false);
  const [stopReceiptpopup, setStopReceiptpopup] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [amountData, setAmountData] = useState("");
  const [selectedRow, setSelectedRow] = useState<MRT_RowSelectionState>({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [receiptEditpopup, setReceiptEditpopup] = useState(false);
  const [provider, setProvider] = useState([]);
  const [activeRowItem, setActiveRowItem] = useState<any | {}>({});
  const [selectedRowData, setSelectedRowData] = useState<string[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const recieptList: any = useSelector(
    (state: RootState) => state.commission.listItems
  );
  const [totalExpected, setTotalExpected] = useState(0);
  const [receiptedAmount, setReceiptedAmount] = useState(0);
  const [unusedAmount, setUnusedAmount] = useState(0);
  const [confirmsavepopup, setConfirmsavepopup] = useState(false);

  const providerOptions = [
    { label: "A J Bell", checked: true, id: "123234" },
    { label: "Bob", checked: true, id: "2343246" },
    { label: "Tom", checked: true, id: "4543667" },
    { label: "Anderson", checked: true, id: "3452366" },
    { label: "A J Bell", checked: true, id: "123324" },
    { label: "Bob", checked: true, id: "234623" },
    { label: "Tom", checked: true, id: "4562367" },
    { label: "Anderson", checked: true, id: "3454366" },
    { label: "A J Bell", checked: true, id: "12434334" },
    { label: "Bob", checked: true, id: "2344346" },
    { label: "Tom", checked: true, id: "45434667" },
    { label: "Anderson", checked: true, id: "34434566" },
    { label: "A J Bell", checked: true, id: "1443234" },
    { label: "Bob", checked: true, id: "233346" },
    { label: "Tom", checked: true, id: "454667" },
    { label: "Anderson", checked: true, id: "3443566" },
    { label: "A J Bell", checked: true, id: "123234" },
    { label: "Bob", checked: true, id: "234326" },
    { label: "Tom", checked: true, id: "4566327" },
    { label: "Anderson", checked: true, id: "3453266" },
    { label: "A J Bell", checked: true, id: "123234" },
    { label: "Bob", checked: true, id: "2322346" },
    { label: "Tom", checked: true, id: "2113" },
    { label: "Anderson", checked: true, id: "3442" },
  ];

  const getProviderList = async () => {
    try {
      const response = await apiServices.systemManager.providers.getList();

      if (response.data && response.data.data) {
        const providerNames = response.data.data.map((item: any) => ({
          label: item.provider_name,
          checked: true,
          id: item.id,
        }));
        setProvider(providerNames);
      }
    } catch (err) {
      console.error("Error fetching provider list:", err);
    }
  };
  useEffect(() => {
    getProviderList();
  }, []);
  const columns = useMemo<MRT_ColumnDef<DataRow>[]>(
    () => [
      { accessorKey: "expected", header: "Expected" },
      {
        accessorKey: "actual",
        header: "Actual",
        Cell: ({ cell, row }: { cell: MRT_Cell<DataRow>; row: any }) => (
          <Input
            style={{ border: "none" }}
            className="hover-border"
            value={cell.getValue<string>()}
          />
        ),
      },
      { accessorKey: "provider", header: "Provider" },
      { accessorKey: "category", header: "Category" },
      { accessorKey: "client_name", header: "Client Name" },
      { accessorKey: "reference", header: "Reference" },
      { accessorKey: "due_date", header: "Due Date" },
      { accessorKey: "commission_type", header: "Commission Type" },
      { accessorKey: "commission_note", header: "Commission Note" },
      { accessorKey: "case_owner", header: "Case Owner" },
      { accessorKey: "case_holder", header: "Case Holder" },
      { accessorKey: "type", header: "Type" },
      { accessorKey: "subtype", header: "Subtype" },
      { accessorKey: "consutant", header: "Consultant" },
      { accessorKey: "fund_value", header: "Fund Value" },
      { accessorKey: "value_date", header: "Value Date" },
      { accessorKey: "benifit", header: "Benefit" },
      { accessorKey: "periodic_benefit", header: "Periodic Benefit" },
      { accessorKey: "ben_freq", header: "Ben Freq" },
      { accessorKey: "payment", header: "Payment" },
      { accessorKey: "frequency", header: "Frequency" },
      { accessorKey: "start_date", header: "Start Date" },
    ],
    []
  );
  useEffect(() => {
    if (selectedRowData.length === 1) {
      const result = recieptList.find(
        (item: any) => item.commission_id === selectedRowData[0]
      );
      if (result) {
        dispatch(setFilteredData(result));
      }
    }
  }, [selectedRowData, recieptList]);

  useEffect(() => {
    if (recieptList.length > 0 && Object.keys(rowSelection).length > 0) {
      const selectedRowIndex = Object.keys(rowSelection).find(
        (key) => rowSelection[key]
      );
      if (selectedRowIndex !== undefined) {
        const selectedRow = recieptList[parseInt(selectedRowIndex)];
        setActiveRowItem(selectedRow);
      }
    }
  }, [rowSelection, recieptList]);
  useEffect(() => {
    if (amountData) {
      const total = Object.keys(selectedRow)
        .filter((key) => selectedRow[key]) // Filter selected rows
        .reduce((sum, rowIndex) => {
          const row = recieptList[parseInt(rowIndex)];
          return sum + (row?.actual ? parseFloat(row.actual) : 0);
        }, 0);
      setReceiptedAmount(total);
    } else {
      setReceiptedAmount(0);
    }
  }, [selectedRow, recieptList, amountData]);
  useEffect(() => {
    const unused = parseFloat(amountData) - receiptedAmount;
    setUnusedAmount(isNaN(unused) ? 0 : unused);
  }, [amountData, receiptedAmount]);

  return (
    <div>
      <div className="commission_options">
        <div className="commission_filter">
          <Label>Amount Received</Label>
          <Input
            value={amountData}
            onChange={(e) => setAmountData(e.target.value)}
            disabled={
              !amountData &&
              !Object.values(selectedRow).some((isSelected) => isSelected)
            }
            type="number"
          />
          <Button
            className="asc-button-primary"
            appearance="primary"
            disabled={!amountData}
            onClick={() => {
              const allSelectedRows = recieptList.reduce(
                (acc: any, row: any, index: any) => {
                  acc[row.commission_id] = true; // Assuming commission_id is unique for each row
                  return acc;
                },
                {} as MRT_RowSelectionState
              );

              setRowSelection(allSelectedRows);
              setSelectedRow(allSelectedRows);
              setSelectedRowData(
                recieptList.map((row: any) => row.commission_id)
              ); // Update selectedRowData with all commission_ids
            }}
          >
            Auto Apply
          </Button>
          <p>Receipted</p>
          <Label>{receiptedAmount.toFixed(2)}</Label> {/* Display sum */}
          <p>Unused</p>
          <Label>{unusedAmount.toFixed(2)}</Label> {/* Display difference */}
        </div>
        <Button
          className="asc-button-primary"
          appearance="primary"
          disabled={!amountData}
          onClick={() => {
            setConfirmsavepopup(true);
          }}
        >
          Save
        </Button>
      </div>
      <div className="client-list-wrapper">
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: "10px",
              }}
            >
              <div>
                <Button
                  onClick={() => {
                    setConfirmationPopupPopup(true);
                  }}
                  disabled={
                    Object.values(selectedRow).filter(
                      (isSelected) => isSelected
                    ).length === 1
                      ? false
                      : true
                  }
                >
                  Adjust Commission
                </Button>
              </div>
              <FilterTab
                filterPopup={props.filterPopup}
                setFilterPopup={props.setFilterPopup}
                selectedMenuItem="Select Provider"
                title="Select Providers"
                providerOptions={providerOptions}
              />
            </div>
          )}
          columns={columns}
          data={recieptList ? recieptList : []}
          enableColumnFilters={false}
          enableGlobalFilter={false}
          enableDensityToggle={false}
          enableRowSelection={true}
          enableSelectAll={false}
          enableColumnActions={false}
          enableMultiRowSelection={true}
          enableRowActions={false}
          enableHiding={false}
          enableFullScreenToggle={true}
          enableStickyHeader
          enablePinning
          manualPagination
          initialState={{
            columnPinning: {
              left: ["mrt-row-select", "expected", "actual"],
            },
            density: "compact",
          }}
          displayColumnDefOptions={{
            "mrt-row-select": {
              header: "Received",
              size: 60,
              enablePinning: false,
            },
          }}
          muiSelectCheckboxProps={({ row }: any) => ({
            onClick: () => {
              setRowSelection({ [row.index]: true });
              const rowId = row.id;
              const isSelected = !selectedRow[rowId];

              setSelectedRow((prev) => ({
                ...prev,
                [rowId]: isSelected,
              }));

              setSelectedRowData(
                (prevData: any) =>
                  isSelected
                    ? [...prevData, row.original.commission_id] // Add commission_id
                    : prevData.filter(
                        (id: any) => id !== row.original.commission_id
                      ) // Remove commission_id
              );
            },
            selected: selectedRow[row.id],
            color: "primary",
          })}
          positionActionsColumn="last"
          positionToolbarAlertBanner="none"
          renderRowActions={({ row, table }) => (
            <Tooltip content="Edit" relationship="label" positioning="below">
              <Button
                appearance="subtle"
                onClick={() => {
                  setConfirmationPopupPopup(true);
                }}
                icon={<Edit16Filled primaryFill="var(--button-color)" />}
              />
            </Tooltip>
          )}
        />
      </div>

      <ConfirmationPopup
        setConfirmationPopupPopup={setConfirmationPopupPopup}
        confirmationPopup={confirmationPopup}
        editpopup={editpopup}
        setReceiptEditpopup={setReceiptEditpopup}
        setEditpopup={setEditpopup}
      />
      <RecieptForm
        setReceiptEditpopup={setReceiptEditpopup}
        receiptEditpopup={receiptEditpopup}
      />
      <StopcommissionPopup
        setStopReceiptpopup={setStopReceiptpopup}
        stopReceiptpopup={stopReceiptpopup}
      />
      <SavePopup
        confirmsavepopup={confirmsavepopup}
        setConfirmsavepopup={setConfirmsavepopup}
      />
    </div>
  );
};

export default ReceiptList;
