import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";

import InitialPage from "./InitialPage";
import TitleDescription from "./TitleDescription";
import FieldSelectionPage from "./FieldSelectionPage";
import FinishPage from "./FinishPage";
import apiServices from "../../../service";
import store, { AppDispatch } from "../../../redux/store";
import OverlayLoader from "../../../components/OverlayLoader";
import { useDispatch } from "react-redux";
import { setLists } from "../../../redux/clientSlice";
interface MainProps {
  isOpen: boolean;
  onClose: () => void;
}
interface Client {
  id: string;
  fname: string;
}

const MainPage: React.FC<MainProps> = ({ isOpen, onClose }) => {
  const userId = store.getState().authUser.userId;
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<Client[]>([]);
  const [specificationList, setSpecificationList] = useState<any>([]);
  const [selectedSpecId, setSelectedSpecId] = useState<any>(null);
  const [selectedClientIds, setSelectedClientIds] = useState<string[]>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [finalTitle, setFinalTitle] = useState<string>("");
  const [finalDescription, setFinalDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const [active, setActive] = React.useState<
    "FIRST" | "SECOND" | "THIRD" | "FOURTH" | null | ""
  >("FIRST");
  const clickToTitle = () => {
    setActive("THIRD");
  };
  const BackToFirst = () => {
    setActive("FIRST");
  };
  const cancelFirst = () => {
    onClose();
    resetFormState();
  };
  const cancelSelection = () => {
    onClose();
    resetFormState();

  };
  const MoveToSelection = () => {
    setActive("THIRD");
  };
  const BacktoSecond = () => {
    setActive("FIRST");
  };
  const CancelFieldSelection = () => {
    onClose();
    resetFormState();

  };
  const CancelTitlePage = () => {
    onClose();
    resetFormState();

  };
  const CancelFinishPage = () => {
    onClose();
    resetFormState();

  };
  const BackToFieldSelection = () => {
    setActive("THIRD");
  };
  const NextToFinalPage = () => {
    setActive("FOURTH");
  };
  const BackToTab = () => {
    onClose();
  };

  const editClick = () => {
    const selectedSpec = specificationList.find(
      (spec: any) => spec.SearchId === selectedSpecId
    );
    if (selectedSpec) {
      setTitle(selectedSpec.Title || "");
      setDescription(selectedSpec.Description || "");
  
      const condition = JSON.parse(selectedSpec.Condition); 
  
      const selectedClients = condition.map((id: any) => {
        const client = clients.find((client: Client) => client.id === id);
        return { id, fname: client?.fname || "" };
      });
  
      setSelectedClients(selectedClients); 
      setSelectedClientIds(selectedClients.map((client:any) => client.id)); 
      setActive("THIRD"); 
    } else {
      console.warn("Selected specification not found");
    }
  };
  

  useEffect(() => {
    if (isOpen) {
      setActive("FIRST");
    }
  }, [isOpen]);
  const extractGetList = async () => {
    setLoading(true); 
    try {
      const res = await apiServices.extract.getExtract();
      const filteredResponse = res.data?.data.filter((item: any) => item.Type === "Selection");
      if (filteredResponse.length === 0) {
        console.warn("No entries with Type: 'Selection' found.");
      }
      setSpecificationList(filteredResponse);
      dispatch(setLists(filteredResponse))

    } catch (error) {
      console.error("Error fetching extract list:", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    extractGetList();
  }, []);

  const deleteSpecification = async (id: any) => {
    setLoading(true); 
    try {
      await apiServices.extract.delete(id);
      await extractGetList();
      setSelectedSpecId(null);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      setLoading(false); 
    }
  };
  const UpdateListing = async () => {
    const data = {
      title: title ? title : finalTitle,
      description: description ? description : finalDescription,
      condition: selectedClientIds,
      UserId: userId,
      type: "Selection",
    };
    setLoading(true); 
    try {
      await apiServices.exportExcel.updateList(data, selectedSpecId);
      await extractGetList();
    } catch (error) {
      console.error("Error during update:", error);
    } finally {
      setLoading(false); 
    }
  };
  const AddSelection = async () => {
    const data = {
      title: title || finalTitle,
      description: description || finalDescription,
      condition: selectedClientIds,
      UserId: userId,
      type: "Selection",
    };
  
    setLoading(true); 
    try {
      if (selectedSpecId) {
        await UpdateListing(); 
      } else {
        await apiServices.exportExcel.addList(data); 
      }
  
      await extractGetList();
    } catch (error) {
      console.error("Error during AddSelection process:", error);
    } finally {
      setLoading(false); 
      onClose();
      resetFormState();
    }
  };
  const resetFormState = () => {
    setTitle("");
    setFinalTitle("");
    setDescription("");
    setFinalDescription("");
    setSelectedSpecId(null);
    setSelectedClients([]);
    setSelectedClientIds([]);
  };

  return (
    <div>
      {" "}
      <Dialog
        modalType="alert"
        open={isOpen}
        onOpenChange={(event, data) => {
          if (!data.open) onClose();
        }}
      >
        <DialogSurface>
        <OverlayLoader isLoading={loading} />
          <DialogBody>
            <DialogContent>
              {active === "FIRST" && (
                <InitialPage
                  clickToTitle={clickToTitle}
                  cancelFirst={cancelFirst}
                  BackToTab={BackToTab}
                  specificationList={specificationList}
                  setSelectedSpecId={setSelectedSpecId}
                  selectedSpecId={selectedSpecId}
                  deleteSpecification={deleteSpecification}
                  editClick={editClick}
                />
              )}
              {/* {active === "SECOND" && (
                <TitleDescription
                  MoveToSelection={MoveToSelection}
                  BackToFirst={BackToFirst}
                  CancelTitlePage={CancelTitlePage}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                />
              )} */}
              {active === "THIRD" && (
                <FieldSelectionPage
                  BacktoSecond={BacktoSecond}
                  CancelFieldSelection={CancelFieldSelection}
                  NextToFinalPage={NextToFinalPage}
                  setSelectedClientIds={setSelectedClientIds}
                  setSelectedClients={setSelectedClients}
                  selectedClients={selectedClients}
                  clients={clients}
                  setClients={setClients}
                  title={title}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  description={description}
                  selectedClientIds={selectedClientIds}
                />
              )}
              {active === "FOURTH" && (
                <FinishPage
                  selectedClients={selectedClients}
                  CancelFinishPage={CancelFinishPage}
                  BackToFieldSelection={BackToFieldSelection}
                  title={title}
                  description={description}
                  setDescription={setDescription}
                  setTitle={setTitle}
                  finalTitle={finalTitle}
                  setFinalTitle={setFinalTitle}
                  finalDescription={finalDescription}
                  setFinalDescription={setFinalDescription}
                  finishSelection={AddSelection}
                />
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default MainPage;
