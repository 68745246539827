import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  MRT_VisibilityState,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  MRT_SortingState,
  MRT_RowSelectionState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
  ArrowDownload20Filled,
  ChevronDown16Regular,
  ChevronDownRegular,
} from "@fluentui/react-icons";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Link,
  MenuButton,
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  DrawerProps,
} from "@fluentui/react-components";
import { ClientDataType } from "../../types";
import CopyToClipBoard from "../../components/CopyToClipBoard";
import { BreadcrumbMenuItem } from "../../types/BreadcrumbOption";
import Search from "../../components/Search";
import Multiselect from "../../components/MultiSelectDropdown";
import DropDownBox from "../../components/DropDownBox";
import ASCButton from "../../components/Button";
import "./style.css";
import {
  checkUserPermission,
  convertStringToArray,
  formatedDate,
} from "../../utils";
import apiServices from "../../service";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { UserPermissionsList } from "../../config";
import ReactQuillComponent from "../../components/ReactQuillComponent";
import OverlayLoader from "../../components/OverlayLoader";
import { debounce } from "lodash";
import { title } from "process";
import SendMail from "./SendMail";
import { Box, IconButton } from "@mui/material";
import {
  setReloadClientList,
  resetReloadClientList,
  setFormData,
} from "../../redux/formUpdateReducer";
import { fetchSettings } from "../../redux/formSlice";
import ExportMain from "../Export/ExportMain";
import MainPage from "../ClientPartnerSplit/MainPage";
import {
  setClientValue,
  setFilterSelectionData,
  setPartnerIDs,
} from "../../redux/clientSlice";
import ASCDropDown from "../../components/DropDown";
import MainTab from "./SavedSearch/MainTab";
import { TableListLoader } from "../../components/Loader/TableListLoader";

type DrawerType = Required<DrawerProps>["type"];

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  popup: {
    height: "auto",
    width: "auto",
    position: "absolute",
    backgroundColor: "white",
    ...shorthands.padding("10px"),
    zIndex: 1000,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    alignItems: "center",
    cursor: "pointer",
  },
});

type ClientDataListProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  search?: string;
  setEncodedCondition?: any;
  encodedCondition?: any;
};
const ClientDataList = (props: ClientDataListProps) => {
  const [openSplit, setOpenSplit] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<string | null>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [type, setType] = useState<DrawerType>("overlay");
  const [ClientPartner, setClientPartner] = useState<any>(null);
  const [partnerID, setPartnerID] = useState("");
  const [client, setClient] = useState<ClientDataType[]>([]);
  const [selectClient, setSelectClient] = useState<{
    customer_id: string;
    first_names: string;
    last_name: string;
  }>({ customer_id: "", first_names: "", last_name: "" });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  ////////////////
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const handleOpen = () => setIsOpenDialog(true);
  const handleClose = () => setIsOpenDialog(false);
  const handleOpenSearches = () => {
    props.setTabOpen(true);
  };

  ////////////////
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "first_names",
      desc: false,
    },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {
      initials: false,
      former_last_name: false,
    }
  );

  const dispatch: AppDispatch = useDispatch();
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const isReloadClientList = useSelector(
    (state: RootState) => state.formUpdated.isReloadClientList
  );
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [IsContentloading, setIsContentLoading] = useState(true);

  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const filterSelectionData = useSelector(
    (state: RootState) => state.selectionFilter.storedValue
  );
  // console.log(props.encodedCondition,"setEncodedConditionsetEncodedCondition");

  useEffect(() => {
    // if (
    //   props.applyFilter === true ||
    //   props.reloadList === true ||
    //   isReloadClientList === true
    // ) {
    //   fetchUsersData();
    // }
    fetchUsersData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    props.applyFilter,
    props.reloadList,
    isReloadClientList,
  ]);

  useEffect(() => {
    if (props.setIsLoading !== undefined) {
      props.setIsLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    const debouncedFunction = debounce(() => {
      if (
        props.selectedScope?.length > 0 &&
        props.search &&
        props.search.length >= 2
      ) {
        fetchUsersData();
      } else if (props.search == "") {
        fetchUsersData();
      } else {
      }
    }, 300);

    debouncedFunction(); // Execute the debounced function immediately

    return () => {
      debouncedFunction.cancel(); // Clean up the debounced function
    };
  }, [props.search, props.selectedScope]);
  useEffect(() => {
    const selectedCustomerId = Object.keys(rowSelection)[0];
    if (selectedCustomerId) {
      const selectedCustomerValue = client.find(
        (c) => c.customer_id === selectedCustomerId
      );

      if (selectedCustomerValue && props.setActiveItem) {
        props.setActiveItem(selectedCustomerValue);
      }
    }
  }, [rowSelection, client, props]);

  useEffect(() => {
    setRowSelection({ [props.activeClient.customer_id]: true });
  }, [props.activeClient]);

  const [selectedClient, setSelectedClient] = useState<ClientDataType | null>(
    null
  );
  const handleCloseSplit = () => {
    setOpenSplit(false);
  };
  async function fetchUsersData() {
    let scopeSelected =
      props.selectedScope?.length > 0 ? props.selectedScope.join(",") : "";
    setLoading(true);
    setIsContentLoading(true);
    apiServices.client
      .getClientLists(
        pagination.pageIndex + 1,
        pagination.pageSize,
        props.search,
        scopeSelected,
        props.selectedClientType,
        props.searchId,
        props.encodedCondition
      )
      .then(async (response: any) => {
        if (response) {
          setLoading(false);
          setIsContentLoading(false);
          props.setApplyFilter(false);
          await setClient(
            response.data &&
              response.data.data &&
              response.data.data.customerList
              ? response.data.data.customerList
              : []
          );
          await setRowCount(
            response.data && response.data.data && response.data.data.count
              ? response.data.data.count
              : 0
          );
          dispatch(resetReloadClientList());
        } else {
          setLoading(false);
          setIsContentLoading(false);
          props.setApplyFilter(false);
          dispatch(resetReloadClientList());
          notify("Something went wrong", "", "error");
        }
      })

      .catch((error) => {
        setLoading(false);
        setIsContentLoading(false);
        if (error.response && ![401, 403].includes(error.response?.status)) {
          notify("Something went wrong", "", "error");
        }
      });
  }
  // useEffect(() => {
  //   if (props.handleCondition.length > 0) {
  //     setClient(props.handleCondition);
  //   }
  // }, [props.handleConditon]);

  useEffect(() => {
    if (filterSelectionData.length > 0) {
      setClient(filterSelectionData);
    }
  }, [filterSelectionData]);

  function exportApi(customerId: any) {
    apiServices.exportToExcel
      .get(customerId)
      .then(async (response) => {
        const blob = new Blob([response.data]);

        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ClientData.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const styles = useStyles();
  const columns = useMemo<MRT_ColumnDef<ClientDataType>[]>(
    () => [
      {
        accessorKey: "first_names",
        header: "First Name",
        sortingFn: (rowA, rowB) => {
          const firstNameA =
            rowA.original?.first_names?.toString().toLowerCase() || "";
          const firstNameB =
            rowB.original?.first_names?.toString().toLowerCase() || "";
          return firstNameA.localeCompare(firstNameB);
        },
      },

      {
        accessorKey: "last_name",
        header: "Last Name",
        sortingFn: (rowA, rowB) => {
          const lastNameA =
            rowA.original?.last_name?.toString().toLowerCase() || "";
          const lastNameB =
            rowB.original?.last_name?.toString().toLowerCase() || "";
          return lastNameA.localeCompare(lastNameB);
        },
      },
      {
        accessorKey: "initials",
        header: "Initials",
      },
      {
        accessorKey: "former_last_name",
        header: "Former Last Name",
      },
      {
        accessorKey: "client_type",
        header: "Client Type",
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
      },
      {
        accessorKey: "town",
        header: "Town",
      },
      {
        accessorKey: "date_of_birth",
        header: "Date of Birth",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original?.date_of_birth)}</>
        ),
      },
      {
        accessorKey: "place_of_birth",
        header: "Place of Birth",
      },
      {
        accessorKey: "nationality",
        header: "Nationality",
      },
      {
        accessorKey: "country_of_residence",
        header: "Country of Residence",
      },
      {
        accessorKey: "country_of_domicile",
        header: "Country of Domicile",
      },
      {
        accessorKey: "sex",
        header: "Sex",
      },
      {
        accessorKey: "height",
        header: "Height",
      },
      {
        accessorKey: "weight",
        header: "Weight",
      },
      {
        accessorKey: "smoker",
        header: "Smoker",
      },
      {
        accessorKey: "marital_status",
        header: "Marital Status",
      },
      {
        accessorKey: "preferred_retirement_age",
        header: "Preferred Retirement Age",
      },
      {
        accessorKey: "deceased",
        header: "Deceased",
      },
    ],
    []
  );
  const showClient = (row: any) => {
    setSelectedClient(row.original);
    // setIsViewOpen(true);
    if (props.changeMode) {
      props.changeMode("view");
    }
    if (props.setActiveItem) {
      props.setActiveItem(row.original);
    }
    // if (formSettingsObj && formSettingsObj.data && Object.keys(formSettingsObj.data).length === 0) {
    //   dispatch(fetchSettings());
    // }
  };

  const doEditClient = (row: any) => {
    // setIsOpen(true);
    if (props.setActiveItem) {
      props.setActiveItem(row.original);
    }
    if (props.changeMode) {
      props.changeMode("edit");
    }
    // if (formSettingsObj && formSettingsObj.data && Object.keys(formSettingsObj.data).length === 0) {
    //   dispatch(fetchSettings());
    // }
  };

  // useEffect(() => {
  //   if (
  //     formSettingsObj &&
  //     formSettingsObj.data &&
  //     Object.keys(formSettingsObj.data).length === 0
  //   ) {
  //     dispatch(fetchSettings());
  //   }
  // }, [formSettingsObj]);

  const clientTypeData: {
    id: number;
    label: string;
  }[] = [
    {
      id: 1,
      label: "Gold",
    },
    {
      id: 2,
      label: "VIP",
    },
  ];
  const scopeData: {
    id: number;
    label: string;
  }[] = [
    {
      id: 1,
      label: "Partner",
    },
    {
      id: 2,
      label: "Client",
    },
  ];

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLTableRowElement>,
    data: string
  ) => {
    const { clientX, clientY } = event;
    if (data) {
      setPopupData(data);
      setPopupPosition({ top: clientY + 10, left: clientX + 10 });
      setPopupVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setPopupVisible(false);
  };
  const [isSendMailDialogOpen, setSendMailDialogOpen] = useState(false);
  const openSendMailDialog = (
    customer_id: string,
    first_names: string,
    last_name: string
  ) => {
    setSendMailDialogOpen(true);
    setSelectClient({ customer_id, first_names, last_name });
  };

  const closeSendMailDialog = () => {
    setSendMailDialogOpen(false);
    props.setReloadList(true);
  };
  const getClientPartner = (id: any) => {
    apiServices.ClientPartnerSplit.getClientDetail(id)
      .then((res) => {
        setPartnerID(res.data.data.NameAndAddress.associated_with);
        dispatch(setPartnerIDs(res.data.data.NameAndAddress.associated_with));

        setClientPartner(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseMainTab = () => {
    props.setTabOpen(false);
  };

  const clearExtractFilters = async () => {
    setLoading(true);
    props.setSearch([]);
    dispatch(setFilterSelectionData([]));
    props.setSelectedSearches([]);
    props.setSelectedSearch([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setClient([]);
    setRowCount(0);

    try {
      await fetchUsersData();
    } catch (error) {
      console.error("Error while fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    clearExtractFilters();
  }, []);
  const OpenSplitComponent = (customer_id: any) => {
    setOpenSplit(true);
    dispatch(setClientValue(customer_id));
  };
  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      {IsContentloading ? (
        <TableListLoader rows={10} columns={6} />
      ) : (
        <div className="client-list-wrapper">
          <MaterialReactTable
            renderTopToolbarCustomActions={({ table }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="global-search-filter">
                    <Search
                      setSearch={props.setSearch}
                      search={props.search}
                      isLoading={props.isLoading}
                      placeholder="Search by last name, first name"
                    />
                    <DropDownBox
                      disabled={
                        props.search && props.search !== "" ? false : true
                      }
                      placeholder="Scope"
                      multiselect={true}
                      values={props.scopeData}
                      selectedOptions={props.selectedScope}
                      onOptionSelect={(event: any, option: string | any) => {
                        props.setSelectedScope(option.selectedOptions);
                      }}
                    />
                    <ASCDropDown
                      placeholder="Client Type"
                      optionsFetcher={props.fetchClientTypeOptions}
                      clearable
                      onSelectionChange={(selectedValue: any) => {
                        props.setSelectedClientType(selectedValue);
                      }}
                    />

                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("saved_searches") ? (
                      <Button
                        icon={<ChevronDownRegular />}
                        iconPosition="after"
                        style={{
                          fontWeight: 400,
                          color: "#606060",
                          border: "1px solid grey",
                        }}
                        onClick={handleOpenSearches}
                      >
                        Saved Searches
                      </Button>
                    ) : (
                      ""
                    )}

                    <ASCButton
                      shape={"rounded"}
                      disabled={props.selectedClientType != "" ? false : true}
                      label={"Apply Filter"}
                      className={
                        props.selectedClientType != ""
                          ? "asc-button-primary"
                          : ""
                      }
                      appearance="primary"
                      onItemClick={() => {
                        props.setApplyFilter(true);
                      }}
                      size="medium"
                    />
                    {(props.searchId.length > 0 ||
                      filterSelectionData.length > 0) && (
                      <Button
                        shape="rounded"
                        appearance="primary"
                        size="medium"
                        onClick={clearExtractFilters}
                      >
                        Clear Filter
                      </Button>
                    )}
                  </div>{" "}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("saved_searches") && (
                    <Tooltip
                      content="Export"
                      relationship="label"
                      appearance="inverted"
                      positioning="below"
                      withArrow
                    >
                      {props.searchId?.length > 0 ||
                      filterSelectionData?.length > 0 ? (
                        <IconButton
                          onClick={handleOpen}
                          disabled={
                            !(
                              props.searchId?.length > 0 ||
                              filterSelectionData?.length > 0
                            )
                          }
                        >
                          <ArrowDownload20Filled />
                        </IconButton>
                      ) : null}
                    </Tooltip>
                  )}

                  <ExportMain
                    searchIds={props.searchIds}
                    isOpen={isOpenDialog}
                    onClose={handleClose}
                    selectedTab={props.selectedTab}
                    typeOfExtract={props.typeOfExtract}
                    searchId={props.searchId}
                  />
                </div>
              </div>
            )}
            columns={columns}
            data={client}
            enableColumnFilters={true}
            enableGlobalFilter={false}
            enableDensityToggle={true}
            enableRowSelection={true}
            onRowSelectionChange={setRowSelection}
            enableSelectAll={false}
            enableColumnActions={false}
            getRowId={(row) => row.customer_id}
            enableMultiRowSelection={false}
            enableRowActions={true}
            muiTableBodyRowProps={({ row }) => ({
              onMouseEnter: (event) =>
                handleMouseEnter(
                  event,
                  row.original?.Administration?.note_text
                ),
              onMouseLeave: handleMouseLeave,
            })}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions",
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
                enablePinning: false, // Disable pinning for actions column
              },
              "mrt-row-select": {
                header: "Select",
                enablePinning: false, // Disable pinning for select column
              },
            }}
            enableStickyHeader
            enablePinning
            initialState={{
              columnPinning: {
                left: ["mrt-row-select"],
                right: ["mrt-row-actions"],
              },
              columnVisibility,
              density: "compact",
              sorting: sorting.map((column) => ({
                id: column.id,
                desc: column.desc,
              })),
            }}
            manualPagination
            onSortingChange={setSorting}
            onColumnVisibilityChange={setColumnVisibility}
            onPaginationChange={setPagination}
            rowCount={rowCount}
            state={{ pagination, columnVisibility, sorting, rowSelection }}
            renderRowActions={({ row, table }) => (
              <div className={styles.wrapper}>
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_VIEW_CLIENT
                ) && (
                  <Tooltip
                    content="View"
                    relationship="label"
                    positioning="below"
                  >
                    <ASCButton
                      appearance="subtle"
                      onItemClick={() => {
                        navigate(
                          `clients/${row.original.customer_id}/name-and-address/view`
                        );
                        // showClient(row);
                      }}
                      icon={<Eye16Filled primaryFill="var(--button-color)" />}
                    />
                  </Tooltip>
                )}
                {checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                ) && (
                  <Tooltip
                    content="Edit"
                    relationship="label"
                    positioning="below"
                  >
                    <ASCButton
                      appearance="subtle"
                      onItemClick={() => {
                        navigate(
                          `clients/${row.original.customer_id}/name-and-address/edit`
                        );
                        // doEditClient(row);
                      }}
                      icon={<Edit16Filled primaryFill="var(--button-color)" />}
                    />
                  </Tooltip>
                )}
                {(checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_CLIENT_EXPORTS
                ) ||
                  checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  )) && (
                  <Menu>
                    <MenuTrigger disableButtonEnhancement>
                      <Button
                        appearance="subtle"
                        icon={<MoreHorizontal24Filled primaryFill="gray" />}
                      />
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        {checkUserPermission(
                          loggedUser,
                          UserPermissionsList.ALLOW_CLIENT_EXPORTS
                        ) && (
                          <MenuItem
                            onClick={() => exportApi(row.original.customer_id)}
                          >
                            Export to Excel
                          </MenuItem>
                        )}
                        <MenuItem
                          disabled={!row.original.email}
                          onClick={() =>
                            openSendMailDialog(
                              row.original.customer_id,
                              row.original.first_names,
                              row.original.last_name
                            )
                          }
                        >
                          Send Mail
                        </MenuItem>
                        {!convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("client_partner_split") ? (
                          <MenuItem
                            onClick={() => {
                              OpenSplitComponent(row.original.customer_id);
                              getClientPartner(row.original.customer_id);
                            }}
                            disabled={row.original.associated_with === null}
                          >
                            Split
                          </MenuItem>
                        ) : (
                          ""
                        )}
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                )}
              </div>
            )}
            positionActionsColumn="last"
            positionToolbarAlertBanner="none"
            muiTableContainerProps={{
              style: {
                minHeight: "200px",
                maxHeight: "100vh",
                overflow: "auto",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: "14px",
                lineHeight: "0.9",
              },
            }}
          />
          {popupVisible && (
            <div
              className={styles.popup}
              style={{ top: popupPosition.top, left: popupPosition.left }}
            >
              <Label weight="semibold">Headline Note</Label>
              <Tooltip
                withArrow
                content="Example tooltip with an arrow"
                relationship="label"
              >
                <ReactQuillComponent value={popupData} />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {isSendMailDialogOpen && (
        <SendMail
          openSendMailDialog={openSendMailDialog}
          isSendMailDialogOpen={isSendMailDialogOpen}
          closeSendMailDialog={closeSendMailDialog}
          selectClient={selectClient}
          reloadList={props.setReloadList}
          notify={notify}
        />
      )}
      {props.tabOpen && (
        <MainTab
          handleAddDialogBox={props.handleAddDialogBox}
          selectedItem={props.selectedItem}
          handleAddSearchClick={props.handleAddSearchClick}
          selectedMenuItem={props.selectedMenuItem}
          renderedMenuItems={props.renderedMenuItems}
          getExtracts={props.getExtracts}
          handleSearchId={props.handleSearchId}
          applyFilter={props.applyFilter}
          setApplyFilter={props.setApplyFilter}
          // setHandleCondition={props.setHandleCondition}
          // handleCondition={props.handleCondition}
          setSearchIds={props.setSearchIds}
          searchIds={props.searchIds}
          selectedSearch={props.selectedSearch}
          setSelectedSearch={props.setSelectedSearch}
          selectedTab={props.selectedTab}
          setSelectedTab={props.setSelectedTab}
          setTypeOfExtract={props.setTypeOfExtract}
          selectedSearches={props.selectedSearches}
          setSelectedSearches={props.setSelectedSearches}
          setTabOpen={props.setTabOpen}
          getDialogState={props.tabOpen}
          closeMainTabDialog={handleCloseMainTab}
        />
      )}
      <MainPage
        isOpen={openSplit}
        ClientPartner={ClientPartner}
        onClose={handleCloseSplit}
        partnerID={partnerID}
      />
    </>
  );
};

export default ClientDataList;
