import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Select,
  useId,
  makeStyles,
  Button,
  Divider,
  tokens,
  CompoundButton,
  ButtonProps,
  Tab,
  TabList,
  shorthands,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioOnChangeData,
  RadioGroup,
} from "@fluentui/react-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbProps,
  BreadcrumbButton,
  BreadcrumbItemProps,
} from "@fluentui/react-breadcrumb-preview";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  DrawerProps,
} from "@fluentui/react-components";
import {
  Add20Filled,
  Add16Filled,
  ChevronLeft20Regular,
  Add20Regular,
  DocumentBulletList20Regular,
  DocumentFolder20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CaseCategorySelection from "./CaseCategorySelection";
import { checkUserPermission, convertStringToArray } from "../../../utils";
import ASCButton from "../../../components/Button";
const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "8px",
    marginBottom: "4px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("4px", "0px"),
    rowGap: "20px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
});
const radioOptions: any = {
  assets: [
    { value: "investment", label: "Investment" },
    { value: "shares", label: "Share Holdings" },
    { value: "home-personal", label: "Home and Personal" },
    { value: "bank-societies", label: "Bank/Building Societies" },
  ],
  liabilities: [
    { value: "morgages", label: "Mortgages" },
    { value: "loans-lease-hp", label: "Loan and Hire Purchase" },
    { value: "credit-cards", label: "Credit Cards" },
  ],
  policies: [
    { value: "Life Assurance", label: "Life Assurance" },
    { value: "Pensions", label: "Pensions" },
    { value: "Investments", label: "Investments" },
    { value: "Savings Plans", label: "Savings Plans" },
    { value: "Income Protection", label: "Income Protection" },
    { value: "Health Assurance", label: "Health Assurance" },
  ],
};
function CaseNavList(props: any) {
  console.log("casenavlist", props);
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = useState("");
  const [caseCategoryPopup, setCaseCategoryPopup] = useState(false);
  const [selectedCaseType, setSelectedCaseType] = useState("");
  const navigate = useNavigate();
  const { clientId, action, caseId, subItemId, subSubItemId } = useParams();
  const sectionLabel: any = {
    assets: "Asset",
    liabilities: "Liability",
    policies: "Policy",
  };
  useEffect(() => {
    setSelectedTab(props.tab);
  }, [props.tab]);
  const onTabSelect = (item: string) => {
    if (
      [
        "fund",
        "action",
        "payment",
        "compliance",
        "valuation",
        "withdrawal",
        "commission",
        "distribution",
      ].includes(item) &&
      props.subItem != undefined &&
      subItemId != undefined
    ) {
      if (item == "distribution" && subSubItemId != undefined) {
        navigate(
          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/${subSubItemId}/${item}`
        );
      } else {
        if (item == "commission") {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${item}`
          );
        } else {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/${item}/view`
          );
        }
      }
    } else {
      navigate(`/clients/${clientId}/${props.section}/${caseId}/${item}/view`);
    }
  };
  const onSubItemSelect = (item: string) => {
    navigate(`/clients/${clientId}/${props.section}/${caseId}/${item}`);
  };
  const handleAddCase = () => {
    if (selectedCaseType) {
      setCaseCategoryPopup(false);
      let selCaseType = selectedCaseType;
      setSelectedCaseType("");
      navigate(`/clients/${clientId}/${props.section}/add`, {
        state: { caseType: selCaseType },
      });
    }
  };
  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className={styles.btnWrapper}>
              <ASCButton
                shape="rounded"
                size="medium"
                // icon={<ChevronLeft20Regular />}
                style={{
                  background: !props.subItem ? "black" : undefined,
                  color: !props.subItem ? "white" : undefined,
                }}
                onItemClick={() => {
                  navigate(`/clients/${clientId}/${props.section}`);
                }}
                label={"Detail"}
              />

              {[
                "investment",
                "Life Assurance",
                "Pensions",
                "Investments",
                "Savings Plans",
                "Income Protection",
                "Health Assurance",
                "General",
              ].includes(props.caseType) &&
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("asset_hide_fund_button") ? (
                <ASCButton
                  shape="rounded"
                  size="medium"
                  style={{
                    background: props.subItem == "funds" ? "black" : undefined,
                    color: props.subItem == "funds" ? "white" : undefined,
                  }}
                  onItemClick={() => {
                    onSubItemSelect("funds");
                  }}
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("asset_disable_fund_button")
                      ? false
                      : true
                  }
                  label="Funds"
                />
              ) : (
                ""
              )}
              {[
                "investment",
                "shares",
                "bank-societies",
                "morgages",
                "loans-lease-hp",
                "credit-cards",
                "Life Assurance",
                "Pensions",
                "Investments",
                "Savings Plans",
                "Income Protection",
                "Health Assurance",
                "General",
              ].includes(props.caseType) &&
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("asset_hide_payment_button") ? (
                <ASCButton
                  shape="rounded"
                  size="medium"
                  onItemClick={() => {
                    onSubItemSelect("payments");
                  }}
                  style={{
                    background:
                      props.subItem == "payments" ? "black" : undefined,
                    color: props.subItem == "payments" ? "white" : undefined,
                  }}
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("asset_disable_payment_button")
                      ? false
                      : true
                  }
                  label="Payments"
                />
              ) : (
                ""
              )}
              {[
                "investment",
                "shares",
                "bank-societies",
                "Life Assurance",
                "Pensions",
                "Investments",
                "Savings Plans",
                "Income Protection",
                "Health Assurance",
                "General",
              ].includes(props.caseType) &&
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("asset_hide_withdrawal_button") ? (
                <ASCButton
                  shape="rounded"
                  size="medium"
                  style={{
                    background:
                      props.subItem === "withdrawals" ? "black" : undefined,
                    color:
                      props.subItem === "withdrawals" ? "white" : undefined,
                  }}
                  onItemClick={() => {
                    onSubItemSelect("withdrawals");
                  }}
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("asset_disable_withdrawal_button")
                      ? false
                      : true
                  }
                  label="Withdrawals"
                />
              ) : (
                ""
              )}
              {[
                "investment",
                "morgages",
                "loans-lease-hp",
                "Life Assurance",
                "Pensions",
                "Investments",
                "Savings Plans",
                "Income Protection",
                "Health Assurance",
                "General",
              ].includes(props.caseType) &&
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("asset_hide_action_button") ? (
                <ASCButton
                  shape="rounded"
                  size="medium"
                  style={{
                    background:
                      props.subItem === "actions" ? "black" : undefined,
                    color: props.subItem === "actions" ? "white" : undefined,
                  }}
                  onItemClick={() => {
                    onSubItemSelect("actions");
                  }}
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("asset_disable_action_button")
                      ? false
                      : true
                  }
                  label="Actions"
                />
              ) : (
                ""
              )}
              {[
                "investment",
                "shares",
                "bank-societies",
                "home-personal",
                "Life Assurance",
                "Pensions",
                "Investments",
                "Savings Plans",
                "Income Protection",
                "Health Assurance",
                "General",
              ].includes(props.caseType) &&
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("asset_hide_valuation_button") ? (
                <ASCButton
                  shape="rounded"
                  size="medium"
                  style={{
                    background:
                      props.subItem === "valuations" ? "black" : undefined,
                    color: props.subItem === "valuations" ? "white" : undefined,
                  }}
                  onItemClick={() => {
                    onSubItemSelect("valuations");
                  }}
                  disabled={
                    !convertStringToArray(
                      process.env.REACT_APP_DISABLE_CTA_BUTTON
                    ).includes("asset_disable_valuation_button")
                      ? false
                      : true
                  }
                  label="Valuations"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <div className={styles.btnWrapper}>
              {props.subItem && (
                <>
                  {props.subItem === "actions" && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_load_standard_action_hide") && (
                        <ASCButton
                          size="medium"
                          shape="rounded"
                          disabled={convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("asset_load_standard_action_disable")}
                          onItemClick={() => {
                            props.handleStandardActions(true);
                          }}
                          label=" Load Standard Actions"
                        />
                      )}
                    </>
                  )}
                  {props.subItem === "valuations" &&
                    !convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("asset_use_case_fund_value") && (
                      <ASCButton
                        size="medium"
                        disabled={convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("asset_use_case_fund_value")}
                        appearance="outline"
                        shape="rounded"
                        label={"Use Case Fund Value"}
                      />
                    )}
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("asset_case_details_hide") ? (
                    <ASCButton
                      size="medium"
                      appearance="outline"
                      shape="rounded"
                      disabled={convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("asset_case_details_disable")}
                      icon={<DocumentBulletList20Regular />}
                      onItemClick={() => {
                        props.handleDetailPopup(true);
                      }}
                      label={" Case Details"}
                    />
                  ) : (
                    ""
                  )}{" "}
                  <ASCButton
                    shape="rounded"
                    size="medium"
                    appearance="primary"
                    className="asc-button-primary"
                    icon={<Add16Filled />}
                    onItemClick={() => {
                      if (props.tab == "commission") {
                        navigate(
                          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/add`
                        );
                      } else if (props.tab == "distribution") {
                        navigate(
                          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${subItemId}/commission/${subSubItemId}/distribution/add`
                        );
                      } else {
                        navigate(
                          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/add`
                        );
                      }
                    }}
                    label={" Add New"}
                  />
                </>
              )}
              {!props.subItem && ["", "view"].includes(props.mode) && (
                <ASCButton
                  appearance="primary"
                  className="asc-button-primary"
                  icon={<Add16Filled />}
                  size="medium"
                  onItemClick={() => {
                    setCaseCategoryPopup(true);
                  }}
                  label="Add New"
                  shape="rounded"
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.tabList}>
          <TabList selectedValue={selectedTab}>
            <>
              {props.section && props.section == "assets" && (
                <>
                  {!props.subItem && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_asset_hide_button") ? (
                        <Tab
                          value="asset"
                          onClick={() => {
                            onTabSelect("asset");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("asset_asset_disable_button")
                              ? false
                              : true
                          }
                        >
                          Asset
                        </Tab>
                      ) : (
                        ""
                      )}
                      {[
                        "investment",
                        "shares",
                        "home-personal",
                        "bank-societies",
                      ].includes(props.caseType) &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_review_hide_button") ? (
                        <Tab
                          value="review"
                          onClick={() => {
                            onTabSelect("review");
                          }}
                          disabled={
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("asset_review_disable_button") || !caseId
                              ? true
                              : false
                          }
                        >
                          Review
                        </Tab>
                      ) : (
                        ""
                      )}
                      {["investment"].includes(props.caseType) &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_contract_enquiry_hide_button") ? (
                        <Tab
                          value="contract-enquiry"
                          disabled={
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "asset_contract_enquiry_disable_button"
                            ) || !caseId
                              ? true
                              : true
                          }
                        >
                          Contract Enquiry
                        </Tab>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
              {props.section && props.section == "liabilities" && (
                <>
                  {!props.subItem && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("liability_hide_button") ? (
                        <Tab
                          value="liability"
                          onClick={() => {
                            onTabSelect("liability");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("liability_disable_button")
                              ? false
                              : true
                          }
                        >
                          Liability
                        </Tab>
                      ) : (
                        ""
                      )}
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("liability_review_hide_button") ? (
                        <Tab
                          value="review"
                          onClick={() => {
                            onTabSelect("review");
                          }}
                          disabled={
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("liability_review_disable_button") ||
                            !caseId
                              ? true
                              : false
                          }
                        >
                          Review
                        </Tab>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
              {props.section && props.section == "policies" && (
                <>
                  {!props.subItem && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("policy_policy_hide_button") ? (
                        <Tab
                          value="policy"
                          onClick={() => {
                            onTabSelect("policy");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("policy_policy_disable_button")
                              ? false
                              : true
                          }
                        >
                          Policy
                        </Tab>
                      ) : (
                        ""
                      )}
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("policy_review_hide_button") ? (
                        <Tab
                          value="review"
                          onClick={() => {
                            onTabSelect("review");
                          }}
                          disabled={
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("policy_review_disable_button") ||
                            !caseId
                              ? true
                              : false
                          }
                        >
                          Review
                        </Tab>
                      ) : (
                        ""
                      )}
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("policy_contract_enquiry_hide_button") ? (
                        <Tab
                          value="contract-enquiry"
                          disabled={
                            convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "asset_contract_enquiry_disable_button"
                            ) || !caseId
                              ? true
                              : true
                          }
                        >
                          Contract Enquiry
                        </Tab>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
              {props.subItem && (
                <>
                  {props.subItem == "funds" && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_hide_fund_button") ? (
                        <Tab
                          value="fund"
                          onClick={() => {
                            onTabSelect("fund");
                          }}
                        >
                          Fund
                        </Tab>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {props.subItem == "payments" && (
                    <>
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_payment_payment_hide_button") ? (
                        <Tab
                          value="payment"
                          onClick={() => {
                            onTabSelect("payment");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes("asset_payment_payment_disable_button")
                              ? false
                              : true
                          }
                        >
                          Payment
                        </Tab>
                      ) : (
                        ""
                      )}
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_payment_compliance_hide_button") ? (
                        <Tab
                          value="compliance"
                          onClick={() => {
                            onTabSelect("compliance");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "asset_payment_compliance_disable_button"
                            )
                              ? false
                              : true
                          }
                        >
                          Compliance
                        </Tab>
                      ) : (
                        ""
                      )}
                      {!convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_payment_commission_hide_button") ? (
                        <Tab
                          value="commission"
                          onClick={() => {
                            onTabSelect("commission");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "asset_payment_commission_disable_button"
                            )
                              ? false
                              : true
                          }
                        >
                          Commission
                        </Tab>
                      ) : (
                        ""
                      )}
                      {subSubItemId != undefined &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("asset_payment_commission_hide_button") ? (
                        <Tab
                          value="distribution"
                          onClick={() => {
                            onTabSelect("distribution");
                          }}
                          disabled={
                            !convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "asset_payment_commission_disable_button"
                            )
                              ? false
                              : true
                          }
                        >
                          Distribution
                        </Tab>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {props.subItem == "actions" &&
                  !convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("asset_disable_action_button") ? (
                    <Tab
                      value="action"
                      onClick={() => {
                        onTabSelect("action");
                      }}
                      disabled={
                        !convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("asset_disable_action_button")
                          ? false
                          : true
                      }
                    >
                      Action
                    </Tab>
                  ) : (
                    ""
                  )}
                  {props.subItem == "valuations" &&
                  !convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("asset_hide_valuation_button") ? (
                    <Tab
                      value="valuation"
                      onClick={() => {
                        onTabSelect("valuation");
                      }}
                      disabled={
                        !convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("asset_hide_valuation_button")
                          ? false
                          : true
                      }
                    >
                      Valuation
                    </Tab>
                  ) : (
                    ""
                  )}
                  {props.subItem == "withdrawals" &&
                  !convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("asset_hide_withdrawal_button") ? (
                    <Tab
                      value="withdrawal"
                      onClick={() => {
                        onTabSelect("withdrawal");
                      }}
                      disabled={
                        !convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("asset_hide_withdrawal_button")
                          ? false
                          : true
                      }
                    >
                      Withdrawal
                    </Tab>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          </TabList>
        </div>
      </div>
      <Dialog open={caseCategoryPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div className={styles.field}>
                <Label weight="semibold">
                  Select {sectionLabel[props.section]} Category
                </Label>
                <RadioGroup
                  value={selectedCaseType}
                  onChange={(_, data) => setSelectedCaseType(data.value)}
                >
                  {radioOptions[props.section]?.map((radio: any) => (
                    <Radio
                      key={radio.value}
                      value={radio.value}
                      label={radio.label}
                    />
                  ))}
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <ASCButton
                  appearance="secondary"
                  size="small"
                  onItemClick={() => setCaseCategoryPopup(false)}
                  label="Close"
                />
              </DialogTrigger>
              <ASCButton
                appearance="primary"
                size="small"
                className="asc-button-primary"
                onItemClick={handleAddCase}
                label="Submit"
              />
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default CaseNavList;
