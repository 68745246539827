import {
  Button,
  Checkbox,
  Input,
  Label,
  Spinner,
} from "@fluentui/react-components";
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  ChevronDoubleLeft20Filled,
  ChevronDoubleRight20Filled,
  ChevronLeft20Filled,
  ChevronRight20Filled,
} from "@fluentui/react-icons";
import apiServices from "../../../service";
interface Client {
  id: string;
  fname: string;
}

const FieldSelectionPage = ({
  BacktoSecond,
  CancelFieldSelection,
  NextToFinalPage,
  setSelectedClientIds,
  setSelectedClients,
  selectedClients,
  clients,
  setClients,
  setTitle,
  title,
  description,
  setDescription,
  selectedClientIds
}: {
  BacktoSecond: () => void;
  selectedClientIds:any;
  NextToFinalPage: () => void;
  CancelFieldSelection: () => void;
  setSelectedClientIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedClients: React.Dispatch<React.SetStateAction<Client[]>>;
  selectedClients: Client[];
  clients: any[];
  setClients: React.Dispatch<React.SetStateAction<any[]>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
}) => {
  const [selectedClient, setSelectedClient] = useState<{
    id: string;
    fname: string;
  } | null>(null);
  const [selectedSelectedClient, setSelectedSelectedClient] = useState<{
    id: string;
    fname: string;
  } | null>(null);
  const [selectedField, setSelectedField] = useState<{
    id: string;
    fname: string;
  } | null>(null);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);
  const getClientList = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await apiServices.client.getClients(
        pagination.pageIndex + 1,
        pagination.pageSize,
        ""
      );

      if (response?.data?.data?.list) {
        const newClients = response.data.data.list.map((client: any) => ({
          id: client.customer_id,
          fname: client?.NameAndAddress?.first_names || "",
        }));

        setClients((prev) => [...prev, ...newClients]);
        setHasMore(newClients.length >= pagination.pageSize);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setLoading(false);
    }
  }, [pagination, loading, clients]);

  const lastClientRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPagination((prev) => ({
            ...prev,
            pageIndex: prev.pageIndex + 1,
          }));
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    getClientList();
  }, [pagination]);

  const MoveToRight = () => {
    if (selectedClient) {
      setSelectedClients((prev) => {
        if (prev.some((client) => client.id === selectedClient.id)) return prev; // Avoid duplicates
        return [...prev, selectedClient];
      });
  
      setSelectedClientIds((prev) => {
        if (prev.includes(selectedClient.id)) return prev; 
        return [...prev, selectedClient.id];
      });
  
      setSelectedClient(null); 
    }
  };
  
  const MoveToLeft = () => {
    if (selectedSelectedClient) {
      setSelectedClients((prev) =>
        prev.filter((client) => client.id !== selectedSelectedClient.id)
      );
  
      setSelectedClientIds((prev) =>
        prev.filter((id) => id !== selectedSelectedClient.id)
      );
  
      setSelectedSelectedClient(null); 
    }
  };
  
  

  const MoveAllToRight = () => {
    const allClients = clients.map((client) => ({
      id: client.id,
      fname: client.fname,
    }));
    setSelectedClients(allClients); 
    setSelectedClientIds(allClients.map((client) => client.id)); // Store all IDs
    setSelectedClient(null);
  };

  const MoveAllToLeft = () => {
    setSelectedClients([]); 
    setSelectedClientIds([]); 
  };
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span style={{ fontWeight: 700, fontSize: "16px" }}>
              Select Clients
            </span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div>

          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div
              className="title"
              style={{
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "1fr 3fr",
              }}
            >
              <Label style={{ fontWeight: "600" }}>Title</Label>
              <Input
                style={{
                  border: "1px solid #CDCDCD",
                  borderRadius: "0px",
                }}
                required
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)} // Update title state
              />
            </div>

            <div
              className="description"
              style={{
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "1fr 3fr",
              }}
            >
              <Label style={{ fontWeight: "600" }}>Description</Label>
              <Input
                style={{
                  border: "1px solid #CDCDCD",
                  borderRadius: "0px",
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)} // Update description state
              />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div
            className="main"
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
          >
            <div>
              <span>Client List:</span>
            </div>
            <div>
              <span style={{ marginInline: "28px" }}>Selected Clients:</span>
            </div>
          </div>

          <div
            className="container"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                border: "1px solid #D1D1D1",
                height: "270px",
                width: "308px",
                overflowX: "auto",
              }}
            >
{clients?.map((client, index) => (
  <div
    key={`${client.id}-${index}`} // Use both id and index to ensure uniqueness
    ref={clients.length === index + 1 ? lastClientRef : null}
    style={{
      padding: "6px",
      backgroundColor:
        selectedClient?.id === client.id ? "#e0e0e0" : "transparent",
      fontWeight: selectedClients.some(
        (selectedClient) => selectedClient.id === client.id
      )
        ? 700
        : 400,
      cursor: "pointer",
    }}
    onClick={() => setSelectedClient(client)}
  >
    <span>{client.fname}</span>
  </div>
))}
              {loading && <Spinner size="tiny" />}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "8px",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                style={{ minWidth: "20px" }}
                onClick={MoveToRight}
              >
                <ChevronRight20Filled />
              </Button>
              <Button
                size="small"
                style={{ minWidth: "20px" }}
                onClick={MoveToLeft}
              >
                <ChevronLeft20Filled />
              </Button>
              <Button
                size="small"
                style={{ minWidth: "20px" }}
                onClick={MoveAllToRight}
              >
                <ChevronDoubleRight20Filled />
              </Button>
              <Button
                size="small"
                style={{ minWidth: "20px" }}
                onClick={MoveAllToLeft}
              >
                <ChevronDoubleLeft20Filled />
              </Button>
            </div>

            <div
              style={{
                border: "1px solid #D1D1D1",
                height: "270px",
                width: "308px",
                overflowX: "auto",
              }}
            >
{selectedClients.map((client, index) => (
  <div
    key={`${client.id}-${index}`} // Ensure uniqueness for selectedClients as well
    style={{
      padding: "6px",
      backgroundColor:
        selectedSelectedClient?.id === client.id
          ? "#e0e0e0"
          : "transparent",
      cursor: "pointer",
    }}
    onClick={() => setSelectedSelectedClient(client)}
  >
    {client.fname}
  </div>
))}
            </div>
          </div>


        </div>
      </div>

      <br />

      <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px" }}>
        <div>
          <Button size="small" onClick={BacktoSecond}>
            Back
          </Button>
        </div>
        <div>
  <Button
    size="small"
    disabled={selectedClients.length === 0
       || title.trim().length === 0
      }
    onClick={NextToFinalPage}
  >
    Next
  </Button>
</div>

        <div>
          <Button size="small" onClick={CancelFieldSelection}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default FieldSelectionPage;
